import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import userSlice from './user';
import { apiBasePath } from '../services/baseUrl';
import uiSlice from './UI';
import routeSlice from './route';
import locationSlice from './location';
import newCollectionSlice from './newcollection';
import reconciliationCollectionSlice from './reconciliationCollection';
import leaseSlice from './lease';
import playCardSummarySlice from './playcardsSummary';
import storedCollectionsSlice from './storedCollections';
import storedReconciliationCollectionsSlice from './storedReconciliationCollections';
import { initialState } from './user/user-slice';
import { initialState as uiInitialState } from './UI/ui-slice';
import errorSlice from './error';
import msalStatusSlice from './msalStatus/msalStatus-slice';
import collectionFilterSlice from './collectionFilters';
import seedliveFilterSlice from './seedliveFilters';
import leaseFilterSlice from './leaseFilters';
import locationFilterSlice from './locationFilters';
import usersFilterSlice from './usersFilters';
import modelsFilterSlice from './modelsFilters';
import machinesFilterSlice from './machinesFilters';
import modemsFilterSlice from './modemFilters';
import depositFilterSlice from './depositFilters';
import locationOwnerFiltersSlice from './locationOwnerFilters';
import submittedCollectionSlice from './submittedFilters';
import statesFilterSlice from './statesFilter';

// Root state - will persist across browser and tab sessions
// Blacklist any storage keys that you set up as local storage!
const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  blacklist: ['location', 'deposit', 'lease', 'playCard', 'api'],
  stateReconciler: autoMergeLevel1,
  transforms: [
    encryptTransform({
      secretKey: 'CTM',
      onError: function (error) {
        console.log(error);
      },
    }),
  ],
};

// Combine Reducers into rootReducer
const allReducers = combineReducers({
  user: userSlice.reducer,
  ui: uiSlice.reducer,
  location: locationSlice.reducer,
  newCollection: newCollectionSlice.reducer,
  reconciliationCollection: reconciliationCollectionSlice.reducer,
  storedCollections: storedCollectionsSlice.reducer,
  storedReconciliationCollections: storedReconciliationCollectionsSlice.reducer,
  collectionFilter: collectionFilterSlice.reducer,
  seedliveFilter: seedliveFilterSlice.reducer,
  submittedCollectionFilter: submittedCollectionSlice.reducer,
  leaseFilter: leaseFilterSlice.reducer,
  locationFilter: locationFilterSlice.reducer,
  playCard: playCardSummarySlice.reducer,
  lease: leaseSlice.reducer,
  route: routeSlice.reducer,
  error: errorSlice.reducer,
  api: apiBasePath.reducer,
  msalStatus: msalStatusSlice.reducer,
  usersFilter: usersFilterSlice.reducer,
  modelsFilter: modelsFilterSlice.reducer,
  machinesFilter: machinesFilterSlice.reducer,
  modemsFilter: modemsFilterSlice.reducer,
  depositFilter: depositFilterSlice.reducer,
  locationOwnerFilter: locationOwnerFiltersSlice.reducer,
  statesFilter: statesFilterSlice.reducer,
});
const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    return {
      ...state,
      user: initialState,
      ui: uiInitialState,
    };
  }

  return allReducers(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
