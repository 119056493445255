import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  formContent: {
    marginTop: '32px',
    backgroundColor: theme.palette.common.white,
    border: '0px 1px 4px #64748b',
    borderRadius: '8px',
    padding: '16px 0px 32px 0px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
    },
  },
  btn: {
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
  },
  popupData: {
    '& .MuiDialogContent-root': {
      textAlign: 'center',
      marginTop: 32,
      marginBottom: 32,
    },
  },
  intercardAccordion: {
    border: `1px solid ${theme.palette.grey[900]}`,
    borderRadius: '8px',
    padding: '24px 56px 18px',
    '& .MuiTypography-h3': {
      lineHeight: '24px',
    },
    '& .MuiGrid2-container': {
      padding: '0',
      marginBottom: '16px',
      '& .MuiGrid2-root': {
        padding: '0',
      },
    },
  },
  searchFilterWrap: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 5px',
    height: '100%',
  },
  searchWrap: {
    width: '100%',
  },
}));

export default useStyles;
