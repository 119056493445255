import React from 'react';
import { Button, Modal, NoContent, ReactTable } from '../../components/shared';
import { MESSAGE } from '../../constants/message';
import { Box, Link } from '@mui/material';
import { COLUMNS } from '../../constants/tableColumns';
import { Download } from '@mui/icons-material';

const Intercards3Table = (props) => {
  const { handleClose, intercards3TableData } = props;

  const column = React.useMemo(
    () => [
      ...COLUMNS.INTERCARD_S3_LOCATION_TABLE,
      {
        Header: () => 'Action',
        id: 'view',
        Cell: ({ row }) => {
          const fileName = row.original.filePath ? row.original.filePath.split('/') : '';
          const extractFileName = fileName[fileName?.length - 1]?.split('?')[0]?.split('.')[0]?.split('_')?.join('');
          return row.original.filePath ? (
            <Link
              href={row.original.filePath}
              download
              title={extractFileName}
              target="_blank"
              rel="noreferrer"
              key={row.original.filePath}
            >
              <Download sx={{ marginLeft: '8px' }} />
            </Link>
          ) : (
            <Button sx={{ paddingLeft: 0 }} disabled>
              <Download />
            </Button>
          );
        },
      },
    ],
    [],
  );

  return (
    <Modal
      open={intercards3TableData}
      onClose={handleClose}
      title={''}
      titleVariant="subtitle10"
      actionButtons={
        <Box my={3}>
          <Button size="large" onClick={handleClose} variant={'text'}>
            {MESSAGE.CLOSE}
          </Button>
        </Box>
      }
      maxWidth={'md'}
    >
      {intercards3TableData && intercards3TableData?.length !== 0 ? (
        <ReactTable data={intercards3TableData} columns={column} />
      ) : (
        <NoContent title="No Intercard Data Found" desc={<>There are no intercard data present in S3.</>} />
      )}
    </Modal>
  );
};

export default Intercards3Table;
