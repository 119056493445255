import { useEffect, useState } from 'react';
import useStyles from './style/FilterContent.style';
import { ExpandMore, EventOutlined } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material';
import GridContainer from '../GridContainer';
import GridItem from '../GridItem';
import { FILTER_CONTENT_TYPE, FILTER_VALUES } from '../../../constants/FilterConstants';
import DateRangePicker from '../DateRangePicker';
import FilterAutoComplete from './FilterAutoComplete';
import CheckBox from '../CheckBox';

const FilterContent = ({ onChipChange, filterParams = {}, data = [] }) => {
  const classes = useStyles();
  const [customData, setCustomData] = useState(data);

  useEffect(() => {
    setCustomData(data);
  }, [data]);

  const renderFilterContent = (item, index) => {
    const { type } = item;
    switch (type) {
      case FILTER_CONTENT_TYPE.SELECT:
        return (
          <FilterAutoComplete
            item={item}
            customData={customData}
            setCustomData={setCustomData}
            onChipChange={onChipChange}
            filterParams={filterParams}
            index={index}
          />
        );

      case FILTER_CONTENT_TYPE.DATE_RANGE_PICKER: {
        const selectedDays = filterParams[item.value];
        return (
          <DateRangePicker
            selectedDays={selectedDays}
            onChange={(days) => onChipChange(item.value, days)}
            disabledAfterDate={new Date()}
          />
        );
      }

      case FILTER_CONTENT_TYPE.TOGGLE: {
        const selected = filterParams[item.value];
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: '5px',
              opacity: item?.disabled ? 0.5 : 1,
            }}
            key={item.name}
          >
            <Typography>{item.name}</Typography>
            <CheckBox
              checked={selected}
              onChange={(e) => onChipChange(item.value, e.target.checked)}
              disabled={item.disabled}
            />
          </Box>
        );
      }

      default:
        return;
    }
  };

  return (
    <GridContainer direction={'column'} className={classes.filter} p={0}>
      {customData.length > 0 &&
        customData?.map((item, index) => {
          let defaultExpand = false;
          if (item.value !== FILTER_VALUES.DATE_RANGE && filterParams[item.value]?.length > 0) {
            defaultExpand = true;
          } else if (
            item.value === FILTER_VALUES.DATE_RANGE &&
            filterParams[item.value]?.from &&
            filterParams[item.value]?.to &&
            filterParams[item.value]?.from !== '' &&
            filterParams[item.value]?.to !== ''
          ) {
            defaultExpand = true;
          }

          return (
            <GridItem key={index} xs={12}>
              {item.type === FILTER_CONTENT_TYPE.TOGGLE ? (
                renderFilterContent(item, index)
              ) : (
                <Accordion className={classes.accordion} defaultExpanded={defaultExpand}>
                  <AccordionSummary
                    expandIcon={
                      item.type === FILTER_CONTENT_TYPE.DATE_RANGE_PICKER ? <EventOutlined /> : <ExpandMore />
                    }
                    sx={
                      item.type === FILTER_CONTENT_TYPE.DATE_RANGE_PICKER
                        ? {
                            '& .MuiAccordionSummary-expandIconWrapper': {
                              transition: 'none',
                              '&.Mui-expanded': {
                                transform: 'none',
                              },
                            },
                          }
                        : {}
                    }
                  >
                    {item?.name}
                  </AccordionSummary>
                  <AccordionDetails>{renderFilterContent(item, index)}</AccordionDetails>
                </Accordion>
              )}
            </GridItem>
          );
        })}
    </GridContainer>
  );
};

export default FilterContent;
