import { createSlice } from '@reduxjs/toolkit';
import { FILTER_VALUES } from '../../constants/FilterConstants';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

const initialState = {
  seedlive: {
    [FILTER_VALUES.DATE_RANGE]: { from: '', to: '' },
    [FILTER_VALUES.SEEDLIVE_MISSING_DATES]: false,
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
};

export const seedliveFilterSlice = createSlice({
  name: 'seedliveFilter',
  initialState,
  reducers: {
    setSeedliveFilters(state, action) {
      state.seedlive[FILTER_VALUES.DATE_RANGE] = action.payload?.dateRange || { from: '', to: '' };
      state.seedlive[FILTER_VALUES.SEEDLIVE_MISSING_DATES] =
        action.payload?.[FILTER_VALUES.SEEDLIVE_MISSING_DATES] || false;
      state.seedlive.searchText = action.payload?.searchText || '';
      state.seedlive.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.seedlive.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetSeedliveFilterState(state) {
      state.seedlive = { ...initialState.seedlive, searchText: state.seedlive.searchText };
    },
  },
});

export default seedliveFilterSlice;
