import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { GridContainer, GridItem, InformationField, InformationPad, Link } from '../../../components/shared';
import { LEASE_DETAILS } from '../../../constants/accordionFields';
import { routeConstants } from '../../../constants/routeConstants';
import useStyles from './style';
import { checkNAOrReturnPercentValue } from '../../../utils/common-methods';

const LeaseAccordion = (props) => {
  const { leaseInfo } = props;
  const { RENT, LEASE } = LEASE_DETAILS;
  const classes = useStyles();
  const fields = [
    { label: LEASE.TYPE, value: leaseInfo?.leaseType },
    { label: LEASE.STATUS, value: leaseInfo?.leaseStatus },
    { label: RENT.RENT_TYPE, value: leaseInfo?.rentType },
    { label: RENT.RENT_FEE, value: leaseInfo?.rentFee },
    { label: RENT.RENT_RATE, value: checkNAOrReturnPercentValue(leaseInfo?.rentRate) },
  ];
  return (
    <Box component={Paper} className={classes.leaseAccordion}>
      <GridContainer>
        <GridItem xs={12} md={12}>
          <Typography variant="h3" mb={1}>
            Lease & Rent Details
          </Typography>
        </GridItem>
      </GridContainer>
      <InformationPad data={fields} />
      <GridContainer className={classes.leaseAccordionBtnWrap}>
        <GridItem xs={12} md={12}>
          <InformationField
            fontSize="14px"
            fontWeight="500"
            lineHeight="16px"
            value={
              <Link
                variant="link"
                underline="none"
                color="primary"
                to={`/${routeConstants.LEASE_ROUTE}/${leaseInfo?.id}`}
              >
                VIEW FULL DETAILS
              </Link>
            }
          />
        </GridItem>
      </GridContainer>
    </Box>
  );
};

export default LeaseAccordion;
