import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';

export const PercentTextField = React.memo((props) => {
  const {
    label,
    loading,
    disabled,
    useFormik = false,
    helperText,
    value: propValue,
    onChange,
    error = false,
    allowDecimal = false, // Default to false
    ...otherProps
  } = props;

  let field, meta, setFieldValue;
  let formikValue = 0;

  if (useFormik) {
    [field, meta] = useField(props);
    const formik = useFormikContext();
    setFieldValue = formik?.setFieldValue;
    formikValue = field?.value || 0;
  }

  const initialValue = useFormik ? formikValue : propValue || 0;

  const formatValue = (value) => {
    const valueInPercent = value * 100;
    return allowDecimal
      ? valueInPercent.toFixed(2).replace(/\.?0+$/, '') // Remove trailing .00 if not needed
      : Math.round(valueInPercent).toString();
  };

  const [currentFieldValue, setCurrentFieldValue] = useState(initialValue === 0 ? '' : formatValue(initialValue));

  useEffect(() => {
    const externalValue = useFormik ? formikValue : propValue || 0;
    setCurrentFieldValue(externalValue === 0 ? '' : formatValue(externalValue));
  }, [formikValue, propValue, useFormik]);

  const handleChange = (evt) => {
    let inputValue = evt.target.value;

    // Remove the % sign and allow only valid characters
    inputValue = inputValue.replace('%', '').replace(
      allowDecimal ? /[^0-9.-]/g : /[^0-9]/g, // Allow `-` only if `allowDecimal` is true
      '',
    );

    // Prevent multiple dots or misplaced negative signs
    if (allowDecimal) {
      if ((inputValue.match(/\./g) || []).length > 1) {
        return;
      }
      if ((inputValue.match(/-/g) || []).length > 1 || inputValue.indexOf('-') > 0) {
        return;
      }
    }

    // Restrict to two digits before the decimal point
    const parts = inputValue.split('.');
    if (parts[0].replace('-', '').length > 2) {
      return;
    }

    // Update the local state
    setCurrentFieldValue(inputValue);

    // Update Formik or external handler
    if (useFormik && setFieldValue) {
      if (inputValue === '-') {
        // Allow intermediate "-" input
        setCurrentFieldValue(inputValue);
      } else if (inputValue !== '') {
        let parsedValue = parseFloat(inputValue) / 100 || 0;
        parsedValue = Number(parsedValue.toFixed(4)); // Limit precision
        setFieldValue(field.name, parsedValue);
      }
    } else if (onChange && inputValue !== '') {
      let parsedValue = parseFloat(inputValue) / 100 || 0;
      parsedValue = Number(parsedValue.toFixed(4)); // Limit precision
      onChange({ name: props.name, value: parsedValue });
    }
  };

  const handleBlur = () => {
    const numericValue = parseFloat(currentFieldValue) || 0;
    setCurrentFieldValue(
      numericValue === 0
        ? ''
        : allowDecimal
        ? numericValue.toFixed(2).replace(/\.?0+$/, '') // Remove trailing .00
        : Math.round(numericValue).toString(),
    );

    if (useFormik && setFieldValue) {
      setFieldValue(field.name, numericValue / 100);
    } else if (onChange) {
      onChange({ name: props.name, value: numericValue / 100 });
    }
  };

  return (
    <TextField
      {...(useFormik ? field : {})}
      {...otherProps}
      label={label || 'Label'}
      value={loading ? 'Loading...' : currentFieldValue}
      onChange={!disabled ? handleChange : undefined}
      onBlur={!disabled ? handleBlur : undefined}
      error={useFormik ? meta?.touched && Boolean(meta.error) : error}
      helperText={useFormik ? (meta?.touched && meta?.error ? meta.error : helperText) : helperText}
      disabled={disabled}
      inputProps={{
        maxLength: allowDecimal ? 6 : 3, // Adjust max length for decimals and %
      }}
      InputProps={{
        endAdornment: <span>%</span>,
      }}
    />
  );
});

PercentTextField.displayName = 'PercentTextField';
