import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Box, InputAdornment, Paper, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import {
  dateFormatForApi,
  debounce,
  getOptions,
  isArrayWithLength,
  roundOffValue,
} from '../../../utils/common-methods';
import {
  Autocomplete,
  Button,
  Datepicker,
  GridContainer,
  GridItem,
  TextField,
  Dropzone,
} from '../../../components/shared';
import DepositDetailCommonRender from '../CommonComponent/DepositDetailCommonRender';
import { ROLE } from '../../../constants/roles';
import { useHasRoles } from '../../../hooks';
import {
  useCreateDepositMutation,
  useLazyGetDepositsSubLocationsQuery,
} from '../../../services/Deposit/DepositService';
import { routeConstants } from '../../../constants/routeConstants';
import { MESSAGE } from '../../../constants/message';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import { IN_PAGE_NOTIFICATION_TYPES } from '../../../constants/InPageNotificationTypes';

const OpenDeposit = () => {
  const navigate = useNavigate();
  const isSuperAdminOrAccounting = useHasRoles([ROLE.SUPER_ADMIN_IT, ROLE.ACCOUNTING]);
  const [locationAmount, setLocationAmount] = useState({});
  const [dateOfDeposit, setDateOfDeposit] = useState(new Date());
  const [isDateError, setIsDateError] = useState(false);
  const [dateMessage, setDateMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subLocationOptions, setSubLocationOptions] = useState([]);
  const [subLocationList, setSubLocationList] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [dropZoneKey, setDropZoneKey] = useState(0); //Needed to clear dropzone images. https://github.com/Yuvaleros/material-ui-dropzone/issues/9#issuecomment-460022810
  const [showSuccessfulModel, setShowSuccessfulModel] = useState(false);

  const [getSubLocations] = useLazyGetDepositsSubLocationsQuery();
  const [createDeposit, createDepositResult] = useCreateDepositMutation();

  useEffect(() => {
    if (showSuccessfulModel) {
      setTimeout(() => {
        setShowSuccessfulModel(false);
      }, 2000);
    }
  }, [showSuccessfulModel]);

  useEffect(() => {
    let amount = {};
    for (const index in subLocationList) {
      const subLocation = subLocationList[index];
      amount = { ...amount, [subLocation.id]: locationAmount[subLocation.id] || '' };
    }
    setLocationAmount(amount);
  }, [subLocationList]);

  const totalAmount = useMemo(() => {
    const values = Object.values(locationAmount);
    return roundOffValue(values.reduce((prevValue, currentValue) => Number(prevValue) + Number(currentValue), 0));
  }, [locationAmount]);

  const disableDeposit = useMemo(
    () =>
      Number(totalAmount) === 0 ||
      isDateError ||
      !isArrayWithLength(selectedImages) ||
      !isArrayWithLength(subLocationList),
    [totalAmount, selectedImages, isDateError, dateOfDeposit],
  );

  const addLocationAmount = (columnId, newValue) => {
    let valueTwoDecimal = newValue
      ?.toString()
      ?.split('.')
      ?.map((el, i) => (i ? el.split('').slice(0, 2).join('') : el))
      .join('.');
    setLocationAmount((prevValue) => ({
      ...prevValue,
      [columnId]: valueTwoDecimal,
    }));
  };

  const resetStates = () => {
    setSelectedImages([]);
    setSubLocationList([]);
    setDropZoneKey((prevValue) => prevValue + 1);
    let amount = {};
    for (const index in subLocationList) {
      const location = subLocationList[index];
      amount = { ...amount, [location.id]: '' };
    }
    setLocationAmount(amount);
  };

  const onAddDepositPress = async () => {
    const addDepositData = new FormData();
    const locList = subLocationList.map((item) => ({
      sublocationId: item.id,
      cashDeposited: Number(locationAmount[item.id]) || 0,
    }));

    addDepositData.append('depositDate', dateFormatForApi(dateOfDeposit));
    addDepositData.append('sublocations', JSON.stringify(locList));
    selectedImages.forEach((selectedImage) => {
      addDepositData.append('depositFiles', selectedImage);
    });

    createDeposit(addDepositData).then((res) => {
      if (res.data) {
        resetStates();
        if (isSuperAdminOrAccounting) {
          navigate(`/${routeConstants.DEPOSIT_ROUTE}?selectedTab=2`, {
            replace: true,
          });
        } else {
          setShowSuccessfulModel(true);
        }
      }
    });
  };

  const callAPI = async ({ params }) => {
    const resp = await getSubLocations(params);
    if (resp.isSuccess) {
      let optionsSub = Array.isArray(resp?.data?.sublocations) ? resp?.data?.sublocations : [];
      const optionsForAutocomplete = getOptions(optionsSub);
      setSubLocationOptions([...optionsForAutocomplete]);
    }
  };

  const onInputChange = debounce(async (inputText) => {
    setLoading(true);
    let params = {
      isDropdown: true,
      page: 1,
      perPage: 100,
      searchParams: `q=${inputText}`,
    };
    await callAPI({ params });
    setLoading(false);
  }, 600);

  const onOpen = async () => {
    setLoading(true);
    setOpen(true);
    const shouldCallAPI = !isArrayWithLength(subLocationOptions);
    if (shouldCallAPI) {
      let params = {
        isDropdown: true,
        page: 1,
        perPage: 100,
        searchParams: `q=${''}`,
      };
      await callAPI({ params });
    }
    setLoading(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChangeHandler = (files) => {
    setSelectedImages(files);
  };

  const onDateChange = async (value) => {
    Yup.date(MESSAGE.INVALID_DATE)
      .typeError(MESSAGE.INVALID_DATE)
      .label('Date of Deposit')
      .max(dayjs().format('MM/DD/YYYY'))
      .validate(dayjs(value).format('MM/DD/YYYY'))
      .then((res) => {
        if (res) {
          setDateMessage('');
          setIsDateError(false);
          setDateOfDeposit(value);
        } else {
          setDateMessage('Enter a valid date in the format of MM/DD/YYYY');
          setIsDateError(true);
        }
      })
      .catch((e) => {
        const message = e.errors?.[0] || 'Enter a valid date in the format of MM/DD/YYYY';
        setDateMessage(message);
        setIsDateError(true);
      });
  };

  return (
    <Box p={4} component={Paper}>
      <GridContainer direction={'column'}>
        <GridContainer>
          <DepositDetailCommonRender
            key={'DateOfDeposit'}
            title={'Date of Deposit'}
            child={
              <Datepicker
                name={'dateOfDeposit'}
                data-testid="datepicker"
                value={dateOfDeposit}
                onChange={onDateChange}
                inputFormat={'MM/DD/YYYY'}
                PopperProps={{ placement: 'bottom-end' }}
                error={isDateError}
                maxDate={new Date()}
                helperText={isDateError ? dateMessage : ''}
              />
            }
          />
        </GridContainer>
        <GridContainer mt={2}>
          <GridItem xs={12} md={4} display="flex" justifyContent="start" alignItems="center">
            <Typography>Sublocation List</Typography>
          </GridItem>
          <GridItem xs={12} md={8}>
            <Autocomplete
              label={'Sublocation List'}
              value={subLocationList}
              name={'sublocation'}
              options={subLocationOptions}
              multiple
              disableCloseOnSelect
              onChange={(_, value) => setSubLocationList(value)}
              onInputChange={(e, newValue) => onInputChange(newValue)}
              getOptionLabel={(option) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              loading={loading}
              onOpen={onOpen}
              onClose={onClose}
              open={open}
            />
          </GridItem>
        </GridContainer>
        {subLocationList.map((item, index) => (
          <GridContainer key={index} direction={'row'} mt={1}>
            <DepositDetailCommonRender
              key={index}
              title={item.name}
              child={
                <TextField
                  name="amount"
                  placeholder={'0'}
                  type="number"
                  value={locationAmount[item.id] || ''}
                  onChange={(e) => addLocationAmount(item.id, e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  restrictNegativeValue
                />
              }
            />
          </GridContainer>
        ))}
        <GridContainer mt={2}>
          <DepositDetailCommonRender
            key={'TotalCashDeposited'}
            title={'Total Cash Deposited'}
            child={
              <TextField
                name="totalCashDeposited"
                disabled
                value={totalAmount}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            }
          />
        </GridContainer>
        <GridContainer mt={2}>
          <GridItem xs={12} md={4} display="flex" justifyContent="start">
            <Typography role="presentation">{'Attach Receipt'}</Typography>
          </GridItem>
          <GridItem xs={12} md={8}>
            <Dropzone
              acceptedFiles={['image/*', 'application/pdf']}
              key={dropZoneKey}
              maxFileSize={5 * 1024 * 1024} // 5MB File size limit
              onChange={onChangeHandler}
            />
          </GridItem>
        </GridContainer>
        <GridContainer mt={2}>
          <GridItem xs={12} md={4} display="flex" justifyContent="start"></GridItem>
          <GridItem xs={12} md={8}>
            <Alert severity={IN_PAGE_NOTIFICATION_TYPES.INFO}>{MESSAGE.FILE_TYPE_INFO}</Alert>
            <Alert severity={IN_PAGE_NOTIFICATION_TYPES.INFO}>{MESSAGE.FILE_SIZE_INFO}</Alert>
          </GridItem>
        </GridContainer>
      </GridContainer>

      <Stack direction={'row'} mt={2} spacing={2} flexWrap={'wrap'}>
        <LoadingButton
          disabled={disableDeposit}
          loading={createDepositResult.isLoading}
          variant={'contained'}
          onClick={onAddDepositPress}
        >
          {'Add Deposit'}
        </LoadingButton>
        <Button color={'error'} onClick={() => {}}>
          {'CANCEL'}
        </Button>
      </Stack>
      <ConfirmationModal
        isOpen={showSuccessfulModel}
        title="Successful"
        msg="You have created your deposit successfully."
      />
    </Box>
  );
};

export default OpenDeposit;
