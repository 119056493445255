import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import BREADCRUMB_NAMES from '../../../constants/breadcrumbNames';
import { routeConstants } from '../../../constants/routeConstants';
import SORT_CONSTANT, { ASSET_ID_ASC, COLLECTION_SORT_PROPERTY } from '../../../constants/SortConstants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import { COLLECTION_TYPES } from '../../../constants/CommonConstants';
const { PENDING: PENDING_COLLECTION_TYPE } = COLLECTION_TYPES;

import {
  Container,
  Box,
  Chip,
  Typography,
  Stack,
  Paper,
  DialogContentText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NavigateNext, CheckCircle, HighlightOff } from '@mui/icons-material';
import { sortIcon } from '../../../assets/images/icons';
import {
  Button,
  Breadcrumbs,
  GridContainer,
  GridItem,
  InformationField,
  Datepicker,
  NoContent,
  Modal,
} from '../../../components/shared';
import SearchBar from '../../../components/SearchBar';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import SummaryModelBody from './SummaryModelBody';
import TimerModal from '../../../components/TimerModal/TimerModal';

import useStyles from '../style';
import { MESSAGE } from '../../../constants/message';
import { useGetLocationDetailsByIdQuery } from '../../../services/Location/LocationService';

import {
  formatNumberOrReturnZero,
  getAddress,
  getCurrency,
  getDateFormat,
  getFormDataSize,
  getUsedLocalStorageSpaceSize,
  getValidationSchemeYupObject,
  handledSpecialCharacterRegex,
  isAllObjectValuesNullOrZero,
  isArrayWithLength,
  isExceedLocalStorageLimit,
  sortArrayOfObjects,
} from '../../../utils/common-methods';
import {
  getReportMachines,
  getValidationScheme,
  getInitialFieldValueFromAsset,
  getInitialIntercardState,
  allowPlaycardSubmission,
  getTransformedSublocationData,
  getPlaycardForSubmission,
  getIntercardData,
  getIntercardDataForSubmission,
  getDraftData,
  getTransformedDataForIntercardSublocations,
  getRevenueFieldNameAndRevenueInUSD,
  getShowLocationPlaycardSummary,
  getInitialPlaycardState,
  getPlayCardSalesRevenueInUsd,
  updateFormikWithStoredData,
} from '../../../utils/collection-methods';
import CollectionForm from './CollectionForm';
import { useAddCollectionMutation } from '../../../services/CollectionService/CollectionService';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSummary,
  resetState,
  setIntercardData,
  setLocationCurrency,
  resetSubLocationDates,
} from '../../../redux-slice/newcollection';
import {
  resetState as resetPlaycardState,
  setPlayCardSalesSummary,
  setPlayCards,
  setPlaycardFormDirty,
} from '../../../redux-slice/playcardsSummary';
import { ROLE } from '../../../constants/roles';
import { useHasRoles, useNavigationPrompt, useRefreshAndTabClosePrompt } from '../../../hooks';
import { CURRENCY_CONSTANT, DEFAULT_EXCHANGE_RATE } from '../../../constants/CurrencyConstants';
import {
  reduceDraftDatesBySublocationIds,
  resetCollectionByLocationId,
  setCollection,
  setDraftSublocationDates,
} from '../../../redux-slice/storedCollections';
import { DRAFT_COLLECTION_LOCAL_STORAGE_LIMIT } from '../../../constants/CommonConstants';
import { FIELD_NAME } from '../../../constants/MeterTypeFieldMap';
import { DraftSummaryBody } from './DraftSummaryBody';

/**
 *
 */
const displayName = 'Add Collection';

/**
 * Represents a component that handles the process of adding a collection.
 *
 * @constructor
 * @return {void}
 */
const AddCollection = () => {
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSuperAdminOrAccountingOrManagerUser = useHasRoles([
    ROLE.SUPER_ADMIN_IT,
    ROLE.MANAGER,
    ROLE.ACCOUNTING,
    ROLE.DM,
    ROLE.REGIONAL_MANAGER,
    ROLE.EXECS,
    ROLE.VP,
  ]);
  const formRef = useRef();
  const handleSubmitForm = (...args) => {
    formRef.current?.handleSubmit(...args);
    if (Object.values(formRef.current.errors).length) {
      setWarningModal(true);
      setShowSummary(true);
    }
  };
  const classes = useStyles();
  const theme = useTheme();
  const isAboveMd = useMediaQuery(theme.breakpoints.up('md'));
  const [addNewCollectionApi, { isLoading: isSubmitting }] = useAddCollectionMutation();

  const [filterParams, setFilterParams] = useState(FILTER_DATA.ADD_COLLECTION.INITIAL_PARAMS);
  const [noSublocation, setNoSublocation] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  const [sort, setSort] = useState(ASSET_ID_ASC);
  const submissionDate = dayjs(new Date());
  const [showSummary, setShowSummary] = useState(false);
  const [showSuccessfulModel, setShowSuccessfulModel] = useState(false);
  const [showFailedModel, setShowFailedModel] = useState(false);
  const [showSuccessfulDraftModel, setShowSuccessfulDraftModel] = useState(false);
  const [showFailedDraftModel, setShowFailedDraftModel] = useState(false);
  const [showDraftModel, setShowDraftModel] = useState(false);
  const [showClearDraftModel, setShowClearDraftModel] = useState(false);
  const [readFromDraft, setReadFromDraft] = useState(false);
  const [hasInput, setHasInput] = useState(false);

  const [isSubmittingForm, setIsSubmittingForm] = useState(isSubmitting);
  const [reportMachines, setReportMachines] = useState([]);
  const [warningModal, setWarningModal] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [showClearDraftModal, setShowClearDraftModal] = useState(false);
  const [locationsIds, setLocationIds] = useState({});
  const [showSuccessClearDraftModel, setShowSuccessClearDraftModel] = useState(false);

  const {
    totalCollected,
    totalCollectedInUSD,
    collectionCollected,
    transformedSublocations,
    summary,
    intercardData,
    locationCurrency,
    sublocationDates,
    formikData,
    subLocationDatesFormIsDirty,
  } = useSelector((state) => state.newCollection);
  const abbreviation = locationCurrency?.abbreviation || CURRENCY_CONSTANT.USD;
  const exchangeRate = locationCurrency?.exchangeRate || DEFAULT_EXCHANGE_RATE;
  const { playCards, playcardSalesSummary, playcardFormDirty } = useSelector((state) => state.playCard);
  const { collections } = useSelector((state) => state.storedCollections);
  const showPlayCardSummaryAtLocationLevel = getShowLocationPlaycardSummary(playCards);
  const summaryWidth = showPlayCardSummaryAtLocationLevel && JSON.stringify(playCards).length > 2 ? 4 : 6;
  const {
    currentData: locationData,
    isError,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetLocationDetailsByIdQuery({
    id: Number(params.id),
    searchParams: 'includeNewCollectionData=true',
  });

  const [location, setLocation] = useState({
    sublocations: [],
  });
  const [searchRef, setSearchRef] = useState(location);

  const draftedCollections = useMemo(() => {
    let draftsArr = [];
    Object.keys(collections)?.map((objKey) => {
      const dataObj = collections[objKey];
      draftsArr.push({
        id: objKey,
        name: dataObj?.locationName,
      });
    });
    return draftsArr;
  }, [collections]);

  useEffect(() => {
    if (location && locationData) {
      if (location?.sublocations?.length === 0) {
        setNoSublocation(true);
      }
    }
  }, [searchRef]);

  useEffect(() => {
    if (sort && location && location?.sublocations?.length > 0) {
      const sublocations = location?.sublocations?.map((sub) => {
        const sortedAssets = sortArrayOfObjects(sub?.assets, COLLECTION_SORT_PROPERTY[sort]);
        return { ...sub, assets: sortedAssets };
      });
      setLocation((prev) => ({ ...prev, sublocations: sublocations }));
    }
  }, [sort]);

  useEffect(() => {
    if (isSuccess && locationData) {
      dispatch(resetSubLocationDates());
      let sublocations = locationData?.data?.sublocations?.filter((sublocation) => sublocation?.status);
      if (sort) {
        sublocations = sublocations?.map((sub) => {
          const sortedAssets = sortArrayOfObjects(sub?.assets, COLLECTION_SORT_PROPERTY[sort]);
          return { ...sub, assets: sortedAssets };
        });
      }
      const data = { ...(locationData?.data || {}), sublocations };
      data.sublocationIds = sublocations?.map((el) => el.id);
      setSearchRef(data);
      setLocation(data);
      dispatch(
        setLocationCurrency({
          id: locationData?.data?.currency?.id || null,
          abbreviation: locationData?.data?.currency?.abbreviation || null,
          exchangeRate: locationData?.data?.currency?.exchangeRate?.exchangeRate
            ? Number(locationData?.data?.currency?.exchangeRate?.exchangeRate)
            : DEFAULT_EXCHANGE_RATE,
        }),
      );

      dispatch(
        setSummary({
          locationName: locationData?.data?.locationName,
          submissionDate: getDateFormat(submissionDate),
          totalAssets: locationData?.data?.totalAssets,
        }),
      );
      const localIntercardData = getIntercardData(data?.sublocations);
      dispatch(setIntercardData(localIntercardData));
      setNoSublocation(false);
    }
  }, [isSuccess, locationData]);

  const getCustomParam = (value, latestData) => {
    switch (value) {
      case FILTER_VALUES.COLLECTION_SUBLOCATION: {
        const sublocations = latestData?.sublocations || [];
        return {
          options: sublocations,
        };
      }
      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      FILTER_DATA.ADD_COLLECTION.FILTER.map((item) => {
        const param = getCustomParam(item.value, location);

        return {
          ...item,
          ...param,
        };
      }),
    [isSuccess, location],
  );

  const draftData = useMemo(() => getDraftData(formRef?.current?.values), [formRef?.current?.values]);

  useEffect(() => {
    if (isSuccess && locationData && formRef?.current?.values) {
      let noNeedToUpdate = false;
      const storedForm = collections[location?.id];
      const currentData = { ...formRef?.current?.values, exchangeRate: exchangeRate };
      if (storedForm) {
        noNeedToUpdate = Object.keys(storedForm).every((key) => String(storedForm[key]) === String(currentData[key]));
      }
      if (noNeedToUpdate) {
        setFormIsDirty(!noNeedToUpdate);
      } else {
        setFormIsDirty(formRef?.current?.dirty);
      }
      const checkHasInput = formRef?.current?.values
        ? Object.values(formRef?.current?.values).some((el) => el !== 0 && el !== '')
        : false;
      const hasAssetMeters =
        locationData?.data?.sublocations?.flatMap((sub) => sub?.assets)?.flatMap((asset) => asset?.assetMeters)
          ?.length > 0;
      setHasInput((hasAssetMeters && checkHasInput) || Object.values(intercardData).length > 0);
    }
  }, [formRef?.current?.values]);

  useRefreshAndTabClosePrompt(formIsDirty);
  useNavigationPrompt(
    formIsDirty,
    MESSAGE.DRAFT_COLLECTION_ASK_SAVE,
    useCallback((obj) => {
      if (obj.isOk) {
        dispatch(
          setCollection({
            locationId: obj?.data?.locationId,
            formData: obj?.data?.formData,
            exchangeRate: obj?.data?.exchangeRate,
            playcards: obj?.data?.playcards,
            intercardData: obj?.data?.intercardData,
            collectionType: PENDING_COLLECTION_TYPE,
          }),
        );
        dispatch(setDraftSublocationDates(obj?.data?.sublocationDates));
      }
    }, []),
    {
      locationId: location?.id,
      formData: draftData,
      exchangeRate: exchangeRate,
      playcards: playCards,
      intercardData: intercardData,
      collectionType: PENDING_COLLECTION_TYPE,
      sublocationDates,
    },
  );

  useEffect(() => {
    if (collections && params?.id && collections[Number(params?.id)]) {
      setShowDraftModel(true);
    } else {
      setShowDraftModel(false);
    }

    return () => {
      dispatch(resetState());
      dispatch(resetPlaycardState());
    };
  }, []);

  // Read playcard values
  useEffect(() => {
    if (isSuccess && locationData && locationData?.data?.sublocations?.length > 0) {
      let sublocations = locationData?.data?.sublocations?.filter((sublocation) => sublocation?.status);
      let storedPlayCardData = {};
      sublocations?.forEach((eachSubLoc) => {
        const storedCollection = collections[locationData?.data?.id];
        const storedCollectionType = PENDING_COLLECTION_TYPE;
        const storedPlaycards = storedCollection?.playcards;
        if (readFromDraft && storedPlaycards && storedCollectionType) {
          storedPlayCardData = Object.assign(storedPlayCardData, { [eachSubLoc?.id]: storedPlaycards[eachSubLoc?.id] });
        }
        // read from api
        if (
          (readFromDraft && !storedPlaycards) ||
          (!readFromDraft &&
            eachSubLoc?.playCardSumDetails &&
            !isAllObjectValuesNullOrZero(eachSubLoc?.playCardSumDetails))
        ) {
          const initialPlaycardState = getInitialPlaycardState(eachSubLoc?.playCardSumDetails);
          storedPlayCardData = Object.assign(storedPlayCardData, { [eachSubLoc?.id]: initialPlaycardState });
        }
      });
      dispatch(setPlayCards(storedPlayCardData));
      const totalPlayCardSales = getPlayCardSalesRevenueInUsd(storedPlayCardData);
      dispatch(setPlayCardSalesSummary(totalPlayCardSales));
    }
  }, [locationData, readFromDraft]);

  useEffect(() => {
    if (isSuccess && locationData) {
      const shouldBeEnabled = allowPlaycardSubmission(playCards, playcardSalesSummary);
      if (
        transformedSublocations.length > 0 ||
        (shouldBeEnabled && playcardSalesSummary > 0) ||
        Object.values(intercardData).length > 0
      ) {
        setDisabledSubmit(false);
      } else {
        setDisabledSubmit(true);
      }
    }
  }, [playCards, playcardSalesSummary, transformedSublocations]);

  useEffect(() => {
    setIsSubmittingForm(isSubmitting);
  }, [isSubmitting]);

  // Update formIsDirty when the playcard summary is dirty
  useEffect(() => {
    if (playcardFormDirty) {
      setFormIsDirty(true);
    }
  }, [playcardFormDirty]);

  // Update formIsDirty when the dates are dirty
  useEffect(() => {
    if (subLocationDatesFormIsDirty) {
      setFormIsDirty(true);
    }
  }, [subLocationDatesFormIsDirty]);

  const sublocationNameMap = useMemo(() => {
    const subMap = {};
    searchRef?.sublocations?.map((sub) => {
      subMap[sub.id] = sub.name;
    });
    return subMap;
  }, [searchRef]);

  /**
   * This is used to initialize formik
   * When read from draft is false, it will loop all assets inside sublocations and generate formik initial states and validation scheme using assetMeters values. For intercard, it will read from intercard redux (it stored intercard value in newCollection-slice when the location api get data )
   * When read from draft is true, for assetMeters, it will do the same thing as above. But afterwards, it will update states using stored formik values. For intercard, it will read data from storedCollections (collection[sublocation][intercardData]) to update intercardData in newCollection-slice. Then, it will generate initial states from storedCollections intercardData
   * When users change Date of Reading in SublocationDateModule, the readFromDraft will be set to false. In that case, it will follow the flow of read from draft is false
   */
  const initialStateAndScheme = useMemo(() => {
    let validationSchemas = {};

    let dependencies = [];
    let states = {};
    if (searchRef?.sublocations?.length > 0) {
      const currentAssets = searchRef?.sublocations?.flatMap((sub) => sub.assets);
      if (currentAssets && currentAssets.length > 0) {
        currentAssets.map((asset) => {
          if (asset?.assetMeters) {
            states = { ...states, ...getInitialFieldValueFromAsset(asset) };
            const schemas = getValidationScheme(asset);
            validationSchemas = { ...validationSchemas, ...schemas?.schema };
            dependencies = [...dependencies, ...schemas?.dependencies];
          }
          // Read intercard data from redux
          if (
            !readFromDraft &&
            Object.values(intercardData).length > 0 &&
            intercardData[asset?.id] &&
            intercardData[asset?.id]?.intercardData.length > 0
          ) {
            const intercardInitialStates = getInitialIntercardState(intercardData[asset?.id].intercardData, asset);
            states = { ...states, ...intercardInitialStates };
          }
          // update using formikData to preserve
          if (!readFromDraft) {
            const updateStates = updateFormikWithStoredData(formikData, states, asset);
            states = { ...updateStates };
          }
        });

        const validationSchema = getValidationSchemeYupObject(validationSchemas, dependencies);
        if (readFromDraft && collections && collections[searchRef?.id]) {
          const storedForm = collections[searchRef?.id];
          const storedExchangeRate = storedForm?.exchangeRate;
          if (storedForm['intercardData'] && Object.values(storedForm['intercardData']).length > 0) {
            dispatch(setIntercardData(storedForm['intercardData']));
            Object.keys(storedForm['intercardData']).forEach((assetIdForDraftIntercard) => {
              const sublocationIdForDraftIntercard =
                storedForm['intercardData'][assetIdForDraftIntercard].sublocationId;
              const intercardInitialStates = getInitialIntercardState(
                storedForm['intercardData'][assetIdForDraftIntercard].intercardData,
                { id: assetIdForDraftIntercard, subLocationId: sublocationIdForDraftIntercard },
              );
              states = { ...states, ...intercardInitialStates };
            });
          }
          Object.keys(storedForm).map((key) => {
            if (key in states && String(states[key]) !== String(storedForm[key])) {
              let value = String(storedForm[key]);
              if (storedExchangeRate !== exchangeRate && key.includes(FIELD_NAME.REVENUE_IN_USD)) {
                const { revenueInUSD } = getRevenueFieldNameAndRevenueInUSD(
                  exchangeRate,
                  storedForm[key],
                  storedForm,
                  key,
                );
                value = revenueInUSD;
              }
              states[key] = value;
            }
            if (key.includes('comment')) {
              states[key] = storedForm[key];
            }
          });
        }
        return { initialState: states, scheme: validationSchema };
      }
    }
    return { initialState: {}, scheme: {} };
  }, [searchRef, readFromDraft, intercardData]);

  const {
    OPEN_COLLECTION: { OPEN_COLLECTION, ADD_COLLECTION },
  } = BREADCRUMB_NAMES;

  const breadcrumbData = [
    {
      text: OPEN_COLLECTION,
      redirection: `${routeConstants.COLLECTIONS_ROUTE}?selectedTab=1`,
    },
    { text: ADD_COLLECTION },
  ];

  const searchHandler = (params) => {
    if (params) {
      const patternValue = handledSpecialCharacterRegex(params);
      const searchGloballyRegex = new RegExp(patternValue, 'i');
      const data_filter = searchRef?.sublocations.filter(function (element) {
        return element.assets.some(function (subElement) {
          return (
            String(subElement.id).match(searchGloballyRegex) ||
            String(subElement?.legacyAssetId).match(searchGloballyRegex) ||
            subElement?.title?.match(searchGloballyRegex)
          );
        });
      });
      const filterAssets = data_filter.map(function (element) {
        return {
          ...element,
          assets: element.assets.filter(function (subElement) {
            return (
              String(subElement.id).match(searchGloballyRegex) ||
              String(subElement?.legacyAssetId).match(searchGloballyRegex) ||
              subElement?.title?.match(searchGloballyRegex)
            );
          }),
        };
      });

      data_filter.length === 0 ? setIsSearch(true) : setIsSearch(false);
      data_filter.length === 0 ? setNoSublocation(true) : setNoSublocation(false);

      setLocation((prev) => ({ ...prev, sublocations: filterAssets }));
    } else {
      if (sort) {
        const sublocations = searchRef?.sublocations?.map((sub) => {
          const sortedAssets = sortArrayOfObjects(sub?.assets, COLLECTION_SORT_PROPERTY[sort]);
          return { ...sub, assets: sortedAssets };
        });
        setLocation((prev) => ({ ...prev, sublocations: sublocations }));
      }
      setNoSublocation(false);
      setIsSearch(false);
    }
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.ADD_COLLECTION.INITIAL_PARAMS);
  };

  const handleSort = (e) => {
    setSort(e.target.value);
  };

  const readFromDraftHandler = () => {
    setShowDraftModel(false);
    setReadFromDraft(true);
  };

  const submitHandler = () => {
    // check if errors only contain removed fields (once report, all fields will be removed)
    const result = Object.keys(formRef.current?.errors).every(
      (errorKey) => !Object.keys(formRef.current?.values).includes(errorKey),
    );
    if (formRef && result && Object.keys(formRef.current?.touched).length > 0) {
      let transformedReportMachines = [];
      if (Object.values(formRef.current?.values).length > 0) {
        transformedReportMachines = getReportMachines(formRef.current?.values);
      }
      setReportMachines(transformedReportMachines);
      setShowSummary(true);
    } else {
      handleSubmitForm();
    }
  };

  const saveAsDraftHandler = () => {
    setShowSummary(false);
    setIsSubmittingForm(true);
    const usedSpaceSize =
      getFormDataSize(draftData) +
      getFormDataSize(sublocationDates) +
      getFormDataSize(intercardData) +
      getUsedLocalStorageSpaceSize() +
      getFormDataSize(playCards);
    setIsSubmittingForm(false);
    if (isExceedLocalStorageLimit(DRAFT_COLLECTION_LOCAL_STORAGE_LIMIT, usedSpaceSize)) {
      setShowFailedDraftModel(true);
      if (draftedCollections?.length) {
        setTimeout(() => {
          setShowClearDraftModal(true);
        }, 3000);
      } else {
        setShowSuccessfulDraftModel(false);
      }
    } else {
      setFormIsDirty(false);
      dispatch(setPlaycardFormDirty(false));
      dispatch(
        setCollection({
          locationId: location?.id,
          locationName: location?.locationName,
          formData: draftData,
          exchangeRate: exchangeRate,
          playcards: playCards,
          intercardData: intercardData,
          collectionType: PENDING_COLLECTION_TYPE,
        }),
      );
      dispatch(setDraftSublocationDates(sublocationDates));
      setShowFailedDraftModel(false);
      setShowSuccessfulDraftModel(true);
    }
  };

  const clearDraftHandler = () => {
    setShowClearDraftModel(false);
    setShowDraftModel(false);
    setReadFromDraft(false);
    dispatch(resetCollectionByLocationId(location?.id));
    dispatch(reduceDraftDatesBySublocationIds(location?.sublocationIds));
  };

  const clearLocationsDraftHandler = () => {
    setShowClearDraftModal(false);
    Object.keys(locationsIds)?.map((key) => {
      if (locationsIds[key]) {
        dispatch(resetCollectionByLocationId(key));
      }
    });
    setLocationIds({});
    setShowSuccessClearDraftModel(true);
  };

  const confirmHandler = () => {
    setShowSummary(false);
    setIsSubmittingForm(true);
    let data = {
      currencyId: Number(locationCurrency.id),
      exchangeRate: locationCurrency.exchangeRate,
    };
    if (isArrayWithLength(transformedSublocations)) {
      data.sublocations = getTransformedSublocationData(
        location?.sublocationIds,
        transformedSublocations,
        playCards,
        intercardData,
        sublocationDates,
        location,
      );
    } else if (Object.values(playCards).length > 0) {
      const intercardDataForSubmission = getIntercardDataForSubmission(intercardData, location);
      data.sublocations = getPlaycardForSubmission(playCards, intercardDataForSubmission, sublocationDates, location);
    } else {
      const intercardDataForSubmission = getIntercardDataForSubmission(intercardData, location);
      data.sublocations = getTransformedDataForIntercardSublocations(
        intercardDataForSubmission,
        sublocationDates,
        location,
      );
    }
    setFormIsDirty(false);
    addNewCollectionApi(data).then((res) => {
      setIsSubmittingForm(false);
      if (res.error) {
        setShowFailedModel(true);
        setTimeout(() => {
          setShowFailedModel(false);
        }, 2000);
      } else {
        dispatch(resetState());
        dispatch(resetPlaycardState());
        dispatch(resetCollectionByLocationId(location?.id));
        setShowSuccessfulModel(true);

        if (isSuperAdminOrAccountingOrManagerUser) {
          setTimeout(() => {
            navigate(`/${routeConstants.COLLECTIONS_ROUTE}?selectedTab=2`, {
              redirect: true,
            });
          }, 2000);
        } else {
          setTimeout(() => {
            navigate(`/${routeConstants.COLLECTIONS_ROUTE}?selectedTab=1`, {
              redirect: true,
            });
          }, 2000);
        }
      }
    });
  };
  if ((isLoading && !isError) || isFetching) return <LoadingSpinner containerHeight={300} />;
  return (
    <Container maxWidth="xl" disableGutters>
      <Box mb={3}>
        <Breadcrumbs
          icon={<NavigateNext fontSize="medium" />}
          variant="body2"
          underline="hover"
          data-testid="breadcrumb"
          data={breadcrumbData}
        />
      </Box>

      <Box mb={3}>
        <Stack direction={'row'} alignItems="center" spacing={2}>
          <Typography variant="h1" data-testid="locationName" textAlign="center">
            {location?.locationName}
          </Typography>
          <Chip
            label={location?.status ? 'Active' : 'Inactive'}
            color={location?.status ? 'success' : 'error'}
            variant="outlined"
          />
        </Stack>
      </Box>

      <Box mb={1} component={Paper}>
        <GridContainer data-testid="info-pad" spacing={2} px={3} py={1}>
          <GridItem xs={12} sm={4.5} md={4} lg={2} className={classes.locationHeaderSubmissionDate}>
            <Datepicker
              label={MESSAGE.SUBMISSION_DATE}
              name="submissionDate"
              value={submissionDate}
              id="submissionDate"
              inputFormat="MM/DD/YYYY"
              onChange={() => {}}
              disabled
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={3.5} lg={2} ml={3}>
            <InformationField value={MESSAGE.TOTAL_ASSET} variant="subtitle2" />
            <InformationField value={location?.totalAssets} variant="subtitle1" />
          </GridItem>
          <GridItem xs={12} sm={4} md={4} lg={2}>
            <InformationField value={MESSAGE.COLLECTION_LOCATION_CITY} variant="subtitle2" />
            <InformationField value={location?.city?.name} variant="subtitle1" />
          </GridItem>
          <GridItem xs={12} sm={4} md={4} lg={2}>
            <InformationField value={MESSAGE.COLLECTION_LOCATION_ADDRESS} variant="subtitle2" />
            <InformationField value={getAddress(location)} variant="subtitle1" />
          </GridItem>
          {abbreviation !== CURRENCY_CONSTANT.USD && (
            <GridItem xs={12} sm={4} md={4} lg={2}>
              <InformationField value={MESSAGE.COLLECTION_EXCHANGE_RATE} variant="subtitle2" />
              <InformationField value={exchangeRate} variant="subtitle1" data-testid="exchangeRate" />
            </GridItem>
          )}
        </GridContainer>
      </Box>

      {!isError && !isLoading && (
        <Box component={Paper} className={classes.accordion}>
          <Box className={classes.searchFilterWrap}>
            <Box className={classes.searchWrap}>
              <SearchBar onSearch={searchHandler} onReset={() => setLocation(searchRef)} isEnterDisabled />
            </Box>
            <Box px={3}>
              <NewFilterDrawer
                name={TITLE.SORT_BY.toUpperCase()}
                title={TITLE.SORT_BY}
                drawerType={DRAWER_TYPES.SORT_DRAWER}
                startIcon={<>{sortIcon}</>}
                onSortSelect={handleSort}
                selectedSort={sort}
                sortData={SORT_CONSTANT.ADD_COLLECTION_SORT}
              />
            </Box>
            <Box pr={3}>
              <NewFilterDrawer
                filteredValues={filterParams}
                name={TITLE.FILTERS.toUpperCase()}
                title={TITLE.FILTERS}
                drawerType={DRAWER_TYPES.FILTER_DRAWER}
                onReset={resetFilterParams}
                onApplyFilter={(v) => setFilterParams(v)}
                filterData={filterData}
                emptyFilterLength={JSON.stringify(FILTER_DATA.ADD_COLLECTION.INITIAL_PARAMS).length}
              />
            </Box>
          </Box>

          {isSuccess && locationData && (
            <CollectionForm
              ref={formRef}
              noSublocation={noSublocation}
              isSearch={isSearch}
              allSublocations={searchRef?.sublocations}
              location={location}
              initialStateAndScheme={initialStateAndScheme}
              onConfirm={() => {
                setShowSummary(true);
              }}
              readFromDraft={readFromDraft}
              turnOffDraft={() => setReadFromDraft(false)}
              filterParams={filterParams}
            />
          )}
        </Box>
      )}

      {noSublocation && !isSearch && !isError && (
        <NoContent title="No Sublocation Associated" desc={<>There is no sublocation associated to this location.</>} />
      )}

      {((isLoading && !isError) || isFetching) && <LoadingSpinner containerHeight={300} />}

      {!noSublocation && !isError && (
        <Box mt={3} component={Paper}>
          <GridContainer
            data-testid="info-pad"
            spacing={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <GridItem xs={6} sm={7}>
              <GridContainer data-testid="info-pad" display="flex" justifyContent="flex-start" alignItems="center">
                <GridItem xs={summaryWidth}>
                  <Box>
                    <InformationField
                      value={`${MESSAGE.COLLECTION_TOTAL_COLLECTED_UPPERCASE} (${abbreviation})`}
                      variant="subtitle7"
                      data-testid="totalCollectedLocalName"
                    />
                  </Box>
                  <InformationField
                    value={`${getCurrency(abbreviation)} ${formatNumberOrReturnZero(totalCollected)}`}
                    variant="subtitle4"
                    data-testid="totalCollectedLocalVal"
                  />
                </GridItem>
                {abbreviation !== CURRENCY_CONSTANT.USD && (
                  <GridItem xs={summaryWidth}>
                    <Box>
                      <InformationField
                        value={`${MESSAGE.COLLECTION_TOTAL_COLLECTED_UPPERCASE} (${CURRENCY_CONSTANT.USD})`}
                        variant="subtitle7"
                        data-testid="totalCollected"
                      />
                    </Box>
                    <InformationField
                      value={`$ ${formatNumberOrReturnZero(totalCollectedInUSD)}`}
                      variant="subtitle4"
                      data-testid="totalCollectedValue"
                    />
                  </GridItem>
                )}
                <GridItem xs={5} sm={summaryWidth} pr={1}>
                  <Box>
                    <InformationField value={MESSAGE.SUBLOCATIONS_COLLECTED} variant="subtitle7" />
                  </Box>
                  <InformationField value={collectionCollected} variant="subtitle4" />
                </GridItem>
                {showPlayCardSummaryAtLocationLevel && JSON.stringify(playCards).length > 2 && (
                  <GridItem xs={summaryWidth}>
                    <Box>
                      <InformationField
                        value={`${MESSAGE.PLAYCARD_SALES_SUMMARY} (${CURRENCY_CONSTANT.USD})`}
                        variant="subtitle7"
                        data-testid="totalPlaycardSales"
                      />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InformationField
                        value={`$ ${formatNumberOrReturnZero(playcardSalesSummary)}`}
                        variant="subtitle4"
                        data-testid="totalPlaycardSalesValue"
                        pr={1}
                      />
                    </Box>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
            <GridItem xs={6} sm={5} display="flex" justifyContent="flex-end" alignItems="center">
              <GridContainer data-testid="info-pad">
                <GridItem xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
                  <Button
                    disabled={disabledSubmit}
                    onClick={submitHandler}
                    variant="contained"
                    data-testid="submit-collection"
                    sx={{ padding: '10px 51px' }}
                  >
                    {MESSAGE.SUBMIT}
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
                  <Button
                    variant="contained"
                    sx={{ padding: `10px ${isAboveMd ? '15px' : ' 20px'}` }}
                    onClick={saveAsDraftHandler}
                    disabled={!hasInput}
                  >
                    {MESSAGE.SAVE_AS_DRAFT}
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <Modal
            title={!warningModal ? MESSAGE.COLLECTION_SUMMARY_TITLE : ''}
            titleVariant="subtitle10"
            open={showSummary}
            onClose={() => setShowSummary(false)}
            actionButtons={
              <Box my={3}>
                {!warningModal ? (
                  <Button variant="contained" size="large" onClick={confirmHandler}>
                    {MESSAGE.CONFIRM}
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  size="large"
                  onClick={() => {
                    setShowSummary(false);
                    // add delay in setting value as mui modal execution is taking time to close the modal
                    setTimeout(() => {
                      setWarningModal(false);
                    }, 300);
                  }}
                  variant={warningModal ? 'contained' : 'text'}
                >
                  {warningModal ? MESSAGE.OK : MESSAGE.CANCEL}
                </Button>
              </Box>
            }
          >
            {!warningModal ? (
              <SummaryModelBody
                sublocationIds={location?.sublocationIds}
                show={showSummary}
                sublocationNameMap={sublocationNameMap}
                reportMachines={reportMachines}
                data={summary}
                onConfirm={() => {
                  setShowSummary(false);
                }}
                onClose={() => {
                  setShowSummary(false);
                }}
              />
            ) : (
              MESSAGE.INCOMPLETE_METER_DATA_ERROR_MESSAGE
            )}
          </Modal>
          <Modal
            title={MESSAGE.DRAFT_SUMMARY}
            titleVariant="subtitle10"
            open={showClearDraftModal}
            onClose={() => setShowClearDraftModal(false)}
            actionButtons={
              <Box my={3}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={clearLocationsDraftHandler}
                  disabled={Object.values(locationsIds)?.every((item) => item === false)}
                >
                  {MESSAGE.CLEAR}
                </Button>

                <Button
                  size="large"
                  onClick={() => {
                    setShowClearDraftModal(false);
                  }}
                  variant={warningModal ? 'contained' : 'text'}
                >
                  {MESSAGE.CANCEL}
                </Button>
              </Box>
            }
          >
            <DraftSummaryBody
              draftsData={draftedCollections}
              locationsIds={locationsIds}
              setLocationIds={setLocationIds}
            />
          </Modal>
          <Modal
            children={<DialogContentText variant="h2">{MESSAGE.DRAFT_FORM_EXIST_MESSAGE}</DialogContentText>}
            open={showDraftModel}
            onClose={() => setShowDraftModel(false)}
            buttonPosition="center"
            actionButtons={
              <Box my={3}>
                <Button variant="contained" size="large" onClick={readFromDraftHandler}>
                  {MESSAGE.OPEN_DRAFT}
                </Button>
                <Button
                  size="large"
                  onClick={() => {
                    setShowDraftModel(false);
                    setShowClearDraftModel(true);
                  }}
                >
                  {MESSAGE.CREATE_NEW_COLLECTION}
                </Button>
              </Box>
            }
          ></Modal>
          <Modal
            children={<DialogContentText variant="h2">{MESSAGE.CLEAR_DRAFT_CONFIRMATION}</DialogContentText>}
            open={showClearDraftModel}
            onClose={() => {
              setShowClearDraftModel(false);
              setShowDraftModel(true);
            }}
            actionButtons={
              <Box my={3}>
                <Button variant="contained" size="large" onClick={clearDraftHandler}>
                  {MESSAGE.OK}
                </Button>
                <Button
                  size="large"
                  onClick={() => {
                    setShowDraftModel(true);
                    setShowClearDraftModel(false);
                  }}
                >
                  {MESSAGE.CANCEL}
                </Button>
              </Box>
            }
          ></Modal>
          <Modal open={isSubmittingForm} customWidth="395px">
            <LoadingSpinner containerHeight={300} />
          </Modal>
          <TimerModal
            isOpen={showSuccessfulModel}
            timer={2}
            activate={showSuccessfulModel}
            onTimeComplete={() => setShowSuccessfulModel(false)}
            icon={<CheckCircle color="success" sx={{ fontSize: '4rem' }} />}
            title={MESSAGE.SUCCESSFUL}
            msg={MESSAGE.SUCCESSFUL_COLLECTION(location?.locationName, isSuperAdminOrAccountingOrManagerUser)}
          />
          <TimerModal
            isOpen={showFailedModel}
            timer={2}
            activate={showFailedModel}
            onTimeComplete={() => setShowFailedModel(false)}
            icon={<HighlightOff color="error" sx={{ fontSize: '4rem' }} />}
            title={MESSAGE.FAILED}
            msg={`Your submission for ${location?.locationName} collection is failed. Please try again. This message will be closed in`}
          />
          <TimerModal
            isOpen={showSuccessfulDraftModel}
            timer={2}
            activate={showSuccessfulDraftModel}
            onTimeComplete={() => setShowSuccessfulDraftModel(false)}
            icon={<CheckCircle color="success" sx={{ fontSize: '4rem' }} />}
            title={MESSAGE.SUCCESSFUL}
            msg={`Your input for ${location?.locationName} collection has stored as draft`}
          />
          <TimerModal
            isOpen={showFailedDraftModel}
            timer={3}
            activate={showFailedDraftModel}
            onTimeComplete={() => setShowFailedDraftModel(false)}
            icon={<HighlightOff color="error" sx={{ fontSize: '4rem' }} />}
            title={MESSAGE.FAILED}
            msg={`${MESSAGE.DRAFT_DATA_EXCEED_LOCAL_STORAGE_WARNING} This message will be closed in`}
          />
          <TimerModal
            isOpen={showSuccessClearDraftModel}
            timer={3}
            activate={showSuccessClearDraftModel}
            onTimeComplete={() => setShowSuccessClearDraftModel(false)}
            icon={<CheckCircle color="success" sx={{ fontSize: '4rem' }} />}
            title={MESSAGE.SUCCESSFUL}
            msg={`${MESSAGE.DRAFT_DATA_CLEAR_SUCCESSFUL} This message will be closed in`}
          />
        </Box>
      )}
    </Container>
  );
};

/**
 * Adds a display name to the given collection.
 *
 * @param {Array} collection - The collection to add the display name to.
 * @param {string} displayName - The display name to be added.
 * @returns {Array} - The updated collection with the display name.
 */
AddCollection.displayName = displayName;
export default AddCollection;
