import dayjs from 'dayjs';
import {
  SHARED_FIELDS,
  CREDIT_FIELDS_RECONCILIATION,
  COIN_FIELDS,
  BILL_FIELDS,
  METER_TYPE,
  FIELD_NAME,
  TICKET_FIELDS,
  TOKEN_FIELDS,
  CASH_FIELDS,
  PRIZE_FIELDS,
  MEDALLION_FIELDS,
  SORT_ORDER,
  CASH_FIELDS_RECONCILIATION,
  TOKEN_FIELDS_RECONCILIATION,
  TOKEN_CHANGER_FIELDS,
  COIN_FIELDS_RECONCILIATION,
  CANDY_FIELDS,
  REVENUE_METER_TYPES,
  EXCLUDING_FIELD_NAMES,
  TOKEN_CHANGER_FIELDS_RECONCILIATION,
  EXCLUDING_FIELD_NAMES_FOR_INITIALIZE,
  CHECK_FIELDS,
  IMPULSE_READER_FIELDS,
} from '../constants/MeterTypeFieldMap';
import { twoConditionSchema, threeConditionSchema, getIndependentRepSchema } from '../schema/validationSchemas';
import {
  checkNumberIsNaNAndInfinity,
  checkObjectNotEmpty,
  checkValueNotNullUndefinedBlank,
  dateFormatForApi,
  formatNumberOrReturnZero,
  getCurrency,
  getDateFormat,
  getDateWithoutConversion,
  getESTDateFormat,
  getSum,
  isArrayWithLength,
  isValueValid,
  roundOffValue,
} from './common-methods';
import { CURRENCY_CONSTANT, DEFAULT_EXCHANGE_RATE } from '../constants/CurrencyConstants';
import { ACCOUNT_FIELDS, ACCOUNT_TYPES, NOT_AR_TYPES } from '../constants/accountSummary';
import { TOKEN_CHANGER_PAYMENT_TYPE } from '../constants/MeterConstants';
import { MESSAGE } from '../constants/message';
import { INDEPENDENT_REP_OF_SUBLOCATION } from '../constants/CommonConstants';

export const getFieldName = (type, field, sublocationId, assetId, meterId, assetMeterId) => {
  if (
    isValueValid(type) &&
    isValueValid(field) &&
    isValueValid(sublocationId) &&
    isValueValid(assetId) &&
    isValueValid(meterId) &&
    isValueValid(assetMeterId)
  ) {
    return `${type}_${field}_${sublocationId}_${assetId}_${meterId}_${assetMeterId}`;
  }
  return null;
};

/**
 *
 * @param {string} type comment or report
 * @param {string} sublocationId
 * @param {string} assetId
 * @returns
 */
export const getTypeFieldName = (type, sublocationId, assetId) => {
  if (isValueValid(type) && isValueValid(sublocationId) && isValueValid(assetId)) {
    return `${type}_${sublocationId}_${assetId}`;
  }
  return null;
};

/**
 * Generate fieldName according to meter type for validation purpose.
 * Skip fields that don't need validation, such as prior reading, ticketDispensed, prizeDispensed.
 *
 * @param {Object} asset
 * @returns  { Array } fieldNames
 */
export const getValidationFieldNames = (asset) => {
  const { assetMeters, subLocationId: sublocationId } = asset;
  return assetMeters.flatMap((assetMeter) => {
    const meterName = assetMeter?.meter?.name;
    switch (meterName) {
      case METER_TYPE.BILL:
        return BILL_FIELDS.filter(
          (assetMeter) => ![FIELD_NAME.PRIOR_READING, FIELD_NAME.TEST_VENDS].includes(assetMeter),
        ).map((field) => getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id));
      case METER_TYPE.COIN:
        return COIN_FIELDS.filter(
          (assetMeter) => ![FIELD_NAME.PRIOR_READING, FIELD_NAME.TEST_VENDS].includes(assetMeter),
        ).map((field) => getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id));
      case METER_TYPE.TOKEN_CHANGER:
        return TOKEN_CHANGER_FIELDS.filter((assetMeter) => ![FIELD_NAME.PRIOR_READING].includes(assetMeter)).map(
          (field) => getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id),
        );
      case METER_TYPE.TOKEN:
        return TOKEN_FIELDS.filter(
          (assetMeter) => ![FIELD_NAME.PRIOR_READING, FIELD_NAME.TEST_VENDS].includes(assetMeter),
        ).map((field) => getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id));
      case METER_TYPE.BONUS:
        return SHARED_FIELDS.filter((assetMeter) => assetMeter !== FIELD_NAME.PRIOR_READING).map((field) =>
          getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id),
        );
      case METER_TYPE.PRIZE:
        return PRIZE_FIELDS.filter(
          (assetMeter) => assetMeter !== FIELD_NAME.PRIOR_READING && assetMeter !== FIELD_NAME.PRIZE_DISPENSED,
        ).map((field) => getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id));
      case METER_TYPE.GAME_METER:
        return SHARED_FIELDS.filter((assetMeter) => assetMeter !== FIELD_NAME.PRIOR_READING).map((field) =>
          getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id),
        );
      case METER_TYPE.TICKET:
        return TICKET_FIELDS.filter(
          (assetMeter) => assetMeter !== FIELD_NAME.PRIOR_READING && assetMeter !== FIELD_NAME.TICKET_DISPENSED,
        ).map((field) => getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id));
      case METER_TYPE.CASH:
        return CASH_FIELDS.filter(
          (assetMeter) => ![FIELD_NAME.PRIOR_READING, FIELD_NAME.TEST_VENDS].includes(assetMeter),
        ).map((field) => getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id));
      case METER_TYPE.CREDIT:
        return SHARED_FIELDS.filter((assetMeter) => assetMeter !== FIELD_NAME.PRIOR_READING).map((field) =>
          getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id),
        );
      case METER_TYPE.MEDALLION:
        return SHARED_FIELDS.filter(
          (assetMeter) => assetMeter !== FIELD_NAME.PRIOR_READING && assetMeter !== FIELD_NAME.MEDALLION_DISPENSED,
        ).map((field) => getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id));
      case METER_TYPE.CANDY:
        return CANDY_FIELDS.map((field) =>
          getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id),
        );
      case METER_TYPE.IMPULSE_READER:
        return IMPULSE_READER_FIELDS.map((field) =>
          getFieldName(meterName, field, sublocationId, asset?.id, assetMeter.meterId, assetMeter.id),
        );
      default:
        return [];
    }
  });
};

/**
 * Update meterName for some meter types if it's reconciliation
 *
 * @param {String} meterName
 * @param {Boolean} reconciliation
 * @returns {String} meterName
 */
export const updateMeterNameForReconciliation = (meterName, reconciliation) => {
  let updatedName = meterName;
  if (meterName === METER_TYPE.CASH && reconciliation) {
    updatedName = METER_TYPE.CASH_RECONCILIATION;
  }
  if (meterName === METER_TYPE.CREDIT && reconciliation) {
    updatedName = METER_TYPE.CREDIT_RECONCILIATION;
  }
  if (meterName === METER_TYPE.TOKEN && reconciliation) {
    updatedName = METER_TYPE.TOKEN_RECONCILIATION;
  }
  if (meterName === METER_TYPE.COIN && reconciliation) {
    updatedName = METER_TYPE.COIN_RECONCILIATION;
  }
  if (meterName === METER_TYPE.TOKEN_CHANGER && reconciliation) {
    updatedName = METER_TYPE.TOKEN_CHANGER_RECONCILIATION;
  }
  return updatedName;
};

/**
 * Read initial field values from asset and return objects contains fieldName and fieldValue
 *
 * @param {Object} asset
 * @param {Boolean} reconciliation
 * @param {number} exchangeRate
 * @returns {Object} fields
 * The object contains all input fields as attributes, and their value accordingly if applied
 */
export const getInitialFieldValueFromAsset = (asset, reconciliation = false, exchangeRate) => {
  const { assetMeters, subLocationId: sublocationId } = asset;
  let fields = {};

  assetMeters.forEach((assetMeter) => {
    let meterName = assetMeter?.meter?.name;
    // For reconciliation, the meterName should use METER_TYPE.CASH_RECONCILIATION to include seedlive and adjust
    meterName = updateMeterNameForReconciliation(meterName, reconciliation);
    switch (meterName) {
      case METER_TYPE.BILL:
        return BILL_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          if (reconciliation && field.includes(FIELD_NAME.REVENUE_IN_USD)) {
            fields[name] = 0;
            const billsCollected = assetMeter[FIELD_NAME.BILLS_TAKEN_FROM_CHANGER];
            const coinsCollected = assetMeter[FIELD_NAME.COINS_ADDED_TO_CHANGER];
            fields[name] = getRevenueInUSD(Number(coinsCollected) + Number(billsCollected), exchangeRate);
          }
          return name;
        });
      case METER_TYPE.COIN:
        return COIN_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          return name;
        });
      case METER_TYPE.COIN_RECONCILIATION:
        return COIN_FIELDS_RECONCILIATION.map((field) => {
          const name = getFieldName(
            METER_TYPE.COIN,
            field,
            sublocationId,
            asset?.id,
            assetMeter?.meterId,
            assetMeter.id,
          );
          fields[name] = assetMeter[field] ?? '';
          if (field.includes(FIELD_NAME.ESTIMATED_COIN_REVENUE)) {
            fields[name] = 0;
            const clicksPerPlay = asset[FIELD_NAME.CLICKS_PER_PLAY];
            const costPerPlay = asset[FIELD_NAME.COST_PER_PLAY];
            const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
            const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
            // This expectedCashRevenue is in local currency when exchangeRate is not the default value
            let expectedCashRevenue = getExpectedCashRevenue(currentReading, priorReading, clicksPerPlay, costPerPlay);
            if (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE) {
              // This is to convert expectedCashRevenue into USD
              expectedCashRevenue = getRevenueInUSD(expectedCashRevenue, exchangeRate);
            }
            fields[name] = expectedCashRevenue;
          }
          if (reconciliation && field.includes(FIELD_NAME.REVENUE_IN_USD)) {
            fields[name] = 0;
            const coinsCollected = assetMeter[FIELD_NAME.COINS_COLLECTED];
            fields[name] = getRevenueInUSD(coinsCollected, exchangeRate);
          }
          return name;
        });
      case METER_TYPE.TOKEN_CHANGER:
        return TOKEN_CHANGER_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          if (field.includes(FIELD_NAME.TOKEN_DISPENSED)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
              const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
              const { tokenDispensed } = getTokenChangerRevenueAndDispensedValue(
                currentReading,
                priorReading,
                assetMeter,
              );
              fields[name] = tokenDispensed;
            }
          }
          if (field.includes(FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
              const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
              const { revenueCollected } = getTokenChangerRevenueAndDispensedValue(
                currentReading,
                priorReading,
                assetMeter,
              );
              fields[name] = revenueCollected;
            }
          }
          if (field.includes(FIELD_NAME.REVENUE_IN_USD)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
              const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
              const { revenueCollected } = getTokenChangerRevenueAndDispensedValue(
                currentReading,
                priorReading,
                assetMeter,
              );

              fields[name] = getRevenueInUSD(revenueCollected, exchangeRate);
            }
          }
          return name;
        });
      case METER_TYPE.TOKEN_CHANGER_RECONCILIATION:
        return TOKEN_CHANGER_FIELDS_RECONCILIATION.map((field) => {
          const name = getFieldName(
            METER_TYPE.TOKEN_CHANGER, // For reconciliation, the meterName in formik value still keep as Token Changer
            field,
            sublocationId,
            asset?.id,
            assetMeter?.meterId,
            assetMeter.id,
          );
          fields[name] = assetMeter[field] ?? '';
          if (field.includes(FIELD_NAME.TOKEN_DISPENSED)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
              const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
              const { tokenDispensed } = getTokenChangerRevenueAndDispensedValue(
                currentReading,
                priorReading,
                assetMeter,
              );
              fields[name] = tokenDispensed;
            }
          }
          if (field.includes(FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
              const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
              const { revenueCollected } = getTokenChangerRevenueAndDispensedValue(
                currentReading,
                priorReading,
                assetMeter,
              );
              fields[name] = revenueCollected;
            }
          }
          if (field.includes(FIELD_NAME.REVENUE_IN_USD)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
              const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
              const { revenueCollected } = getTokenChangerRevenueAndDispensedValue(
                currentReading,
                priorReading,
                assetMeter,
              );

              fields[name] = getRevenueInUSD(revenueCollected, exchangeRate);
            }
          }
          if (field.includes(FIELD_NAME.SEEDLIVE_REVENUE)) {
            fields[name] = assetMeter[field] ?? 0;
          }
          return name;
        });
      case METER_TYPE.TOKEN:
        return TOKEN_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          if (field.includes(FIELD_NAME.TOKEN_COLLECTED)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              fields[name] = getDispensedValue(
                assetMeter[FIELD_NAME.CURRENT_READING],
                assetMeter[FIELD_NAME.PRIOR_READING],
              );
            }
          }
          return name;
        });
      case METER_TYPE.TOKEN_RECONCILIATION:
        return TOKEN_FIELDS_RECONCILIATION.map((field) => {
          const name = getFieldName(
            METER_TYPE.TOKEN,
            field,
            sublocationId,
            asset?.id,
            assetMeter?.meterId,
            assetMeter.id,
          );
          fields[name] = assetMeter[field] ?? '';
          if (field.includes(FIELD_NAME.TOKEN_COLLECTED)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              fields[name] = getDispensedValue(
                assetMeter[FIELD_NAME.CURRENT_READING],
                assetMeter[FIELD_NAME.PRIOR_READING],
              );
            }
          }
          return name;
        });
      case METER_TYPE.BONUS:
        return SHARED_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          return name;
        });
      case METER_TYPE.PRIZE:
        return PRIZE_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          if (!reconciliation && field.includes(FIELD_NAME.REMAINING_INVENTORY)) {
            fields[name] = '';
          }
          if (field.includes(FIELD_NAME.PRIZE_DISPENSED)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              fields[name] = getDispensedValue(
                assetMeter[FIELD_NAME.CURRENT_READING],
                assetMeter[FIELD_NAME.PRIOR_READING],
              );
            }
          }
          return name;
        });
      case METER_TYPE.GAME_METER:
        return SHARED_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          return name;
        });
      case METER_TYPE.TICKET:
        return TICKET_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          if (field.includes(FIELD_NAME.TICKET_DISPENSED)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              fields[name] = getDispensedValue(
                assetMeter[FIELD_NAME.CURRENT_READING],
                assetMeter[FIELD_NAME.PRIOR_READING],
              );
            }
          }
          return name;
        });
      case METER_TYPE.CASH_RECONCILIATION:
        return CASH_FIELDS_RECONCILIATION.map((field) => {
          const name = getFieldName(
            METER_TYPE.CASH, // For reconciliation, the meterName in formik value still keep as Cash
            field,
            sublocationId,
            asset?.id,
            assetMeter?.meterId,
            assetMeter.id,
          );
          fields[name] = assetMeter[field] ?? '';
          if (field.includes(FIELD_NAME.METER_ADJUST)) {
            fields[name] = assetMeter[field] ?? 0;
          }
          if (field.includes(FIELD_NAME.EXPECTED_REVENUE)) {
            fields[name] = 0;
            const clicksPerPlay = asset[FIELD_NAME.CLICKS_PER_PLAY];
            const costPerPlay = asset[FIELD_NAME.COST_PER_PLAY];
            const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
            const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
            // This expectedCashRevenue is in local currency when exchangeRate is not the default value
            let expectedCashRevenue = getExpectedCashRevenue(currentReading, priorReading, clicksPerPlay, costPerPlay);
            if (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE) {
              // This is to convert expectedCashRevenue into USD
              expectedCashRevenue = getRevenueInUSD(expectedCashRevenue, exchangeRate);
            }
            // The final expectedCashRevenue should be in USD
            fields[name] = expectedCashRevenue;
          }
          if (field.includes(FIELD_NAME.VARIANCE)) {
            const { [FIELD_NAME.CLICKS_PER_PLAY]: clicksPerPlay, [FIELD_NAME.COST_PER_PLAY]: costPerPlay } = asset;

            const {
              [FIELD_NAME.CURRENT_READING]: currentReading,
              [FIELD_NAME.PRIOR_READING]: priorReading,
              [FIELD_NAME.BILLS_COLLECTED]: billsCollected,
              [FIELD_NAME.COINS_COLLECTED]: coinsCollected,
              [FIELD_NAME.METER_ADJUST]: adjust,
            } = assetMeter;

            const expectedRevenue = getExpectedCashRevenue(currentReading, priorReading, clicksPerPlay, costPerPlay);
            let varianceValue = assetMeter[FIELD_NAME.VARIANCE] ?? 0;
            if (!varianceValue) {
              // This is in local currency when exchangeRate is not the default value
              varianceValue = Number(
                roundOffValue(getCashRevenue(billsCollected, coinsCollected, adjust) - expectedRevenue, 2),
              );

              if (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE) {
                // This is to convert expectedCashRevenue into USD
                varianceValue = Number(roundOffValue(getRevenueInUSD(varianceValue, exchangeRate), 2));
              }
            }

            fields[name] = varianceValue;
          }
          if (reconciliation && field.includes(FIELD_NAME.REVENUE_IN_USD)) {
            fields[name] = 0;
            const billsCollected = assetMeter[FIELD_NAME.BILLS_COLLECTED];
            const coinsCollected = assetMeter[FIELD_NAME.COINS_COLLECTED];
            const adjust = assetMeter[FIELD_NAME.METER_ADJUST];
            const revenue = getCashRevenue(billsCollected, coinsCollected, adjust);
            fields[name] = getRevenueInUSD(revenue, exchangeRate);
          }
          return name;
        });
      case METER_TYPE.CASH:
        return CASH_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          return name;
        });
      case METER_TYPE.CREDIT:
        return SHARED_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          return name;
        });
      case METER_TYPE.CREDIT_RECONCILIATION:
        return CREDIT_FIELDS_RECONCILIATION.map((field) => {
          const name = getFieldName(
            METER_TYPE.CREDIT,
            field,
            sublocationId,
            asset?.id,
            assetMeter?.meterId,
            assetMeter.id,
          );
          fields[name] = assetMeter[field] ?? '';
          if (field.includes(FIELD_NAME.METER_ADJUST) || field.includes(FIELD_NAME.SEEDLIVE_REVENUE)) {
            fields[name] = assetMeter[field] ?? 0;
          }
          if (field.includes(FIELD_NAME.EXPECTED_REVENUE)) {
            fields[name] = 0;
            const currentReading = assetMeter[FIELD_NAME.CURRENT_READING];
            const priorReading = assetMeter[FIELD_NAME.PRIOR_READING];
            const clicksPerPlay = asset[FIELD_NAME.CLICKS_PER_PLAY];
            const costPerPlay = asset[FIELD_NAME.COST_PER_PLAY];
            // Estimated cc revenue uses the same logic as the expected cash revenue
            fields[name] = getExpectedCashRevenue(currentReading, priorReading, clicksPerPlay, costPerPlay);
          }
          return name;
        });
      case METER_TYPE.MEDALLION:
        return MEDALLION_FIELDS.map((field) => {
          const name = getFieldName(meterName, field, sublocationId, asset?.id, assetMeter?.meterId, assetMeter.id);
          fields[name] = assetMeter[field] ?? '';
          if (field.includes(FIELD_NAME.MEDALLION_DISPENSED)) {
            fields[name] = 0;
            if (
              isValueValid(assetMeter[FIELD_NAME.CURRENT_READING]) &&
              isValueValid(assetMeter[FIELD_NAME.PRIOR_READING])
            ) {
              fields[name] = getDispensedValue(
                assetMeter[FIELD_NAME.CURRENT_READING],
                assetMeter[FIELD_NAME.PRIOR_READING],
              );
            }
          }
          return name;
        });
      case METER_TYPE.CANDY:
        return CANDY_FIELDS.map((field) => {
          const name = getFieldName(
            METER_TYPE.CANDY,
            field,
            sublocationId,
            asset?.id,
            assetMeter?.meterId,
            assetMeter.id,
          );
          fields[name] = assetMeter[field] ?? '';
          if (reconciliation && field.includes(FIELD_NAME.REVENUE_IN_USD)) {
            fields[name] = 0;
            if (isValueValid(assetMeter[FIELD_NAME.COINS_COLLECTED])) {
              fields[name] = getRevenueInUSD(assetMeter[FIELD_NAME.COINS_COLLECTED], exchangeRate);
            }
          }
          return name;
        });
      case METER_TYPE.IMPULSE_READER:
        return IMPULSE_READER_FIELDS.map((field) => {
          const name = getFieldName(
            METER_TYPE.IMPULSE_READER,
            field,
            sublocationId,
            asset?.id,
            assetMeter?.meterId,
            assetMeter.id,
          );
          fields[name] = assetMeter[field] ?? '';
          if (reconciliation && field.includes(FIELD_NAME.REVENUE_IN_USD)) {
            fields[name] = 0;
            if (isValueValid(assetMeter[FIELD_NAME.GAME_REVENUE])) {
              fields[name] = getRevenueInUSD(assetMeter[FIELD_NAME.GAME_REVENUE], exchangeRate);
            }
          }
          return name;
        });
      default:
        return [];
    }
  });
  if (reconciliation) {
    fields[`comment_${sublocationId}_${asset?.id}`] = asset?.comments?.map((comment) => ({
      id: comment?.id,
      createdAt: getESTDateFormat(dayjs(comment?.createdAt)),
      comment: comment?.comment,
      name: `${comment?.createdByUser?.firstName} ${comment?.createdByUser?.lastName}`,
      createdByUser: comment?.createdByUser?.id,
    }));
  }
  fields[`report_${asset.subLocationId}_${asset.id}`] = {
    assetId: asset?.id,
    assetName: asset?.name,
    issue: asset?.noCollectionReason?.collectionItemNoCollectIssueId,
    reason: asset?.noCollectionReason?.reason,
    status: Boolean(asset?.noCollectionReason?.status?.title === MESSAGE.REPORT_STATUS_PENDING),
  };
  return fields;
};

/**
 * Read array of fieldNames and return categorized object
 *
 * @param {Array} fieldTypeValue Array of string, each contains fieldName: meterName, field, sublocationId, assetId, assetMeter.meterId, assetMeter.Id
 * @returns {object} {[sublocationId_assetId_meterId_assetMeter.Id]: [fieldNames]}
 *
 */
export const sortByType = (fieldTypeValue) => {
  let obj = {};
  const indexKeys = fieldTypeValue.map((el) => {
    // eslint-disable-next-line
    const [meterName, field, sublocationId, assetId, meterId, assetMeterId] = el.split('_');
    const indexKey = [sublocationId, assetId, meterId, assetMeterId].join('_');
    return indexKey;
  });
  const noDuplication = new Set(indexKeys);
  Array.from(noDuplication).map((el) => {
    let values = [];

    fieldTypeValue.map((value) => {
      if (value.includes(el)) {
        values = [...values, value];
      }
    });
    obj[el] = values;
  });
  return obj;
};

/**
 * Read asset and generate validation field names of all supported meter types.
 * Loop field names to divide field names by meter type
 * {
    "Bill": {},
    "Token": {
        "1_1_3_3": [
            "Token_currentReading_1_1_3_3_2",
            "Token_testVends_1_1_3_3_2",
            "Token_tokensCollected_1_1_3_3_2"
        ]
    },
    "Ticket": {
        "1_1_2_2": [
            "Ticket_currentReading_1_1_2_2_1"
        ]
    },
 * }
 * @param {Array} asset
 * @returns
 */
export const categorizeFieldNamesByTypes = (asset) => {
  const fieldNames = getValidationFieldNames(asset);
  const fieldTypes = {
    [METER_TYPE.BILL]: [],
    [METER_TYPE.CASH]: [],
    [METER_TYPE.COIN]: [],
    [METER_TYPE.TOKEN]: [],
    [METER_TYPE.TOKEN_CHANGER]: [],
    [METER_TYPE.TICKET]: [],
    [METER_TYPE.CREDIT]: [],
    [METER_TYPE.PRIZE]: [],
    [METER_TYPE.BONUS]: [],
    [METER_TYPE.MEDALLION]: [],
    [METER_TYPE.CANDY]: [],
    [METER_TYPE.IMPULSE_READER]: [],
  };

  fieldNames.map((field) => {
    const type = field.split('_')[0];
    fieldTypes[type] = [...fieldTypes[type], field];
  });
  Object.entries(fieldTypes).map(([fieldTypeKey, fieldTypeValue]) => {
    fieldTypes[fieldTypeKey] = sortByType(fieldTypeValue);
  });
  return fieldTypes;
};

/**
 * Read asset and construct validation scheme based on meter types.
 * For example, cash has three fields are dependent on each other: currentMeter, billsCollected and coinsCollected. They must either be none value or all have values
 *
 * @param {Array} asset Array of asset Object
 * @returns  { Object } schema and dependency to be used in getValidationSchemeYupObject.
 * */
export const getValidationScheme = (asset) => {
  const fieldTypes = categorizeFieldNamesByTypes(asset);
  let schema = {};
  let dependencies = [];
  Object.entries(fieldTypes).map(([typeKey, values]) => {
    if ([METER_TYPE.CASH, METER_TYPE.BILL].includes(typeKey)) {
      // eslint-disable-next-line
      Object.entries(values).map(([key, v]) => {
        const result = threeConditionSchema(...v);
        schema = { ...schema, ...result.schema };
        dependencies = [...dependencies, ...result.dependency];
      });
    }
    if ([METER_TYPE.COIN].includes(typeKey)) {
      // eslint-disable-next-line
      Object.entries(values).map(([key, v]) => {
        const result = twoConditionSchema(...v);
        schema = { ...schema, ...result.schema };
        dependencies = [...dependencies, ...result.dependency];
      });
    }
  });

  return { schema: schema, dependencies: dependencies };
};

/**
 * Read from formik values and reorganize data to construct object.
 * formik values includes meter fieldNames, values, report, comments, intercard
 *
 * @param {Object} fieldValues
 * formik values
 * {[meterName_fieldName_sublocationId_assetId_assetMeterId_meterId]: value}
 *
 * @returns {Array} Array of objects for each input field
 * construct object to have meterName, fieldName, fieldValue, sublocationId, assetId, assetMeterId:
 * [{
    "meterName": "Cash",
    "fieldName": "priorReading",
    "sublocationId": "",
    "assetId": "",
    "meterId": "",
    "assetMeterId": "",
    "fieldValue": ""
  }]
 */
export const getRawDataFromFieldValues = (fieldValues) => {
  const results = Object.entries(fieldValues).map(([key, value]) => {
    const names = key.split('_');
    // handle report
    if (key.includes('report')) {
      const element = {
        sublocationId: names[1],
        assetId: names[2],
        report: value,
      };
      return element;
    }
    //handle comments
    if (key.includes('comment')) {
      const element = {
        sublocationId: names[1],
        assetId: names[2],
        comment: value,
      };
      return element;
    }
    // handle intercard
    if (key.includes(METER_TYPE.INTERCARD)) {
      const element = {
        fieldName: names[1],
        sublocationId: names[2],
        assetId: names[3],
      };
      if (
        (names[1] === FIELD_NAME.CASH_DEBITS || names[1] === FIELD_NAME.STANDARD_PLAY) &&
        names.length > 4 &&
        names[4]
      ) {
        element['transactionId'] = names[4];
      }
      return element;
    }
    if (key.includes('independent')) {
      const element = {
        fieldName: names[0],
        sublocationId: names[1],
        fieldValue: value,
      };
      return element;
    }
    // handle meters
    if (value !== '' && isValueValid(value)) {
      const element = {};
      element.meterName = names[0];
      element.fieldName = names[1];
      element.sublocationId = names[2];
      element.assetId = names[3];
      element.meterId = names[4];
      element.assetMeterId = names[5];
      element.fieldValue = value;
      return element;
    }
    return false;
  });
  return results.filter((el) => el !== false);
};

/**
 * Get specific fields according to meter types
 *
 * @param {String} meterName
 * @returns {Array} of fields for related meter type
 */
export const getFieldsByMeterType = (meterName) => {
  switch (meterName) {
    case METER_TYPE.BILL:
      return BILL_FIELDS;
    case METER_TYPE.COIN:
      return COIN_FIELDS;
    case METER_TYPE.COIN_RECONCILIATION:
      return COIN_FIELDS_RECONCILIATION;
    case METER_TYPE.TOKEN_CHANGER:
      return TOKEN_CHANGER_FIELDS;
    case METER_TYPE.TOKEN_CHANGER_RECONCILIATION:
      return TOKEN_CHANGER_FIELDS_RECONCILIATION;
    case METER_TYPE.TOKEN:
      return TOKEN_FIELDS;
    case METER_TYPE.TOKEN_RECONCILIATION:
      return TOKEN_FIELDS_RECONCILIATION;
    case METER_TYPE.BONUS:
      return SHARED_FIELDS;
    case METER_TYPE.PRIZE:
      return PRIZE_FIELDS;
    // case METER_TYPE.GAME_METER:
    //   return SHARED_FIELDS;
    case METER_TYPE.TICKET:
      return TICKET_FIELDS;
    case METER_TYPE.CASH:
      return CASH_FIELDS;
    case METER_TYPE.CASH_RECONCILIATION:
      return CASH_FIELDS_RECONCILIATION;
    case METER_TYPE.CREDIT:
      return SHARED_FIELDS;
    case METER_TYPE.CREDIT_RECONCILIATION:
      return CREDIT_FIELDS_RECONCILIATION;
    case METER_TYPE.MEDALLION:
      return SHARED_FIELDS;
    case METER_TYPE.CANDY:
      return CANDY_FIELDS;
    case METER_TYPE.IMPULSE_READER:
      return IMPULSE_READER_FIELDS;
    default:
      return [];
  }
};

/**
 * Get fields for meterType and filter excluding fields and construct object with assetMeterId and meterName
 *
 * @param {object} asset
 * @param {array} filteredRawData
 * @param {boolean} reconciliation
 * @returns {array} expectedData: an array of objects, each object contains attributes needs to be collected according to meter names provided by assets:
 * [{
    "assetMeterId": value,
    "meterName": MeterName,
    "currentReading": value,
    "testVends": value,
    "tokensCollected": value
  }]
 */
export const getExpectedDataFromAsset = (asset, filteredRawData, reconciliation) => {
  let expectedData = [];
  if (filteredRawData.length > 0) {
    expectedData = asset?.assetMeters?.map((assetMeter) => {
      let meterName = assetMeter?.meter?.name;
      meterName = updateMeterNameForReconciliation(meterName, reconciliation);

      let names = getFieldsByMeterType(meterName);
      names = names.filter((name) => !EXCLUDING_FIELD_NAMES.find((fieldName) => fieldName === name));
      const obj = {};
      obj.assetMeterId = assetMeter.id;
      obj.modemId = assetMeter?.modemId || null;

      if (names.length > 0) {
        obj.meterName = meterName;
        names.map((name) => {
          obj[name] = null;
        });
        return obj;
      }
      // to skip unsupported meter type
      return false;
    });
  }
  return expectedData;
};

/**
 * Get comments data
 *
 * @param {array} filteredRawData : filtered form data, excluding non-store form data
 * @param {array} expectedData object of array
 * {
 *  "assetMeterId": id,
    "meterName": meterType,
    "currentReading": fieldValue
  }
 * @returns {array} commentData
  [
    {
        "commentText": ""
    }
  ]
 */
export const getCommentDataFromRawData = (filteredRawData, expectedData) => {
  let commentData = [];
  filteredRawData.map((el) => {
    const assetMeterId = Number(el?.assetMeterId);
    if (el && isValueValid(el.fieldValue) && !el.report && assetMeterId) {
      const index = expectedData?.findIndex((data) => data?.assetMeterId === assetMeterId);
      if (index !== -1) {
        expectedData[index][el.fieldName] = el.fieldValue;
      }
    }
    if (el && el?.comment) {
      commentData = el?.comment
        ?.filter((value) => value?.id === undefined) //filtering out new comments as we only want to pass new comments in the API
        ?.map((item) => ({ commentText: item?.comment }));
    }
  });
  return commentData;
};

/**
 * Only include form data that all fields are filled
 *
 * @param {array} expectedData
 * @returns {array} filtered expected data: to remove test and get meter fields that has all sub data
 */
const getFilteredExpectedData = (expectedData) =>
  expectedData
    .map((el) => {
      // we have to remove condition of null for testVends for all meters are they are not mandatory but we have to pass in API.
      let tempEl = { ...el };
      Object.keys(tempEl).some((e) => e === FIELD_NAME.TEST_VENDS) && tempEl[FIELD_NAME.TEST_VENDS] === null
        ? delete tempEl[FIELD_NAME.TEST_VENDS]
        : tempEl;
      // we have to remove condition of null for modemId for all meters are they are not mandatory but we have to pass in API.
      Object.keys(tempEl).some((e) => e === 'modemId') && tempEl['modemId'] === null
        ? delete tempEl['modemId']
        : tempEl;
      if (Object.keys(tempEl)?.includes(FIELD_NAME.REMAINING_INVENTORY)) {
        tempEl = isValueValid(tempEl[FIELD_NAME.REMAINING_INVENTORY])
          ? { ...tempEl }
          : { ...tempEl, [FIELD_NAME.REMAINING_INVENTORY]: 0 };
      }

      if (Object.values(tempEl).every((v) => v !== '' && isValueValid(v))) {
        //add modemId field to each meter
        tempEl.modemId = el?.modemId || null;
        return tempEl;
      }
      return null;
    })
    .filter((item) => item !== null);

/**
 * Structure data from formik values to prepare for API call
 *
 * @param {Object} values formik.values: {fieldName : value}
 * @param {Array} sublocations Array of sublocations
 * @returns {Object} { transformedSublocations: filteredTransformedData, formikData: rawData
 * transformedSublocations: Array of field data structured by assets to meet API requirement
 * [
    {
        "sublocationId": 1,
        "assetId": 1,
        "data": [
            {
                "assetMeterId": 3,
                "meterName": "Token",
                "currentReading": "20",
                "testVends": "20",
                "tokensCollected": "20"
            }
        ]
    }
]
 * formikData: Array of formik values:
 * [{
    "meterName": "Coin",
    "fieldName": "priorReading",
    "sublocationId": "1",
    "assetId": "2",
    "meterId": "4",
    "assetMeterId": "13",
    "fieldValue": 20
  }] }
 */
export const getTransformedData = (values, sublocations, reconciliation = false) => {
  const rawData = getRawDataFromFieldValues(values);
  const assets = sublocations.flatMap((sub) => sub.assets);
  const rawReportData = rawData?.filter((item) => Object.keys(item)?.includes('report'));
  const independentRepData = rawData?.filter((item) => item?.fieldName?.includes('independent'));

  const revenueMeterData = [];
  // assetsWithValues is used to check if the sublocation has any valid meter data
  const assetsWithValues = new Set();
  // data grouped by asset id
  const assetsElementResultsData = assets.flatMap((asset) => {
    // get data associated to this asset
    // skip isExcludedFields as they won't be pass through API call
    // add last collection date and asset params for token locations
    const subloc = sublocations?.filter((sub) => String(sub?.id) === String(asset.subLocationId));
    const sublocLastCollectionDate = getLastCollectionDate(subloc[0], reconciliation);
    const formattedSublocDate = checkValueNotNullUndefinedBlank(sublocLastCollectionDate)
      ? dateFormatForApi(sublocLastCollectionDate)
      : null;
    const assetLastCollectionDate = asset?.lastCollectionByAsset?.dateOfReading;

    const finalAssetLastCollectionDate = !assetLastCollectionDate
      ? formattedSublocDate || null
      : dateFormatForApi(assetLastCollectionDate);
    const filteredRawData = rawData.filter((data) => {
      const isExcludedField = EXCLUDING_FIELD_NAMES.find((el) =>
        reconciliation &&
        [
          FIELD_NAME.PRIOR_READING,
          FIELD_NAME.SEEDLIVE_REVENUE,
          FIELD_NAME.CASH_DEBITS,
          FIELD_NAME.STANDARD_PLAY,
        ].includes(el)
          ? false
          : el === data.fieldName,
      );
      if (data.sublocationId === String(asset.subLocationId) && data.assetId === String(asset.id) && !isExcludedField) {
        return data;
      }
    });

    const expectedData = getExpectedDataFromAsset(asset, filteredRawData, reconciliation);
    const commentData = getCommentDataFromRawData(filteredRawData, expectedData);
    const reportData = getReportDataFromRawData(rawReportData, asset, reconciliation);
    let revenueMeterAssetMeterData;
    if (reconciliation) {
      revenueMeterAssetMeterData = expectedData.filter((el) => REVENUE_METER_TYPES.includes(el?.meterName));
    }
    if (revenueMeterAssetMeterData && revenueMeterAssetMeterData.length > 0) {
      const data = {
        sublocationId: asset.subLocationId,
        assetId: asset.id,
        data: revenueMeterAssetMeterData,
      };
      revenueMeterData.push(data);
    }
    const hasData = hasAssetMeterData(expectedData);
    if (hasData || (asset?.intercardTransactions && asset?.intercardTransactions?.length > 0)) {
      assetsWithValues.add(asset.id);
    }

    const independentRep = {};
    independentRepData
      ?.filter((data) => String(data.sublocationId) === String(asset.subLocationId))
      ?.forEach((item) => {
        if (item.fieldName === INDEPENDENT_REP_OF_SUBLOCATION.REP_ID) {
          independentRep[item.fieldName] = item.fieldValue?.value;
        } else {
          independentRep[item.fieldName] = item.fieldValue;
        }
      });

    return {
      assetId: asset.id,
      expectedData: expectedData,
      independentRep,
      independentRepData,
      comments: commentData,
      reportData: { ...reportData[0] },
      sublocationId: asset.subLocationId,
      costPerPlay: asset?.costPerPlay,
      clicksPerPlay: asset?.clicksPerPlay,
      numberOfPlays: asset?.numberOfPlays,
      lastApprovedCollectionDate: finalAssetLastCollectionDate,
    };
  });

  // For each asset, if it has any asset meter input data, include all assetMeters for the entire assets regardless if all asset meters are empty or not
  // Otherwise, do not include the asset at all
  const transformedDataByAsset = assets.flatMap((asset) => {
    const resultData = assetsElementResultsData.find((el) => el.assetId === asset.id);
    let actualData = resultData?.expectedData;
    if (assetsWithValues.size === 0 || !assetsWithValues.has(asset.id)) {
      actualData = getFilteredExpectedData(resultData?.expectedData);
    }
    const reportData = resultData?.reportData;
    const independentRep = resultData?.independentRep;

    if (actualData.length > 0) {
      return {
        sublocationId: asset.subLocationId,
        assetId: asset.id,
        costPerPlay: asset?.costPerPlay,
        clicksPerPlay: asset?.clicksPerPlay,
        numberOfPlays: asset?.numberOfPlays,
        lastApprovedCollectionDate: resultData?.lastApprovedCollectionDate,
        comments: resultData?.comments,
        data: actualData,
        reportData: { ...reportData },
        ...independentRep,
      };
    }
    if (!isArrayWithLength(actualData) && JSON.stringify(reportData).length > 2) {
      return {
        sublocationId: asset.subLocationId,
        assetId: asset.id,
        costPerPlay: asset?.costPerPlay,
        clicksPerPlay: asset?.clicksPerPlay,
        numberOfPlays: asset?.numberOfPlays,
        lastApprovedCollectionDate: resultData?.lastApprovedCollectionDate,
        comments: resultData?.comments,
        data: [],
        reportData: { ...reportData },
        ...independentRep,
      };
    }

    if (actualData?.length === 0 && independentRepData?.length > 0) {
      return {
        sublocationId: asset.subLocationId,
        assetId: asset.id,
        ...independentRep,
      };
    }
    return false;
  });
  const checkHasIndependentValues = transformedDataByAsset?.some((item) => {
    const independentValues = Object.keys(item).filter((key) => key.includes('independent') && item[key] !== null);
    return independentValues.some((value) => value !== null);
  });

  const checkAllAssetDataNotEmpty =
    transformedDataByAsset?.some((item) => item?.data?.length > 0) || checkHasIndependentValues;
  const finalTransformedData = transformedDataByAsset.filter((el) => el != false && checkAllAssetDataNotEmpty);
  return {
    transformedSublocations: finalTransformedData,
    formikData: rawData,
    revenueMeterData: revenueMeterData,
  };
};

/**
 *
 * @param {Array} sublocations
 * @returns {Object} intercardData {assetId: [intercardData]}
 */
export const getIntercardData = (sublocations) => {
  let intercardData = {};
  sublocations?.map((sub) => {
    sub.assets?.map((asset) => {
      if (isArrayWithLength(asset?.intercardTransactions)) {
        const transformedIntercardTransaction = asset?.intercardTransactions?.map((transaction) =>
          // eslint-disable-next-line
          (({ assetTag, ...rest }) => rest)(transaction),
        );
        intercardData[asset.id] = {
          intercardData: transformedIntercardTransaction,
          sublocationId: sub?.id,
          assetId: asset?.id,
        };
      }
    });
  });
  return intercardData;
};

export const getIntercardDataFromData = (data) => data?.intercardData;

/**
 * Structure data to prepare for API call
 *
 * @param {Object} intercardData
 * @returns {Array} intercardAssets
 * [
    {
        "sublocationId": 11803,
        "assets": [
            {
                "assetId": 83228,
                "data": [],
                "intercardData": [
                    {
                        "standardPlay": "27",
                        "cashDebits": 38.37,
                        "points": 10,
                        "group": 'Group 1 - Video"
                        "assetSubTag": null
                    }
                ]
            }
        ]
    }]
 */
export const getIntercardDataForSubmission = (intercardData, location) => {
  if (isArrayWithLength(Object.values(intercardData))) {
    return Object.values(intercardData).map((intercard) => {
      const transformedSub = {};
      //Add last collection date for intercard asset
      const subloc = location?.sublocations?.filter((sub) => String(sub?.id) === String(intercard?.sublocationId));
      const sublocLastCollectionDate = getLastCollectionDate(subloc[0]);
      const formattedSublocDate = checkValueNotNullUndefinedBlank(sublocLastCollectionDate)
        ? dateFormatForApi(sublocLastCollectionDate)
        : null;
      const asset = subloc[0]?.assets?.filter((asset) => String(asset?.id) === String(intercard?.assetId))[0];
      const assetLastCollectionDate = asset?.lastCollectionByAsset?.dateOfReading;

      const finalAssetLastCollectionDate = !assetLastCollectionDate
        ? formattedSublocDate || null
        : dateFormatForApi(assetLastCollectionDate);
      transformedSub.sublocationId = intercard?.sublocationId;
      transformedSub.assets = [
        {
          assetId: intercard?.assetId,
          data: [],
          intercardData: transfromDataFromStringToNumber(intercard?.intercardData || []),
          lastApprovedCollectionDate: finalAssetLastCollectionDate,
          clicksPerPlay: asset?.clicksPerPlay,
          costPerPlay: asset?.costPerPlay,
          numberOfPlays: asset?.numberOfPlays,
        },
      ];
      return transformedSub;
    });
  }
  return [];
};

/**
 * Structure data to prepare API call for intercard data only collections
 *
 * @param {Array} intercardArr Array that contains intercard transactions
 * @param {object} sublocationDates {[sublocationId]: dateEntered, dateOfReading}
 * @returns
 */
export const getTransformedDataForIntercardSublocations = (intercardArr, sublocationDates, location) => {
  const sublocationIds = [...new Set(intercardArr?.map((item) => item?.sublocationId))];
  const sublocations = sublocationIds?.map((subId) => {
    const initialDatesForSublocation = getInitialDatesForSublocation();
    const sublocationDatesForSubmit = checkValueNotNullUndefinedBlank(sublocationDates)
      ? sublocationDates[subId] || { ...initialDatesForSublocation }
      : { ...initialDatesForSublocation };
    let assetsData = intercardArr
      ?.filter((intercardData) => String(intercardData?.sublocationId) === String(subId))
      ?.flatMap((data) => data?.assets);
    //Add last collection and start date for sublocation for intercard sublocation
    const subloc = location?.sublocations?.filter((subloc) => String(subloc?.id) === String(subId));
    const dates = getSublocationDates(subloc[0]);
    return {
      sublocationId: subId,
      ...dates,
      ...sublocationDatesForSubmit,
      assets: assetsData,
    };
  });
  return sublocations;
};

/**
 *
 * @param {object} prevIntercardData
 * @param {Array} updatedAssets
 * @returns
 */
export const getPrevIntercardDataExcludingUpdatedIntercard = (prevIntercardData, updatedAssets) => {
  const sublocAssetIds = updatedAssets?.map((asset) => String(asset?.id));
  let intercardData = {};
  if (checkObjectNotEmpty(prevIntercardData)) {
    for (let i in prevIntercardData) {
      if (!sublocAssetIds?.includes(String(i))) {
        intercardData = { ...intercardData, [i]: { ...prevIntercardData[i] } };
      }
    }
  }
  return intercardData;
};

/**
 * read playcard value from sublocation
 * cashRefunds, bulkEncodedPlaycards, and adjustments are user input data
 *
 * @param {object} sublocation
 * @returns {object} initialPlaycardState
 */
export const getInitialPlaycardState = (playCardObj) => {
  let initialPlaycardState = {
    // Cash
    cashPlaycardSales: 0,
    cashRefunds: 0,
    netCashTotal: 0,
    // Credit Card
    salesFromCreditCard: 0,
    creditCardRefunds: 0,
    netCreditCardTotal: 0,
    // Total
    complimentaryPlaycards: 0,
    bulkEncodedPlaycards: 0,
    adjustments: 0,
    adjustedPlaycardSales: 0,
  };

  const cashPlaycardSaleVal = playCardObj?.totalCashRevenue ? Number(roundOffValue(playCardObj?.totalCashRevenue)) : 0;
  const salesFromCreditCardVal = playCardObj?.totalCreditCardRevenue
    ? Number(roundOffValue(playCardObj?.totalCreditCardRevenue))
    : 0;
  const complimentaryPlaycardsVal = playCardObj?.totalComp ? Number(roundOffValue(playCardObj?.totalComp)) : 0;
  const creditCardRefundsVal = playCardObj?.totalRefunds ? Number(roundOffValue(playCardObj?.totalRefunds)) : 0;
  const netCashTotalVal = checkValueNotNullUndefinedBlank(cashPlaycardSaleVal) ? cashPlaycardSaleVal : 0;

  let netCreditCardTotalVal = 0;
  if (
    checkValueNotNullUndefinedBlank(salesFromCreditCardVal) &&
    checkValueNotNullUndefinedBlank(creditCardRefundsVal)
  ) {
    netCreditCardTotalVal = salesFromCreditCardVal - creditCardRefundsVal;
  } else if (checkValueNotNullUndefinedBlank(salesFromCreditCardVal)) {
    netCreditCardTotalVal = salesFromCreditCardVal;
  } else if (checkValueNotNullUndefinedBlank(creditCardRefundsVal)) {
    netCreditCardTotalVal = creditCardRefundsVal;
  }

  initialPlaycardState = {
    ...initialPlaycardState,
    cashPlaycardSales: cashPlaycardSaleVal,
    netCashTotal: netCashTotalVal,
    salesFromCreditCard: salesFromCreditCardVal,
    creditCardRefunds: creditCardRefundsVal,
    complimentaryPlaycards: complimentaryPlaycardsVal,
    netCreditCardTotal: netCreditCardTotalVal,
  };

  const totalCashRevenue = playCardObj?.totalCashRevenue || 0;
  const totalCreditCardRevenue = playCardObj?.totalCreditCardRevenue || 0;
  const totalRefunds = playCardObj?.totalRefunds || 0;
  const totalComp = playCardObj?.totalComp || 0;

  const adjustedPlaycardSalesVal = totalCashRevenue + (totalCreditCardRevenue - totalRefunds) + totalComp;

  initialPlaycardState = {
    ...initialPlaycardState,
    adjustedPlaycardSales: adjustedPlaycardSalesVal,
  };
  return initialPlaycardState;
};

/**
 * construct object that contains playcard data for submission
 *
 * @param {object} playCards
 * @param {String} subId
 * @returns
 */
export const getPlaycardSubmissionData = (playCards, subId) => {
  let submitPlaycard = {};
  if (subId && checkValueNotNullUndefinedBlank(playCards) && checkValueNotNullUndefinedBlank(playCards[subId])) {
    const {
      cashPlaycardSales,
      cashRefunds,
      salesFromCreditCard,
      creditCardRefunds,
      complimentaryPlaycards,
      bulkEncodedPlaycards,
      adjustments,
    } = playCards[subId];
    submitPlaycard = {
      playcardCashSales: checkNumberIsNaNAndInfinity(cashPlaycardSales),
      playcardCashRefunds: checkNumberIsNaNAndInfinity(cashRefunds),
      playcardCCSales: checkNumberIsNaNAndInfinity(salesFromCreditCard),
      playcardCCRefunds: checkNumberIsNaNAndInfinity(creditCardRefunds),
      playcardBulkEncoded: checkNumberIsNaNAndInfinity(bulkEncodedPlaycards),
      playcardComps: checkNumberIsNaNAndInfinity(complimentaryPlaycards),
      playcardAdjustments: checkNumberIsNaNAndInfinity(adjustments),
    };
  }
  return submitPlaycard;
};

export const transfromDataFromStringToNumber = (data = []) => {
  let updatedData = [];
  for (let i = 0; i < data.length; i++) {
    const newObject = {};
    Object.keys(data[i]).map((key) => {
      const fieldToConvert = [
        FIELD_NAME.PRIOR_READING,
        FIELD_NAME.CURRENT_READING,
        FIELD_NAME.TEST_VENDS,
        FIELD_NAME.COINS_COLLECTED,
        FIELD_NAME.BILLS_COLLECTED,
        FIELD_NAME.TOKEN_COLLECTED,
        FIELD_NAME.TOKEN_REVENUE,
        FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER,
        FIELD_NAME.CASH_AMOUNT,
        FIELD_NAME.TICKET_DISPENSED,
        FIELD_NAME.TOKEN_DISPENSED,
        FIELD_NAME.BILLS_TAKEN_FROM_CHANGER,
        FIELD_NAME.COINS_ADDED_TO_CHANGER,
        FIELD_NAME.PRIZE_DISPENSED,
        FIELD_NAME.REMAINING_INVENTORY,
        FIELD_NAME.MEDALLION_DISPENSED,
        FIELD_NAME.REVENUE_IN_USD,
        FIELD_NAME.CASH_DEBITS,
        FIELD_NAME.STANDARD_PLAY,
        FIELD_NAME.POINTS,
        FIELD_NAME.SEEDLIVE_REVENUE,
        FIELD_NAME.METER_ADJUST,
        FIELD_NAME.EXPECTED_REVENUE,
        FIELD_NAME.CLICKS_PER_PLAY,
        FIELD_NAME.COST_PER_PLAY,
        FIELD_NAME.ESTIMATED_COIN_REVENUE,
        FIELD_NAME.VARIANCE,
      ];
      newObject[key] = fieldToConvert.includes(key) ? Number(data[i][key]) : data[i][key];
    });
    updatedData.push(newObject);
  }

  return updatedData;
};

/**
 * Structure data for summary, if submission is true, update intercard and playcard data
 *
 * @param {Array} sublocationIds
 * @param {Array} transformedDataByAsset
 * @returns {Array} Array of objects
 */
export const getTransformedSublocationData = (
  sublocationIds,
  transformedDataByAsset,
  playCards,
  intercardData,
  sublocationDates,
  location,
) => {
  const finalTransformedData = sublocationIds?.map((subId) => {
    const intercardTransactionBySubId = Object.values(intercardData).filter(
      (data) => String(data.sublocationId) === String(subId),
    );
    const initialDatesForSublocation = getInitialDatesForSublocation();
    const sublocationDatesForSubmit = checkValueNotNullUndefinedBlank(sublocationDates)
      ? sublocationDates[subId] || { ...initialDatesForSublocation }
      : { ...initialDatesForSublocation };
    //Add sublocation last collection date
    const subloc = location?.sublocations?.filter((subloc) => String(subloc?.id) === String(subId))[0];
    const dates = getSublocationDates(subloc);
    const assetsData = transformedDataByAsset
      .map((el) => {
        // remove sublocationId
        if (el.sublocationId === subId) {
          let submissionData = {
            assetId: el.assetId,
            costPerPlay: el?.costPerPlay,
            clicksPerPlay: el?.clicksPerPlay,
            numberOfPlays: el?.numberOfPlays,
            lastApprovedCollectionDate: el?.lastApprovedCollectionDate,
            data: transfromDataFromStringToNumber(el?.data || []),
          };
          if (Object.values(intercardData).length > 0 && intercardData[el.assetId]) {
            submissionData.intercardData = transfromDataFromStringToNumber(
              getIntercardDataFromData(intercardData[el.assetId]),
            );
          }
          const comments = el?.comments ? { comments: el?.comments } : {};
          const reportData = el?.reportData ? { ...el?.reportData } : {};
          if (Object.values(comments).length > 0) {
            submissionData = { ...submissionData, ...comments, ...reportData };
          }
          return submissionData;
        }
      })
      .filter((el) => el !== undefined);
    // Add intercard transactions to assets for those have no input in assetMeters
    const assetDataIds = assetsData?.map((data) => data?.assetId);
    let index = assetsData.length;
    Object.values(intercardTransactionBySubId).map((intercardTransaction) => {
      if (!assetDataIds.includes(intercardTransaction?.assetId)) {
        //add last collection data for asset
        const subloc = location?.sublocations?.filter(
          (sub) => String(sub?.id) === String(intercardTransaction?.sublocationId),
        );
        const sublocLastCollectionDate = getLastCollectionDate(subloc[0]);
        const formattedSublocDate = checkValueNotNullUndefinedBlank(sublocLastCollectionDate)
          ? dateFormatForApi(sublocLastCollectionDate)
          : null;
        const asset = subloc[0]?.assets?.filter(
          (asset) => String(asset?.id) === String(intercardTransaction?.assetId),
        )[0];
        const assetLastCollectionDate = asset?.lastCollectionByAsset?.dateOfReading;

        const finalAssetLastCollectionDate = !assetLastCollectionDate
          ? formattedSublocDate || null
          : dateFormatForApi(assetLastCollectionDate);
        assetsData[index++] = {
          assetId: intercardTransaction?.assetId,
          lastApprovedCollectionDate: finalAssetLastCollectionDate,
          clicksPerPlay: asset?.clicksPerPlay,
          costPerPlay: asset?.costPerPlay,
          numberOfPlays: asset?.numberOfPlays,
          data: [],
          intercardData: getIntercardDataFromData(intercardTransaction),
        };
      }
    });

    if (checkValueNotNullUndefinedBlank(playCards) && checkValueNotNullUndefinedBlank(playCards[subId])) {
      const submitPlaycard = getPlaycardSubmissionData(playCards, subId);
      return {
        sublocationId: subId,
        ...dates,
        ...sublocationDatesForSubmit,
        assets: assetsData,
        ...submitPlaycard,
      };
    }
    if (assetsData && assetsData.length > 0) {
      return {
        sublocationId: subId,
        ...dates,
        ...sublocationDatesForSubmit,
        assets: assetsData,
      };
    }
    return false;
  });
  const filteredTransformedData = finalTransformedData?.filter((el) => el !== false);
  return filteredTransformedData;
};

/**
 * Transform data for summary
 *
 * @param {Array} sublocationIds
 * @param {Array} transformedDataByAsset
 * @returns
 */
export const getTransformedDataForSummary = (sublocationIds, transformedDataByAsset) => {
  const finalTransformedData = sublocationIds.map((subId) => {
    const assetsData = transformedDataByAsset
      .map((el) => {
        // remove sublocationId
        if (el.sublocationId === subId) {
          // delete el.sublocationId;
          // eslint-disable-next-line
          return (({ sublocationId, ...rest }) => rest)(el);
        }
      })
      .filter((el) => el !== undefined);
    if (assetsData && assetsData.length > 0) {
      return { sublocationId: subId, assets: assetsData };
    }
    return false;
  });
  return finalTransformedData?.filter((el) => el !== false);
};

/**
 * Construct data.sublocations API data for collection submission that only has intercard and playcard data
 *
 * @param {Array} playCards
 * @param {Array} intercardDataForSubmission
 * @param {object} sublocationDates
 * @returns {Array} Array of objects that contain intercard and playcard in API requested format
 */
export const getPlaycardForSubmission = (playCards, intercardDataForSubmission, sublocationDates, location) => {
  let playcardForSubmission = [];
  for (const subId in playCards) {
    const initialDatesForSublocation = getInitialDatesForSublocation();
    const sublocationDatesForSubmit = checkValueNotNullUndefinedBlank(sublocationDates)
      ? sublocationDates[subId] || { ...initialDatesForSublocation }
      : { ...initialDatesForSublocation };
    const assetsData =
      intercardDataForSubmission?.length > 0
        ? intercardDataForSubmission
            ?.filter((intercardData) => String(intercardData.sublocationId) === String(subId))
            ?.flatMap((data) => data.assets)
        : [];
    //add sublocation last collection date
    const subloc = location?.sublocations?.filter((subloc) => String(subloc?.id) === String(subId))[0];
    const dates = getSublocationDates(subloc);

    const submitPlaycard = getPlaycardSubmissionData(playCards, subId);
    playcardForSubmission.push({
      sublocationId: subId,
      assets: assetsData,
      ...dates,
      ...sublocationDatesForSubmit,
      ...submitPlaycard,
    });
  }
  return playcardForSubmission;
};

export const getApprovedSubmissionData = (
  sublocations,
  transformedDataByAsset,
  playCards,
  accountSummaryData,
  sublocationDatesForReconciliation,
  intercardDataForReconciliation,
) => {
  const data = [...transformedDataByAsset];
  const sublocationIds = sublocations.map((sub) => sub.id);
  const finalTransformedData = sublocationIds.map((subId) => {
    const intercardTransactionBySubId = Object.values(intercardDataForReconciliation).filter(
      (data) => String(data.sublocationId) === String(subId),
    );
    const subloc = sublocations?.filter((subloc) => String(subloc?.id) === String(subId))[0];
    const sublocLastCollectionDate = getLastCollectionDate(subloc, true);
    const formattedSublocDate = checkValueNotNullUndefinedBlank(sublocLastCollectionDate)
      ? dateFormatForApi(sublocLastCollectionDate)
      : null;
    const dates = getSublocationDates(subloc);
    const initialDatesForSublocation = getInitialDatesForSublocation();
    const sublocationDatesForSubmit = checkValueNotNullUndefinedBlank(sublocationDatesForReconciliation)
      ? sublocationDatesForReconciliation[subId] || { ...initialDatesForSublocation }
      : { ...initialDatesForSublocation };
    const assetsData = data
      .map((el) => {
        if (el.sublocationId === subId) {
          // remove el.sublocationId and replace meter Name from transformedDataByAsset
          const assetMeters = el?.data?.map((assetMeter) => {
            let result = ((rest) => rest)(assetMeter);
            switch (result.meterName) {
              case METER_TYPE.CASH_RECONCILIATION: {
                result = { ...result, meterName: METER_TYPE.CASH };
                break;
              }
              case METER_TYPE.COIN_RECONCILIATION: {
                result = { ...result, meterName: METER_TYPE.COIN };
                break;
              }
              case METER_TYPE.CREDIT_RECONCILIATION: {
                result = { ...result, meterName: METER_TYPE.CREDIT };
                break;
              }
              case METER_TYPE.TOKEN_RECONCILIATION: {
                result = { ...result, meterName: METER_TYPE.TOKEN };
                break;
              }
              case METER_TYPE.TOKEN_CHANGER_RECONCILIATION: {
                result = { ...result, meterName: METER_TYPE.TOKEN_CHANGER };
                break;
              }
              // no default really required here
            }
            return result;
          });
          let approvedData = {
            assetId: el.assetId,
            data: transfromDataFromStringToNumber(assetMeters),
            clicksPerPlay: el?.clicksPerPlay,
            costPerPlay: el?.clicksPerPlay,
            numberOfPlays: el?.numberOfPlays,
            lastApprovedCollectionDate: el?.lastApprovedCollectionDate,
          };
          if (el?.comments) {
            approvedData.comments = el?.comments;
          }
          if (Object.values(intercardDataForReconciliation).length > 0 && intercardDataForReconciliation[el.assetId]) {
            approvedData.intercardData = getIntercardDataFromDataForApproveCollection(
              intercardDataForReconciliation[el.assetId],
            );
          }
          return approvedData;
        }
      })
      .filter((el) => el !== undefined);

    const assetDataIds = assetsData?.map((data) => data?.assetId);
    let index = assetsData.length;
    Object.values(intercardTransactionBySubId).map((intercardTransaction) => {
      if (!assetDataIds.includes(intercardTransaction?.assetId)) {
        const asset = subloc?.assets?.filter((asset) => String(asset?.id) === String(intercardTransaction?.assetId))[0];
        const assetLastCollectionDate = asset?.lastCollectionByAsset?.dateOfReading;
        const finalAssetLastCollectionDate = !assetLastCollectionDate
          ? formattedSublocDate || null
          : dateFormatForApi(assetLastCollectionDate);
        assetsData[index++] = {
          assetId: intercardTransaction?.assetId,
          lastApprovedCollectionDate: finalAssetLastCollectionDate,
          clicksPerPlay: asset?.clicksPerPlay,
          costPerPlay: asset?.costPerPlay,
          numberOfPlays: asset?.numberOfPlays,
          data: [],
          intercardData: getIntercardDataFromDataForApproveCollection(intercardTransaction),
        };
      }
    });
    let SubmitAccountSummary = [];
    if (
      checkValueNotNullUndefinedBlank(accountSummaryData) &&
      checkValueNotNullUndefinedBlank(accountSummaryData[subId])
    ) {
      const accountSummaryBySubId = accountSummaryData[subId];
      const accountNumbersToSend = accountSummaryBySubId?.filter(
        (item) => item.accountNumber !== 'None' && checkValueNotNullUndefinedBlank(item.accountNumber),
      );
      SubmitAccountSummary = accountNumbersToSend.map((item) => ({
        accountNumberId: Number(item?.accountNumber?.value),
        description: item?.description,
        debit: Number(item?.debit),
        credit: Number(item?.credit),
        sublocationId: subId,
      }));
    }
    let submitPlaycard = {};
    if (checkValueNotNullUndefinedBlank(playCards) && checkValueNotNullUndefinedBlank(playCards[subId])) {
      const {
        cashPlaycardSales,
        cashRefunds,
        salesFromCreditCard,
        creditCardRefunds,
        complimentaryPlaycards,
        bulkEncodedPlaycards,
        adjustments,
      } = playCards[subId];
      submitPlaycard = {
        playcardCashSales: checkNumberIsNaNAndInfinity(cashPlaycardSales),
        playcardCashRefunds: checkNumberIsNaNAndInfinity(cashRefunds),
        playcardCCSales: checkNumberIsNaNAndInfinity(salesFromCreditCard),
        playcardCCRefunds: checkNumberIsNaNAndInfinity(creditCardRefunds),
        playcardBulkEncoded: checkNumberIsNaNAndInfinity(bulkEncodedPlaycards),
        playcardComps: checkNumberIsNaNAndInfinity(complimentaryPlaycards),
        playcardAdjustments: checkNumberIsNaNAndInfinity(adjustments),
      };
      const accountSummaryToSubmit = SubmitAccountSummary?.length > 0 ? SubmitAccountSummary : [];
      const independentRepData = {};
      data
        .filter((item) => item.sublocationId === subId)
        .forEach((el) => {
          independentRepData[INDEPENDENT_REP_OF_SUBLOCATION.REP_ID] = el[INDEPENDENT_REP_OF_SUBLOCATION.REP_ID];
          independentRepData[INDEPENDENT_REP_OF_SUBLOCATION.PAY_CODE] = el[INDEPENDENT_REP_OF_SUBLOCATION.PAY_CODE];
          independentRepData[INDEPENDENT_REP_OF_SUBLOCATION.FEE] =
            el[INDEPENDENT_REP_OF_SUBLOCATION.FEE] === '' ? null : el[INDEPENDENT_REP_OF_SUBLOCATION.FEE];
          independentRepData[INDEPENDENT_REP_OF_SUBLOCATION.RATE] =
            el[INDEPENDENT_REP_OF_SUBLOCATION.RATE] === '' ? null : el[INDEPENDENT_REP_OF_SUBLOCATION.RATE];
        });
      return {
        sublocationId: subId,
        ...dates,
        ...sublocationDatesForSubmit,
        ...independentRepData,
        assets: assetsData,
        ...submitPlaycard,
        accountSummary: [...accountSummaryToSubmit],
      };
    }
    if (SubmitAccountSummary.length > 0 && assetsData && assetsData.length > 0) {
      return {
        sublocationId: subId,
        ...dates,
        ...sublocationDatesForSubmit,
        assets: assetsData,
        accountSummary: [...SubmitAccountSummary],
      };
    }
    if (assetsData && assetsData.length > 0) {
      return { sublocationId: subId, ...sublocationDatesForSubmit, assets: assetsData };
    }
    return false;
  });
  return finalTransformedData.filter((el) => el !== false);
};

export const getCollectionCollected = (transformedSublocations) => {
  const sublocationIds = transformedSublocations?.map((el) => el?.sublocationId);
  const noDuplication = new Set(sublocationIds);
  return noDuplication.size;
};

export const getReportMachines = (values) =>
  getRawDataFromFieldValues(values).filter(
    (el) => el.report !== undefined && el.report !== null && Object.keys(el.report).length !== 0 && el?.report?.issue,
  );

export const getReportMachineSortByAssetId = (sublocationId, reportMachines) => {
  const filtered = reportMachines.filter((machine) => machine.sublocationId === String(sublocationId));
  const assetIds = filtered.map((el) => el.assetId);
  return assetIds
    .map((id) => {
      const sortedReportMachines = filtered.filter((el) => el.assetId === String(id));
      if (sortedReportMachines.length > 0) {
        return { assetId: id, reportMachines: sortedReportMachines };
      }
    })
    .filter((el) => el !== false);
};

export const getTotalTicketDispensed = (totalTicketDispensed, key, value) => {
  if (key.includes(FIELD_NAME.TICKET_DISPENSED) && key.includes(METER_TYPE.TICKET) && value) {
    totalTicketDispensed += Number(value);
  }
  return totalTicketDispensed;
};

export const getTotalTokenDispensed = (totalTokenDispensed, key, value) => {
  if (key.includes(FIELD_NAME.TOKEN_DISPENSED) && key.includes(METER_TYPE.TOKEN_CHANGER) && value) {
    totalTokenDispensed += Number(value);
  }
  return totalTokenDispensed;
};

export const getTotalPrizeDispensed = (totalPrizeDispensed, key, value) => {
  if (key.includes(FIELD_NAME.PRIZE_DISPENSED) && key.includes(METER_TYPE.PRIZE) && value) {
    totalPrizeDispensed += Number(value);
  }
  return totalPrizeDispensed;
};

export const getTotalMedallionDispensed = (totalMedallionDispensed, key, value) => {
  if (key.includes(FIELD_NAME.MEDALLION_DISPENSED) && key.includes(METER_TYPE.MEDALLION) && value) {
    totalMedallionDispensed += Number(value);
  }
  return totalMedallionDispensed;
};

export const getTotalTokenCollected = (totalTokenCollected, key, value) => {
  if (key.includes(FIELD_NAME.TOKEN_COLLECTED) && key.includes(METER_TYPE.TOKEN) && value) {
    totalTokenCollected += Number(value);
  }
  return totalTokenCollected;
};

export const getTotalTokenRevenueCollected = (totalTokenChangerRevenueCollected, key, form, exchangeRate) => {
  if (key.includes(METER_TYPE.TOKEN_CHANGER) && form[key] === TOKEN_CHANGER_PAYMENT_TYPE.CREDIT) {
    const generateSeedLiveKeyPaymentType = key.replace(
      FIELD_NAME.PAYMENT_TYPE_FOR_TOKEN_CHANGER,
      FIELD_NAME.SEEDLIVE_REVENUE,
    );
    const generateTestVendsKey = key.replace(FIELD_NAME.PAYMENT_TYPE_FOR_TOKEN_CHANGER, FIELD_NAME.TEST_VENDS);
    const seedLiveRevenueInUsd = Number(
      Number(form[generateSeedLiveKeyPaymentType]) - Number(form[generateTestVendsKey]),
    );
    const seedLiveRevenueInLocalCurrency = getRevenueInLocalCurrency(seedLiveRevenueInUsd, exchangeRate);
    totalTokenChangerRevenueCollected += Number(seedLiveRevenueInLocalCurrency);
  }
  if (key.includes(METER_TYPE.TOKEN_CHANGER) && form[key] === TOKEN_CHANGER_PAYMENT_TYPE.CASH) {
    const generateRevenueInUsdKeyFromPaymentType = key.replace(
      FIELD_NAME.PAYMENT_TYPE_FOR_TOKEN_CHANGER,
      FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER,
    );
    const tokenChangerRevenueInLocal = getRevenueInLocalCurrency(form[generateRevenueInUsdKeyFromPaymentType]);
    totalTokenChangerRevenueCollected += Number(tokenChangerRevenueInLocal);
  }

  return totalTokenChangerRevenueCollected;
};

export const getTokenChangerRevenueForApproveDetails = (
  totalTokenRevenueCollected,
  currentReading,
  priorReading,
  assetMeter,
  exchangeRate,
) => {
  const { revenueCollected } = getTokenChangerRevenueAndDispensedValue(currentReading, priorReading, assetMeter);

  if (assetMeter[FIELD_NAME.PAYMENT_TYPE_FOR_TOKEN_CHANGER] === TOKEN_CHANGER_PAYMENT_TYPE.CASH) {
    const deductTestVeds = Number(revenueCollected) - Number(assetMeter?.testVends);
    totalTokenRevenueCollected += getRevenueInUSD(deductTestVeds, exchangeRate) ?? 0;
  }
  if (assetMeter[FIELD_NAME.PAYMENT_TYPE_FOR_TOKEN_CHANGER] === TOKEN_CHANGER_PAYMENT_TYPE.CREDIT) {
    totalTokenRevenueCollected += Number(assetMeter[FIELD_NAME.SEEDLIVE_REVENUE]) - Number(assetMeter?.testVends);
  }
  return totalTokenRevenueCollected;
};

export const getTotalCollected = (total, totalInUSD, key, value, exchangeRate) => {
  // Total only includes: cash meter - coins and bills collected, coin meter - coins collected, candy meter - coins collected
  if (key.includes(FIELD_NAME.COINS_COLLECTED) && key.includes(METER_TYPE.COIN) && value) {
    total += Number(value);
    totalInUSD += getRevenueInUSD(value, exchangeRate);
  }

  if (
    key.includes(METER_TYPE.CASH) &&
    value &&
    (key.includes(FIELD_NAME.COINS_COLLECTED) || key.includes(FIELD_NAME.BILLS_COLLECTED))
  ) {
    total += Number(value);
    totalInUSD += getRevenueInUSD(value, exchangeRate);
  }

  if (key.includes(FIELD_NAME.COINS_COLLECTED) && key.includes(METER_TYPE.CANDY) && value) {
    total += Number(value);
    totalInUSD += getRevenueInUSD(value, exchangeRate);
  }

  if (key.includes(FIELD_NAME.GAME_REVENUE) && key.includes(METER_TYPE.IMPULSE_READER) && value) {
    total += Number(value);
    totalInUSD += getRevenueInUSD(value, exchangeRate);
  }
  return { total, totalInUSD };
};

export const sortAssetMeters = (originalData) => {
  if (originalData?.length > 0) {
    originalData.sort((assetMeterA, assetMeterB) => {
      const order = [TOKEN_CHANGER_PAYMENT_TYPE.CASH, TOKEN_CHANGER_PAYMENT_TYPE.CREDIT];

      const meterNameOrder =
        SORT_ORDER.indexOf(assetMeterA?.meter?.name) - SORT_ORDER.indexOf(assetMeterB?.meter?.name);
      if (meterNameOrder !== 0) return meterNameOrder;
      if (
        assetMeterA?.meter?.name === METER_TYPE.TOKEN_CHANGER &&
        assetMeterB?.meter?.name === METER_TYPE.TOKEN_CHANGER
      ) {
        if (assetMeterA?.machineAcceptedPaymentType === assetMeterB?.machineAcceptedPaymentType) {
          return assetMeterA?.denomination - assetMeterB?.denomination;
        } else {
          return (
            order.indexOf(assetMeterA?.machineAcceptedPaymentType) -
            order.indexOf(assetMeterB?.machineAcceptedPaymentType)
          );
        }
      }
      if (assetMeterA?.meter?.name === METER_TYPE.MEDALLION && assetMeterB?.meter?.name === METER_TYPE.MEDALLION) {
        if (!assetMeterA?.identifier || !assetMeterB?.identifier) {
          return !assetMeterA?.identifier && !assetMeterB?.identifier
            ? assetMeterA?.displayName.localeCompare(assetMeterB?.displayName, undefined, { sensitivity: 'base' })
            : assetMeterA?.identifier
            ? 1
            : -1;
        }

        const idA = parseInt(assetMeterA?.identifier, 10);
        const idB = parseInt(assetMeterB?.identifier, 10);

        return !isNaN(idA) && !isNaN(idB)
          ? idA - idB
          : !isNaN(idA)
          ? -1
          : !isNaN(idB)
          ? 1
          : assetMeterA?.identifier.localeCompare(assetMeterB?.identifier, undefined, { sensitivity: 'base' });
      }
      return 0;
    });
  }
  return originalData;
};

export const getIntercardFieldName = (assetId, assetSubTag, sublocationId, fieldName, group) => {
  const groupName = group?.replace(/ /g, '');
  if (!isValueValid(assetId) && !isValueValid(sublocationId) && !isValueValid(fieldName)) {
    return null;
  }
  if (isValueValid(assetSubTag) && isValueValid(groupName)) {
    return `${METER_TYPE.INTERCARD}_${fieldName}_${sublocationId}_${assetId}_${assetSubTag}_${groupName}`;
  }
  if (isValueValid(assetSubTag) && !isValueValid(groupName)) {
    return `${METER_TYPE.INTERCARD}_${fieldName}_${sublocationId}_${assetId}_${assetSubTag}`;
  }
  if (!isValueValid(assetSubTag) && isValueValid(groupName)) {
    return `${METER_TYPE.INTERCARD}_${fieldName}_${sublocationId}_${assetId}_${groupName}`;
  }
  return `${METER_TYPE.INTERCARD}_${fieldName}_${sublocationId}_${assetId}`;
};

/**
 * This method update fields: {intercard_fieldName_sublocationId_assetId_assetSubTag: value}
 *
 * @param {object} transaction : {id, cashDebits, standardPlay, assetSubTag, points}
 * @param {object} asset
 * @param {object} fields
 */
export const getIntercardFieldNameFunc = (transaction, asset, fields) => {
  const cashDebitsFieldName = getIntercardFieldName(
    asset?.id,
    transaction?.assetSubTag,
    asset?.subLocationId,
    FIELD_NAME.CASH_DEBITS,
    transaction?.group,
  );
  fields[cashDebitsFieldName] = roundOffValue(transaction?.cashDebits);
  const standardPlayFieldName = getIntercardFieldName(
    asset?.id,
    transaction?.assetSubTag,
    asset?.subLocationId,
    FIELD_NAME.STANDARD_PLAY,
    transaction?.group,
  );
  fields[standardPlayFieldName] = roundOffValue(transaction?.standardPlay);
  const pointsFieldName = getIntercardFieldName(
    asset?.id,
    transaction?.assetSubTag,
    asset?.subLocationId,
    FIELD_NAME.POINTS,
    transaction?.group,
  );
  fields[pointsFieldName] = roundOffValue(transaction?.points);
  const groupFieldName = getIntercardFieldName(
    asset?.id,
    transaction?.assetSubTag,
    asset?.subLocationId,
    FIELD_NAME.GROUP,
    transaction?.group,
  );
  fields[groupFieldName] = transaction[FIELD_NAME.GROUP];
};

/**
 *
 * @param {object OR Array} intercardTransactions
 * @param {object} asset
 * @returns {object} fields
 */
export const getInitialIntercardState = (intercardTransactions, asset) => {
  let fields = {};
  if (Array.isArray(intercardTransactions)) {
    intercardTransactions?.map((transaction) => {
      getIntercardFieldNameFunc(transaction, asset, fields);
    });
  } else {
    getIntercardFieldNameFunc(intercardTransactions, asset, fields);
  }
  return fields;
};

/**
 * This will calculate in local currency and return calculated revenue for cash type meters
 *
 * @param {number} currentReading
 * @param {number} priorReading
 * @param {number} clicksPerPlay in local currency
 * @param {number} costPerPlay local currency
 * @returns
 */
export const getExpectedCashRevenue = (currentReading, priorReading, clicksPerPlay, costPerPlay) => {
  if (
    isValueValid(clicksPerPlay) &&
    isValueValid(costPerPlay) &&
    isValueValid(currentReading) &&
    isValueValid(priorReading)
  ) {
    const result = checkNumberIsNaNAndInfinity(
      ((Number(currentReading) - Number(priorReading)) / Number(clicksPerPlay)) * Number(costPerPlay),
    );
    return roundOffValue(result);
  }
  return 0;
};

export const getExpectedCashOrCoinRevenueByFieldName = (
  name,
  currentReading,
  clicksPerPlay,
  costPerPlay,
  values,
  exchangeRate,
) => {
  const [meterName, field, sublocationId, assetId, meterId, assetMeterId] = name.split('_');
  let fieldName;
  if (meterName === METER_TYPE.CASH || meterName === METER_TYPE.CREDIT) {
    fieldName = FIELD_NAME.EXPECTED_REVENUE;
  } else {
    fieldName = FIELD_NAME.ESTIMATED_COIN_REVENUE;
  }
  const priorReadingFieldName = getFieldName(
    meterName,
    FIELD_NAME.PRIOR_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const currentReadingFieldName = getFieldName(
    meterName,
    FIELD_NAME.CURRENT_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const priorReading = field === FIELD_NAME.PRIOR_READING ? currentReading : values[priorReadingFieldName] ?? 0;
  const currentReadingVal =
    field === FIELD_NAME.CURRENT_READING ? currentReading : values[currentReadingFieldName] ?? 0;

  let expectedRevenue = getExpectedCashRevenue(currentReadingVal, priorReading, clicksPerPlay, costPerPlay);
  if (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE) {
    expectedRevenue = getRevenueInUSD(expectedRevenue, exchangeRate);
  }
  const expectedRevenueFieldName = getFieldName(meterName, fieldName, sublocationId, assetId, meterId, assetMeterId);

  return {
    expectedRevenueFieldName: expectedRevenueFieldName,
    expectedRevenue: Number(expectedRevenue),
  };
};

export const getTokenChangerRevenueAndDispensedValue = (currentReading, priorReading, assetMeter) => {
  if (currentReading === '') {
    return { tokenDispensed: null, revenueCollected: null };
  }
  const isCashPayment = assetMeter.machineAcceptedPaymentType === TOKEN_CHANGER_PAYMENT_TYPE.CASH;
  const denomination = assetMeter.denomination || 1;
  const tokenPayout = assetMeter.tokenPayout || 1;
  if (isValueValid(currentReading) && isValueValid(priorReading)) {
    const difference = checkNumberIsNaNAndInfinity(Number(currentReading) - Number(priorReading));
    const tokenDispensed = isCashPayment
      ? Math.round(difference * tokenPayout)
      : Math.round((difference / denomination) * tokenPayout);
    const revenueCollected = isCashPayment ? roundOffValue(difference * denomination) : difference;
    return { tokenDispensed, revenueCollected };
  }
  return { tokenDispensed: 0, revenueCollected: 0 };
};

export const getTokenChangerRevenueAndDispensedValueFromFieldName = (name, currentReadingValue, assetMeter, values) => {
  const [meterName, field, sublocationId, assetId, meterId, assetMeterId] = name.split('_');
  const priorReadingFieldName = getFieldName(
    METER_TYPE.TOKEN_CHANGER,
    FIELD_NAME.PRIOR_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const tokenDispensedFieldName = getFieldName(
    METER_TYPE.TOKEN_CHANGER,
    FIELD_NAME.TOKEN_DISPENSED,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const revenueCollectedFieldName = getFieldName(
    METER_TYPE.TOKEN_CHANGER,
    FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const revenueInUSDFieldName = getFieldName(
    METER_TYPE.TOKEN_CHANGER,
    FIELD_NAME.REVENUE_IN_USD,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const currentReadingFieldName = getFieldName(
    meterName,
    FIELD_NAME.CURRENT_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );

  if (currentReadingValue === '') {
    return {
      tokenDispensedFieldName: tokenDispensedFieldName,
      tokenDispensed: null,
      revenueCollectedFieldName: revenueCollectedFieldName,
      revenueInUSDFieldName: revenueInUSDFieldName,
      revenueCollected: null,
    };
  }

  const priorReading = field === FIELD_NAME.PRIOR_READING ? currentReadingValue : values[priorReadingFieldName] ?? 0;
  const currentReading =
    field === FIELD_NAME.CURRENT_READING ? currentReadingValue : values[currentReadingFieldName] ?? 0;

  const { tokenDispensed, revenueCollected } = getTokenChangerRevenueAndDispensedValue(
    currentReading,
    priorReading,
    assetMeter,
  );
  return {
    tokenDispensedFieldName: tokenDispensedFieldName,
    tokenDispensed: tokenDispensed,
    revenueCollectedFieldName: revenueCollectedFieldName,
    revenueInUSDFieldName: revenueInUSDFieldName,
    revenueCollected: revenueCollected,
  };
};

export const getVarianceValue = (name, fieldName, val, values, exchangeRate, costPerPlay, clicksPerPlay) => {
  const [meterName, , sublocationId, assetId, meterId, assetMeterId] = name.split('_');
  const varianceFieldName = getFieldName(meterName, FIELD_NAME.VARIANCE, sublocationId, assetId, meterId, assetMeterId);
  const billsCollectedFieldName = getFieldName(
    meterName,
    FIELD_NAME.BILLS_COLLECTED,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const coinsCollectedFieldName = getFieldName(
    meterName,
    FIELD_NAME.COINS_COLLECTED,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const adjustFieldName = getFieldName(
    meterName,
    FIELD_NAME.METER_ADJUST,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );

  const currentReadingFieldName = getFieldName(
    meterName,
    FIELD_NAME.CURRENT_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const priorReadingFieldName = getFieldName(
    meterName,
    FIELD_NAME.PRIOR_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const billsCollected = fieldName === FIELD_NAME.BILLS_COLLECTED ? val : values[billsCollectedFieldName];
  const coinsCollected = fieldName === FIELD_NAME.COINS_COLLECTED ? val : values[coinsCollectedFieldName];
  const adjust = fieldName === FIELD_NAME.METER_ADJUST ? val : values[adjustFieldName];

  const currentReading = fieldName === FIELD_NAME.CURRENT_READING ? val : values[currentReadingFieldName];
  const priorReading = fieldName === FIELD_NAME.PRIOR_READING ? val : values[priorReadingFieldName];
  let expectedRevenue = getExpectedCashRevenue(currentReading, priorReading, clicksPerPlay, costPerPlay);
  let cashRevenue = getCashRevenue(billsCollected, coinsCollected, adjust);
  if (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE) {
    cashRevenue = getRevenueInUSD(cashRevenue, exchangeRate);
    expectedRevenue = getRevenueInUSD(expectedRevenue, exchangeRate);
  }
  const variance = roundOffValue(getSum(Number(cashRevenue), -Number(expectedRevenue)), 2);

  return {
    varianceFieldName,
    variance,
  };
};

export const getDispensedValue = (currentReading, priorReading) => {
  if (isValueValid(currentReading) && isValueValid(priorReading) && currentReading !== '') {
    return checkNumberIsNaNAndInfinity(
      checkNumberIsNaNAndInfinity(currentReading) - checkNumberIsNaNAndInfinity(priorReading),
    );
  }
  return 0;
};

// The adjust is an optional value
export const getCashRevenue = (billsCollected, coinsCollected, adjustment) => {
  let result = 0;
  if (isValueValid(billsCollected) && isValueValid(coinsCollected)) {
    result += checkNumberIsNaNAndInfinity(Number(billsCollected) + Number(coinsCollected));
  }
  if (isValueValid(adjustment)) {
    result += checkNumberIsNaNAndInfinity(Number(adjustment));
  }
  return result;
};

// The adjust is an optional value
/**
 *
 * @param {number} creditCardRevenue  This is by default in USD
 * @param {number} adjustment
 * @returns
 */
export const getCreditRevenue = (creditCardRevenue, adjustment) => {
  let result = 0;
  if (isValueValid(creditCardRevenue)) {
    result += checkNumberIsNaNAndInfinity(creditCardRevenue);
  }
  if (isValueValid(adjustment)) {
    result += checkNumberIsNaNAndInfinity(adjustment);
  }
  return result;
};

export const getTokenRevenue = (averageTokenValue, tokenCollected) => {
  if (isValueValid(averageTokenValue) && isValueValid(tokenCollected)) {
    const result = checkNumberIsNaNAndInfinity(averageTokenValue) * checkNumberIsNaNAndInfinity(tokenCollected);
    return roundOffValue(result);
  }
  return 0;
};

export const getTokenRevenueAndFieldNameByName = (
  name,
  values,
  averageTokenValueInUSD,
  exchangeRate = DEFAULT_EXCHANGE_RATE,
) => {
  // eslint-disable-next-line
  const [meterName, field, sublocationId, assetId, meterId, assetMeterId] = name.split('_');
  const tokenCollectedFieldName = getFieldName(
    METER_TYPE.TOKEN,
    FIELD_NAME.TOKEN_COLLECTED,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const tokenRevenueFieldName = getFieldName(
    METER_TYPE.TOKEN,
    FIELD_NAME.TOKEN_REVENUE,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const tokenRevenueInUSDFieldName = getFieldName(
    METER_TYPE.TOKEN,
    FIELD_NAME.REVENUE_IN_USD,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const tokenCollected = values[tokenCollectedFieldName] ?? 0;
  const tokenRevenueInUSD = getTokenRevenue(averageTokenValueInUSD, tokenCollected);
  const tokenRevenueInLocalCurrency = getRevenueInLocalCurrency(tokenRevenueInUSD, exchangeRate);
  return {
    tokenRevenueInUSDFieldName: tokenRevenueInUSDFieldName,
    tokenRevenueFieldName: tokenRevenueFieldName,
    tokenRevenueInUSD: Number(tokenRevenueInUSD ?? 0),
    tokenRevenueInLocalCurrency: Number(tokenRevenueInLocalCurrency ?? 0),
  };
};

export const getSummaryContent = (subTotalRevenues, sublocationId, assetId, locationCurrency, playCards = {}) => {
  let totalSubtotalCashRevenue = 0,
    totalSubtotalRevenue = 0,
    totalSubtotalCreditRevenue = 0,
    totalSubtotalCashRevenueInUSD = 0,
    totalSubtotalCreditRevenueInUSD = 0,
    totalSubtotalRevenueInUSD = 0,
    variance = 0,
    totalCashCollectedRevenue = 0,
    totalCashCollectedRevenueInUSD = 0;
  const abbreviation = locationCurrency?.abbreviation || CURRENCY_CONSTANT.USD;
  const exchangeRate = locationCurrency?.exchangeRate || DEFAULT_EXCHANGE_RATE;

  if ((!sublocationId && !assetId) || !isArrayWithLength(subTotalRevenues)) {
    return [];
  }
  subTotalRevenues?.map((data) => {
    // For Asset/Machine Level
    const subtotalCashRevenue = data?.subtotalCashRevenue || 0;
    const subtotalCashRevenueInUSD = data?.subtotalCashRevenueInUSD || 0;
    const subtotalCreditRevenue = data?.subtotalCreditRevenue || 0;
    const subtotalCreditRevenueInUSD = data?.subtotalCreditRevenueInUSD || 0;
    const subtotalRevenue = data?.subtotalRevenue || 0;
    const subtotalRevenueInUSD = data?.subtotalRevenueInUSD || 0;
    const assetVariance = data?.variance || 0;
    const subtotalCashCollectedRevenue = data?.subtotalCashCollectedRevenue || 0;
    const subtotalCashCollectedRevenueInUSD = data?.subtotalCashCollectedRevenueInUSD || 0;
    if (
      sublocationId &&
      assetId &&
      String(data?.sublocationId) === String(sublocationId) &&
      String(data?.assetId) === String(assetId)
    ) {
      totalSubtotalCashRevenue += subtotalCashRevenue;
      totalSubtotalCashRevenueInUSD += subtotalCashRevenueInUSD;
      totalSubtotalCreditRevenue += subtotalCreditRevenue;
      totalSubtotalCreditRevenueInUSD += subtotalCreditRevenueInUSD;
      totalSubtotalRevenue += subtotalRevenue;
      totalSubtotalRevenueInUSD += subtotalRevenueInUSD;
      variance += assetVariance;
      totalCashCollectedRevenue += subtotalCashCollectedRevenue;
      totalCashCollectedRevenueInUSD += subtotalCashCollectedRevenueInUSD;
    }
    // For Sublocation Level
    if (sublocationId && !assetId && String(data?.sublocationId) === String(sublocationId)) {
      totalSubtotalCashRevenue += subtotalCashRevenue;
      totalSubtotalCashRevenueInUSD += subtotalCashRevenueInUSD;
      totalSubtotalCreditRevenue += subtotalCreditRevenue;
      totalSubtotalCreditRevenueInUSD += subtotalCreditRevenueInUSD;
      totalSubtotalRevenue += subtotalRevenue;
      totalSubtotalRevenueInUSD += subtotalRevenueInUSD;
      variance += assetVariance;
      totalCashCollectedRevenue += subtotalCashCollectedRevenue;
      totalCashCollectedRevenueInUSD += subtotalCashCollectedRevenueInUSD;
    }
  });
  // USD revenue from playcard
  const netCreditCardTotalInUSD = playCards[sublocationId]?.netCreditCardTotal || 0;
  const netCashTotalInUSD = playCards[sublocationId]?.netCashTotal || 0;

  const netCashTotalInLocalCurrency = netCashTotalInUSD
    ? getRevenueInLocalCurrency(netCashTotalInUSD, exchangeRate)
    : 0;
  const netCreditCardTotalInLocalCurrency = netCreditCardTotalInUSD
    ? getRevenueInLocalCurrency(netCreditCardTotalInUSD, exchangeRate)
    : 0;
  const subtotals = {
    subtotalCashRevenue: totalSubtotalCashRevenue + netCashTotalInLocalCurrency,
    subtotalCreditRevenue: totalSubtotalCreditRevenue + netCreditCardTotalInLocalCurrency,
    subtotalCashCollectedRevenue: totalCashCollectedRevenue,
    subtotalRevenue: totalSubtotalRevenue + netCashTotalInLocalCurrency + netCreditCardTotalInLocalCurrency,
    variance: variance,
  };
  if (abbreviation !== CURRENCY_CONSTANT.USD) {
    subtotals['subtotalCashRevenueInUSD'] = totalSubtotalCashRevenueInUSD + netCashTotalInUSD;
    subtotals['subtotalCreditRevenueInUSD'] = totalSubtotalCreditRevenueInUSD + netCreditCardTotalInUSD;
    subtotals['subtotalRevenueInUSD'] = totalSubtotalRevenueInUSD + netCashTotalInUSD + netCreditCardTotalInUSD;
    subtotals['subtotalRevenueLocalCurrency'] =
      totalSubtotalRevenue + netCreditCardTotalInLocalCurrency + netCashTotalInLocalCurrency;
    subtotals['subtotalCashCollectedRevenueInUSD'] = totalCashCollectedRevenueInUSD;
  } else {
    subtotals['subtotalRevenueInUSD'] = subtotals.subtotalRevenue;
    subtotals['subtotalCashRevenueInUSD'] = subtotals.subtotalCashRevenue;
    subtotals['subtotalCreditRevenueInUSD'] = subtotals.subtotalCreditRevenue;
    subtotals['subtotalCashCollectedRevenueInUSD'] = subtotals.subtotalCashCollectedRevenue;
  }
  return getSummaryArray(subtotals, abbreviation);
};

/**
 *
 * @param {Number} assetId
 * @param {Array of objects} assetData
 * @param {Number} averageTokenValueInUSD
 * @param {Number} exchangeRate
 * @returns {object} summary
 */
export const getSubTotalsPerAsset = (assetId, assetData, exchangeRate = DEFAULT_EXCHANGE_RATE) => {
  if (isArrayWithLength(assetData)) {
    let subtotalCashRevenue = 0; // include cash expected, coin, token, candy
    let subtotalCreditRevenue = 0;
    let subtotalRevenue = 0;
    let tokenChangerCashRevenue = 0;
    let tokenChangerCreditRevenue = 0;
    let subtotalCashCollectedRevenue = 0; // include only cash collected
    // revenue in USD
    let subtotalCashRevenueInUSD = 0; // include cash, coin, token, candy
    let subtotalCreditRevenueInUSD = 0;
    let subtotalRevenueInUSD = 0;
    let variance = 0;
    let subtotalCashCollectedRevenueInUSD = 0; //include only cash collected
    assetData?.map((data) => {
      if (data.meterName === METER_TYPE.CREDIT_RECONCILIATION) {
        const adjustInLocalCurrency = data[FIELD_NAME.METER_ADJUST];
        const seedLiveRevenueInLocalCurrency = getRevenueInLocalCurrency(
          data[FIELD_NAME.SEEDLIVE_REVENUE],
          exchangeRate,
        );
        const adjustInUSD = getRevenueInUSD(adjustInLocalCurrency, exchangeRate);
        const sumInUSD = getCreditRevenue(data[FIELD_NAME.SEEDLIVE_REVENUE], adjustInUSD);
        const sumInLocalCurrency = Number(
          roundOffValue(getSum(seedLiveRevenueInLocalCurrency, adjustInLocalCurrency), 2),
        );

        subtotalCreditRevenueInUSD += sumInUSD;
        subtotalCreditRevenue += sumInLocalCurrency;
      }

      if (data.meterName === METER_TYPE.CASH_RECONCILIATION) {
        // In local currency cash revenue
        const sum = getCashRevenue(
          data[FIELD_NAME.BILLS_COLLECTED],
          data[FIELD_NAME.COINS_COLLECTED],
          data[FIELD_NAME.METER_ADJUST],
        );
        // expectedCashRevenue and meterVariance are in USD
        const expectedCashRevenue = Number(data[FIELD_NAME.EXPECTED_REVENUE] || 0);
        const meterVariance = Number(data[FIELD_NAME.VARIANCE] || 0);
        subtotalCashRevenue += getRevenueInLocalCurrency(expectedCashRevenue, exchangeRate);
        subtotalCashRevenueInUSD += expectedCashRevenue;
        variance += meterVariance;
        subtotalCashCollectedRevenue += sum;
        subtotalCashCollectedRevenueInUSD += getRevenueInUSD(sum, exchangeRate);
      }
      if ([METER_TYPE.COIN_RECONCILIATION, METER_TYPE.CANDY].includes(data.meterName)) {
        subtotalCashRevenue += checkNumberIsNaNAndInfinity(data[FIELD_NAME.COINS_COLLECTED]);
        subtotalCashRevenueInUSD += getRevenueInUSD(
          checkNumberIsNaNAndInfinity(data[FIELD_NAME.COINS_COLLECTED]),
          exchangeRate,
        );
      }
      if ([METER_TYPE.GAME_REVENUE, METER_TYPE.IMPULSE_READER].includes(data.meterName)) {
        subtotalCreditRevenue += checkNumberIsNaNAndInfinity(data[FIELD_NAME.GAME_REVENUE]);
        subtotalCreditRevenueInUSD += getRevenueInUSD(
          checkNumberIsNaNAndInfinity(data[FIELD_NAME.GAME_REVENUE]),
          exchangeRate,
        );
      }
      if (data.meterName === METER_TYPE.TOKEN_CHANGER_RECONCILIATION) {
        if (data?.machineAcceptedPaymentType === 'CASH') {
          const localCurrencyRevenue = Number(
            checkNumberIsNaNAndInfinity(data[FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER]),
          );
          tokenChangerCashRevenue += getRevenueInUSD(checkNumberIsNaNAndInfinity(localCurrencyRevenue), exchangeRate);
        }
        if (data?.machineAcceptedPaymentType === 'CREDIT') {
          const seedliveRevenue = Number(
            checkNumberIsNaNAndInfinity(data[FIELD_NAME.SEEDLIVE_REVENUE]) -
              checkNumberIsNaNAndInfinity(Number(data[FIELD_NAME.TEST_VENDS])),
          );
          tokenChangerCreditRevenue += checkNumberIsNaNAndInfinity(seedliveRevenue);
        }
      }
    });

    if (tokenChangerCashRevenue > 0) {
      subtotalCashRevenueInUSD += tokenChangerCashRevenue;
      subtotalCashRevenue += getRevenueInLocalCurrency(tokenChangerCashRevenue, exchangeRate);
    }
    if (tokenChangerCreditRevenue > 0) {
      subtotalCreditRevenueInUSD += tokenChangerCreditRevenue;
      subtotalCreditRevenue += getRevenueInLocalCurrency(tokenChangerCreditRevenue, exchangeRate);
    }
    if (subtotalCreditRevenue < 0) {
      subtotalCreditRevenue = 0;
    }
    if (subtotalCashRevenue > 0 || subtotalCreditRevenue > 0) {
      subtotalRevenue = subtotalCashRevenue + subtotalCreditRevenue;
    }
    if (subtotalCashRevenueInUSD > 0 || subtotalCreditRevenueInUSD > 0) {
      subtotalRevenueInUSD = subtotalCashRevenueInUSD + subtotalCreditRevenueInUSD;
    }

    const summary = {};
    if (subtotalCreditRevenue > 0) {
      summary.subtotalCreditRevenue = subtotalCreditRevenue;
    }
    if (subtotalCreditRevenueInUSD > 0) {
      summary.subtotalCreditRevenueInUSD = subtotalCreditRevenueInUSD;
    }

    if (subtotalCashRevenue > 0) {
      summary.subtotalCashRevenue = subtotalCashRevenue;
    }
    if (subtotalCashRevenueInUSD > 0) {
      summary.subtotalCashRevenueInUSD = subtotalCashRevenueInUSD;
    }
    if (subtotalCashCollectedRevenue > 0) {
      summary.subtotalCashCollectedRevenue = subtotalCashCollectedRevenue;
    }
    if (subtotalCashCollectedRevenueInUSD > 0) {
      summary.subtotalCashCollectedRevenueInUSD = subtotalCashCollectedRevenueInUSD;
    }
    if (variance !== 0) {
      summary.variance = variance;
    }

    if (subtotalRevenue > 0) {
      summary.subtotalRevenue = subtotalRevenue;
    }
    if (subtotalRevenueInUSD > 0) {
      summary.subtotalRevenueInUSD = subtotalRevenueInUSD;
    }

    if (Object.keys(summary).length > 0) {
      summary.assetId = assetId;
    }
    return summary;
  }
};

export const allowPlaycardSubmission = (playcard) => {
  let shouldBeEnabled = true;
  for (const key in playcard) {
    const selectedPlaycard = { ...playcard[key] };
    if ('adjustments' in selectedPlaycard) {
      delete selectedPlaycard.adjustments;
    }
    Object.values(selectedPlaycard).map((item) => {
      if (Number(item) < 0) shouldBeEnabled = false;
    });
    if (!shouldBeEnabled) break;
  }
  return shouldBeEnabled;
};

export const getDispensedFieldNameByType = (meterName) => {
  switch (meterName) {
    case METER_TYPE.TOKEN:
      return FIELD_NAME.TOKEN_COLLECTED;
    case METER_TYPE.PRIZE:
      return FIELD_NAME.PRIZE_DISPENSED;
    case METER_TYPE.MEDALLION:
      return FIELD_NAME.MEDALLION_DISPENSED;
    case METER_TYPE.TICKET:
      return FIELD_NAME.TICKET_DISPENSED;
    case METER_TYPE.CREDIT:
      return FIELD_NAME.EXPECTED_REVENUE;
    default:
      return '';
  }
};

export const getDispensedFieldNameAndValue = (name, currentReadingValue, values, isReconciliation) => {
  const [meterName, field, sublocationId, assetId, meterId, assetMeterId] = name.split('_');
  const priorNameFieldName = getFieldName(
    meterName,
    FIELD_NAME.PRIOR_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const currentReadingFieldName = getFieldName(
    meterName,
    FIELD_NAME.CURRENT_READING,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );
  const priorReading =
    isReconciliation && field === FIELD_NAME.PRIOR_READING ? currentReadingValue : values[priorNameFieldName];
  const currentReading = field === FIELD_NAME.CURRENT_READING ? currentReadingValue : values[currentReadingFieldName];

  const dispensedField = getDispensedFieldNameByType(meterName);
  const dispensedFieldName = getFieldName(meterName, dispensedField, sublocationId, assetId, meterId, assetMeterId);
  const dispensedValue = getDispensedValue(currentReading, priorReading);
  return {
    dispensedFieldName: dispensedFieldName,
    dispensedValue: dispensedValue,
  };
};

export const getNetCashTotal = (state) => {
  const value = Number(state.cashPlaycardSales || 0) - Number(state.cashRefunds || 0);
  return value;
};

export const getNetCreditCardTotal = (state) => {
  const value = Number(state.salesFromCreditCard || 0) - Number(state.creditCardRefunds || 0);
  return value;
};

export const getAdjustedPlaycardSales = (state) => {
  const value =
    Number(state.netCashTotal || 0) +
    Number(state.netCreditCardTotal || 0) +
    Number(state.complimentaryPlaycards || 0) +
    Number(state.bulkEncodedPlaycards || 0) +
    Number(state.adjustments || 0);

  return value;
};

export const getMeterLabel = (assetMeter, locationCurrency = {}) => {
  const { meter, identifier, displayName, description, denomination, machineAcceptedPaymentType } = assetMeter;
  const abbreviation = locationCurrency?.abbreviation || CURRENCY_CONSTANT.USD;
  switch (meter?.name) {
    case METER_TYPE.PRIZE:
      if (identifier) {
        return description ? `${meter?.name}-${description}-${identifier}` : `${meter?.name}-${identifier}`;
      } else if (!identifier) {
        return description ? `${meter?.name}-${description}` : `${meter?.name}`;
      }
      break;
    case METER_TYPE.MEDALLION:
      if (identifier) {
        return displayName ? `${meter?.name}-${displayName}-${identifier}` : `${meter?.name}-${identifier}`;
      } else if (!identifier) {
        return displayName ? `${meter?.name}-${displayName}` : `${meter?.name}`;
      }
      break;
    case METER_TYPE.TOKEN_CHANGER:
      if (denomination && machineAcceptedPaymentType) {
        return identifier
          ? `${METER_TYPE.TOKEN_CHANGER} ${getCurrency(abbreviation)}${denomination} ${
              machineAcceptedPaymentType === TOKEN_CHANGER_PAYMENT_TYPE.CASH
                ? TOKEN_CHANGER_PAYMENT_TYPE.CASH.toLowerCase()
                : TOKEN_CHANGER_PAYMENT_TYPE.CREDIT.toLowerCase()
            } ${identifier}`
          : `${METER_TYPE.TOKEN_CHANGER} ${getCurrency(abbreviation)}${denomination} ${
              machineAcceptedPaymentType === TOKEN_CHANGER_PAYMENT_TYPE.CASH
                ? TOKEN_CHANGER_PAYMENT_TYPE.CASH.toLowerCase()
                : TOKEN_CHANGER_PAYMENT_TYPE.CREDIT.toLowerCase()
            }`;
      }
      if (identifier) {
        return `${METER_TYPE.TOKEN_CHANGER}-${identifier}`;
      }
      return METER_TYPE.TOKEN_CHANGER;
    default:
      if (identifier) {
        return `${meter?.name}-${identifier}`;
      }
      return `${meter?.name}`;
  }
};

export const getCommentAPIData = (transactionId, comment, assetId, sublocationId, exchangeRate) => ({
  exchangeRate: exchangeRate,
  transactionId: transactionId,
  sublocations: [
    {
      sublocationId: Number(sublocationId),
      assets: [
        {
          assetId: Number(assetId),
          comments: [
            {
              commentText: comment?.comment,
            },
          ],
        },
      ],
    },
  ],
});

export const getCommentDataToDisplayOnReconciliation = (subLoc) => {
  const commentData = subLoc?.assets
    ?.map((asset) => {
      if (asset?.comments?.length > 0) {
        const isMultiple = asset?.comments?.length > 1 ? true : false;
        const filteredUsers = Array.from(
          new Set(
            asset?.comments
              .map((item) => {
                if (item?.createdByUser?.firstName || item?.createdByUser?.lastName) {
                  return `${item?.createdByUser?.firstName || ''} ${item?.createdByUser?.lastName || ''}`;
                }
                return false;
              })
              .filter((el) => el !== false),
          ),
        );
        const userString =
          filteredUsers?.length > 3 ? filteredUsers.slice(-3).join(', ') + ' and others' : filteredUsers.join(', ');
        return {
          assetId: asset?.id,
          users: `${userString} added ${isMultiple ? 'comments' : 'a comment'} on `,
        };
      }
      return false;
    })
    .filter((el) => el !== false);
  return commentData;
};

export const getApprovedSubtotals = (assetMeters, exchangeRate) => {
  let subtotalCashRevenue = 0, //total cash revenue
    subtotalCashRevenueInUSD = 0, //total cash revenue in USD
    subtotalCreditRevenue = 0,
    subtotalCreditRevenueInUSD = 0,
    subtotalCashCollectedRevenue = 0, // actual cash revenue
    subtotalCashCollectedRevenueInUSD = 0, // actual cash revenue in USD
    singleVariance = 0,
    variance = 0;
  assetMeters?.map((assetMeter) => {
    const { meter } = assetMeter;
    // The BILLS_COLLECTED, COINS_COLLECTED and adjust field value is in local currency by default
    if (meter?.name === METER_TYPE.CREDIT) {
      const adjustCRInLocalCurrency = assetMeter[FIELD_NAME.METER_ADJUST];
      const seedLiveRevenueInLocalCurrency = getRevenueInLocalCurrency(
        assetMeter[FIELD_NAME.SEEDLIVE_REVENUE],
        exchangeRate,
      );
      const seedLiveRevenueInUSD = assetMeter[FIELD_NAME.SEEDLIVE_REVENUE];
      const adjustCRInUSD = getRevenueInUSD(adjustCRInLocalCurrency, exchangeRate);
      const creditRevenue =
        checkNumberIsNaNAndInfinity(seedLiveRevenueInUSD) + checkNumberIsNaNAndInfinity(adjustCRInUSD);
      subtotalCreditRevenueInUSD += creditRevenue;
      subtotalCreditRevenue += Number(
        roundOffValue(getSum(adjustCRInLocalCurrency, seedLiveRevenueInLocalCurrency), 2),
      );
    }
    if (meter?.name === METER_TYPE.CASH) {
      // EXPECTED_REVENUE and VARIANCE are in USD by default
      const expectedRevenueInUSD = checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.EXPECTED_REVENUE]);
      subtotalCashRevenueInUSD += expectedRevenueInUSD;
      if (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE) {
        const expectedRevenueInLocalCurrency = getRevenueInLocalCurrency(expectedRevenueInUSD, exchangeRate);
        // In local currency
        subtotalCashRevenue += expectedRevenueInLocalCurrency;
      }
      singleVariance = checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.VARIANCE]);
      variance += singleVariance;
      const cashRevenue =
        checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.BILLS_COLLECTED]) +
        checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.COINS_COLLECTED]) +
        checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.METER_ADJUST]);
      const cashRevenueInUSD =
        exchangeRate === DEFAULT_EXCHANGE_RATE ? cashRevenue : getRevenueInUSD(cashRevenue, exchangeRate);
      subtotalCashCollectedRevenue += cashRevenue;
      subtotalCashCollectedRevenueInUSD += cashRevenueInUSD;
    }
    if (meter?.name === METER_TYPE.COIN) {
      const cashRevenue = checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.COINS_COLLECTED]);
      let cashRevenueInUSD = 0;
      if (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE) {
        cashRevenueInUSD = getRevenueInUSD(cashRevenue, exchangeRate);
      } else {
        cashRevenueInUSD = cashRevenue;
      }
      subtotalCashRevenue += cashRevenue;
      subtotalCashRevenueInUSD += cashRevenueInUSD;
    }
    if (meter?.name === METER_TYPE.TOKEN_CHANGER) {
      if (assetMeter?.machineAcceptedPaymentType === 'CASH') {
        const { revenueCollected: tokenChangerCashRevenue } = getTokenChangerRevenueAndDispensedValue(
          assetMeter?.currentReading,
          assetMeter?.priorReading,
          assetMeter,
        );
        subtotalCashRevenue += Number(Number(tokenChangerCashRevenue));
        const tokenChangerCashRevenueInUSD = getRevenueInUSD(tokenChangerCashRevenue, exchangeRate);
        subtotalCashRevenueInUSD += tokenChangerCashRevenueInUSD;
      }
      if (assetMeter?.machineAcceptedPaymentType === 'CREDIT') {
        const deductTestVends = Number(
          Number(assetMeter[FIELD_NAME.SEEDLIVE_REVENUE]) - Number(assetMeter[FIELD_NAME.TEST_VENDS]),
        );
        subtotalCreditRevenueInUSD += Number(deductTestVends);
        subtotalCreditRevenue += getRevenueInLocalCurrency(deductTestVends, exchangeRate);
      }
    }
    if (meter?.name === METER_TYPE.CANDY) {
      const candyRevenue = checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.COINS_COLLECTED]);
      let candyRevenueInUSD = 0;
      if (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE) {
        candyRevenueInUSD = getRevenueInUSD(candyRevenue, exchangeRate);
      } else {
        candyRevenueInUSD = candyRevenue;
      }
      subtotalCashRevenue += checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.COINS_COLLECTED]);
      subtotalCashRevenueInUSD += candyRevenueInUSD;
    }
    if (meter?.name === METER_TYPE.IMPULSE_READER) {
      const impulseReaderRevenue = checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.GAME_REVENUE]);
      let impulseReaderRevenueInUSD = 0;
      if (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE) {
        impulseReaderRevenueInUSD = getRevenueInUSD(impulseReaderRevenue, exchangeRate);
      } else {
        impulseReaderRevenueInUSD = impulseReaderRevenue;
      }
      subtotalCreditRevenue += checkNumberIsNaNAndInfinity(assetMeter[FIELD_NAME.GAME_REVENUE]);
      subtotalCreditRevenueInUSD += impulseReaderRevenueInUSD;
    }
  });
  if (subtotalCreditRevenue < 0) {
    subtotalCreditRevenue = 0;
  }
  return {
    subtotalCashRevenue,
    subtotalCreditRevenue,
    subtotalCreditRevenueInUSD,
    variance,
    subtotalCashCollectedRevenue,
    subtotalCashCollectedRevenueInUSD,
    subtotalCashRevenueInUSD,
  };
};

export const getSummaryArray = (subtotals, abbreviation = CURRENCY_CONSTANT.USD) => {
  const {
    subtotalRevenueLocalCurrency,
    subtotalCashRevenue,
    subtotalCreditRevenue,
    subtotalRevenueInUSD,
    subtotalCashRevenueInUSD,
    subtotalCreditRevenueInUSD,
    subtotalCashCollectedRevenueInUSD,
    subtotalCashCollectedRevenue,
    variance,
  } = subtotals || {};
  const priceIcon = getCurrency(abbreviation);
  const USDPriceIcon = getCurrency(CURRENCY_CONSTANT.USD);
  let summary = [];
  if (subtotalCreditRevenue > 0 && abbreviation !== CURRENCY_CONSTANT.USD) {
    summary = [
      ...summary,
      {
        label: `CC revenue ${abbreviation !== CURRENCY_CONSTANT.USD ? `(${abbreviation})` : ''}`,
        value: formatNumberOrReturnZero(subtotalCreditRevenue),
        priceIcon,
      },
    ];
  }
  if (subtotalCreditRevenueInUSD > 0) {
    summary = [
      ...summary,
      {
        label: `CC revenue (${CURRENCY_CONSTANT.USD})`,
        value: formatNumberOrReturnZero(subtotalCreditRevenueInUSD),
        priceIcon: USDPriceIcon,
      },
    ];
  }

  if (subtotalCashRevenue > 0 && abbreviation !== CURRENCY_CONSTANT.USD) {
    summary = [
      ...summary,
      {
        label: `Expected Cash revenue ${abbreviation !== CURRENCY_CONSTANT.USD ? `(${abbreviation})` : ''}`,
        value: formatNumberOrReturnZero(subtotalCashRevenue),
        priceIcon,
      },
    ];
  }
  if (subtotalCashRevenueInUSD > 0) {
    summary = [
      ...summary,
      {
        label: `Expected Cash revenue (${CURRENCY_CONSTANT.USD})`,
        value: formatNumberOrReturnZero(subtotalCashRevenueInUSD),
        priceIcon: USDPriceIcon,
      },
    ];
  }
  if (subtotalCashCollectedRevenue > 0 && abbreviation !== CURRENCY_CONSTANT.USD) {
    summary = [
      ...summary,
      {
        label: `Actual Cash revenue ${abbreviation ? `(${abbreviation})` : ''}`,
        value: formatNumberOrReturnZero(subtotalCashCollectedRevenue),
        priceIcon,
      },
    ];
  }
  if (subtotalCashCollectedRevenueInUSD > 0) {
    summary = [
      ...summary,
      {
        label: `Actual Cash revenue (${CURRENCY_CONSTANT.USD})`,
        value: formatNumberOrReturnZero(subtotalCashCollectedRevenueInUSD),
        priceIcon: USDPriceIcon,
      },
    ];
  }
  if (variance !== 0) {
    summary = [
      ...summary,
      {
        label: `Variance (${CURRENCY_CONSTANT.USD})`,
        value: formatNumberOrReturnZero(variance),
        priceIcon: USDPriceIcon,
      },
    ];
  }
  if (subtotalRevenueLocalCurrency > 0 && abbreviation !== CURRENCY_CONSTANT.USD) {
    summary = [
      ...summary,
      {
        label: `Total CC & Cash revenue ${abbreviation !== CURRENCY_CONSTANT.USD ? `(${abbreviation})` : ''}`,
        value: formatNumberOrReturnZero(subtotalRevenueLocalCurrency),
        priceIcon,
      },
    ];
  }
  if (subtotalRevenueInUSD > 0) {
    summary = [
      ...summary,
      {
        label: `Total CC & Cash revenue (${CURRENCY_CONSTANT.USD})`,
        value: formatNumberOrReturnZero(subtotalRevenueInUSD),
        priceIcon: USDPriceIcon,
      },
    ];
  }
  return summary;
};

export const getRevenueByType = (name, currentReadingValue, values) => {
  const [meterName, field, sublocationId, assetId, meterId, assetMeterId] = name.split('_');
  const revenueFieldName = getFieldName(
    meterName,
    FIELD_NAME.REVENUE_IN_USD,
    sublocationId,
    assetId,
    meterId,
    assetMeterId,
  );

  switch (meterName) {
    case METER_TYPE.CASH: {
      const billsCollectedFieldName = getFieldName(
        meterName,
        FIELD_NAME.BILLS_COLLECTED,
        sublocationId,
        assetId,
        meterId,
        assetMeterId,
      );
      const coinsCollectedFieldName = getFieldName(
        meterName,
        FIELD_NAME.COINS_COLLECTED,
        sublocationId,
        assetId,
        meterId,
        assetMeterId,
      );
      const adjustFieldName = getFieldName(
        meterName,
        FIELD_NAME.METER_ADJUST,
        sublocationId,
        assetId,
        meterId,
        assetMeterId,
      );
      const billsCollected =
        field === FIELD_NAME.BILLS_COLLECTED ? Number(currentReadingValue) : Number(values[billsCollectedFieldName]);
      const coinsCollected =
        field === FIELD_NAME.COINS_COLLECTED ? Number(currentReadingValue) : Number(values[coinsCollectedFieldName]);
      const adjust = field === FIELD_NAME.METER_ADJUST ? Number(currentReadingValue) : Number(values[adjustFieldName]);
      return { revenueFieldName: revenueFieldName, value: billsCollected + coinsCollected + adjust };
    }
    case METER_TYPE.CANDY: {
      return { revenueFieldName: revenueFieldName, value: currentReadingValue };
    }
    case METER_TYPE.IMPULSE_READER: {
      return { revenueFieldName: revenueFieldName, value: currentReadingValue };
    }
    case METER_TYPE.COIN: {
      const coinsCollectedFieldName = getFieldName(
        meterName,
        FIELD_NAME.COINS_COLLECTED,
        sublocationId,
        assetId,
        meterId,
        assetMeterId,
      );
      const coinsCollected =
        field === FIELD_NAME.COINS_COLLECTED ? Number(currentReadingValue) : Number(values[coinsCollectedFieldName]);
      return { revenueFieldName: revenueFieldName, value: coinsCollected };
    }
    case METER_TYPE.BILL: {
      const billsChangerFieldName = getFieldName(
        meterName,
        FIELD_NAME.BILLS_TAKEN_FROM_CHANGER,
        sublocationId,
        assetId,
        meterId,
        assetMeterId,
      );
      const coinsChangerFieldName = getFieldName(
        meterName,
        FIELD_NAME.COINS_ADDED_TO_CHANGER,
        sublocationId,
        assetId,
        meterId,
        assetMeterId,
      );
      const billsChangerCollected =
        field === FIELD_NAME.BILLS_TAKEN_FROM_CHANGER
          ? Number(currentReadingValue)
          : Number(values[billsChangerFieldName]);
      const coinsChangerCollected =
        field === FIELD_NAME.COINS_ADDED_TO_CHANGER
          ? Number(currentReadingValue)
          : Number(values[coinsChangerFieldName]);
      return { revenueFieldName: revenueFieldName, value: billsChangerCollected + coinsChangerCollected };
    }
    case METER_TYPE.TOKEN_CHANGER: {
      const revenueCollectedName = getFieldName(
        meterName,
        FIELD_NAME.REVENUE_COLLECTED_FROM_TOKEN_CHANGER,
        sublocationId,
        assetId,
        meterId,
        assetMeterId,
      );
      const revenueCollected = Number(values[revenueCollectedName]);
      return { revenueFieldName: revenueFieldName, value: revenueCollected };
    }
    default:
      return { revenueFieldName: revenueFieldName, value: 0 };
  }
};

export const getRevenueFieldNameAndRevenueInUSD = (exchangeRate, currentReadingValue, values, name) => {
  const { revenueFieldName, value } = getRevenueByType(name, currentReadingValue, values);
  const revenueInUSD = getRevenueInUSD(value, exchangeRate);

  return { revenueFieldName, revenueInUSD };
};

export const getRevenueInUSD = (value, exchangeRate) => {
  const revenueInUSD = roundOffValue(Number(value) * Number(exchangeRate));
  // Can return 0 when exchangeRate is undefined or NaN because of roundOffValue
  return Number(revenueInUSD);
};

export const getRevenueInLocalCurrency = (value, exchangeRate = DEFAULT_EXCHANGE_RATE) => {
  const revenueInUSD = roundOffValue(Number(value) / Number(exchangeRate));
  return Number(revenueInUSD);
};

export const getDebitAndCreditValueForAccountSummary = (subtotalRevenue) => {
  let obj = {};
  subtotalRevenue?.map((item) => {
    if (obj[item?.sublocationId]) {
      const temp = { ...obj };
      const credit =
        Number(obj[item?.sublocationId]?.creditRevenue || 0) + Number(item?.subtotalCreditRevenueInUSD || 0);
      const cash = Number(obj[item?.sublocationId]?.cashRevenue || 0) + Number(item?.subtotalCashRevenueInUSD || 0);
      const assetVariance = Number(obj[item?.sublocationId]?.variance) + Number(item?.variance || 0);
      const newObj = { creditRevenue: credit || 0, cashRevenue: cash || 0, variance: assetVariance || 0 };
      temp[item?.sublocationId] = {
        ...newObj,
      };
      obj = { ...temp };
    } else if (!obj[item?.sublocationId])
      obj[item?.sublocationId] = {
        creditRevenue: item?.subtotalCreditRevenueInUSD || 0,
        cashRevenue: item?.subtotalCashRevenueInUSD || 0,
        variance: item?.variance || 0,
      };
  });
  return obj;
};

/**
 * Retrieves and calculates account summary data based on given parameters.
 *
 * @param {boolean} taxExemptFlag - A flag indicating if the account is tax-exempt.
 * @param {number} subtotalRevenues - The subtotal revenues of the account.
 * @param {object} sublocation - The sub-location of the account.
 * @param {object} playCards - The play cards data of the account.
 * @param {object} previousAccountSummary - The previous account summary data.
 * @param {object} variableSummaryFields - The variable summary fields of the account.
 *
 * @returns {object} - The new account summary data and updated variable field data.
 */
export const getAccountSummaryData = (
  taxExemptFlag,
  subtotalRevenues,
  sublocation,
  playCards,
  previousAccountSummary,
  variableSummaryFields,
) => {
  // Referring to sublocation in a shorter variable for brevity
  const subloc = sublocation;

  // Get the user deposit account number from the sublocation object
  const userDepositAccountNumber = subloc?.userDepositAccountNumber;

  // Filter the account numbers for the Credit Account
  let creditAccount = subloc?.accountNumbers?.filter(
    (account) => String(account?.accountName) === String('CREDIT CARD'),
  );

  // Filter the account numbers for Debit Account
  let debitAccount = subloc?.accountNumbers?.filter((account) => {
    const accountNumber = String(account.accountNumber).replace(/\D/g, '');
    return accountNumber === String(userDepositAccountNumber?.accountNumber);
  });

  // If the debit account is empty, filter again the account numbers using Cash Deposit Account Type
  if (!isArrayWithLength(debitAccount)) {
    debitAccount = subloc?.accountNumbers?.filter((account) => String(account?.type) === ACCOUNT_TYPES.CASH_DEPOSIT);
  }

  // This filters the account numbers for Accounts Receivable.
  let accountReceivableAccount;
  const isElaut = subloc?.productCategories.some((category) => category.name === 'ELAUT');
  const isNamcoOrArcade = subloc?.productCategories.some(
    (category) => category.name === 'NAMCO' || category.name === 'ARCADE',
  );

  const filteredDebitAccount =
    debitAccount?.length > 0
      ? debitAccount?.find((el) => el.userId === userDepositAccountNumber?.userId && subloc?.id === el?.sublocationId)
      : null;
  if (filteredDebitAccount) {
    accountReceivableAccount = [filteredDebitAccount];
  } else if (debitAccount[0]?.accountName) {
    accountReceivableAccount = subloc?.accountNumbers.filter(
      (account) => String(account?.accountName) === debitAccount[0]?.accountName,
    );
  } else if (isElaut) {
    accountReceivableAccount = subloc?.accountNumbers?.filter(
      (account) => String(account?.accountName) === ACCOUNT_TYPES.ELAUT_ACCOUNT,
    );
  } else if (isNamcoOrArcade) {
    accountReceivableAccount = subloc?.accountNumbers?.filter(
      (account) => String(account?.accountName) === ACCOUNT_TYPES.ARCADE_ACCOUNT,
    );
  } else {
    accountReceivableAccount = subloc?.accountNumbers?.filter(
      (account) => String(account?.accountName) === ACCOUNT_TYPES.ACCOUNT_RECEIVABLE,
    );
  }

  // Filter the account numbers for Gross Sale Account
  let grossSaleAccount = subloc?.accountNumbers?.filter(
    (account) => String(account?.accountName) === ACCOUNT_TYPES.DEFAULT_GROSS_SALES,
  );

  // if Gross Sale Account remains empty after filtering, filter again using Gross Sales account type
  if (!checkObjectNotEmpty(grossSaleAccount)) {
    grossSaleAccount = subloc?.accountNumbers?.filter((account) => String(account?.type) === ACCOUNT_TYPES.GROSS_SALES);
  }

  // Similarly, do for Credit Account as well
  if (!checkObjectNotEmpty(creditAccount)) {
    creditAccount = subloc?.accountNumbers?.filter((account) => String(account?.type) === ACCOUNT_TYPES.CREDIT_CARD);
  }

  // Calculate the net cash revenue
  const netCashRevenue =
    subtotalRevenues && subtotalRevenues[subloc?.id]
      ? roundOffValue(Number(subtotalRevenues[subloc?.id]?.cashRevenue || 0))
      : roundOffValue(0);

  // Calculate the net credit revenue
  const netCreditRevenue =
    subtotalRevenues && subtotalRevenues[subloc?.id]
      ? roundOffValue(Number(subtotalRevenues[subloc?.id]?.creditRevenue || 0))
      : roundOffValue(0);

  // Variance Calculation
  const variance =
    subtotalRevenues && subtotalRevenues[subloc?.id]
      ? roundOffValue(Number(subtotalRevenues[subloc?.id]?.variance || 0))
      : roundOffValue(0);

  // Net Playcard Revenue
  const netPlaycardRevenue =
    playCards && playCards?.adjustedPlaycardSales ? roundOffValue(Number(playCards?.adjustedPlaycardSales || 0)) : 0;

  // Net Playcard Credit Revenue
  const netPlaycardCreditRevenue =
    playCards && playCards?.netCreditCardTotal ? roundOffValue(Number(playCards?.netCreditCardTotal || 0)) : 0;

  // Total of cash revenue, credit revenue and play card revenue
  const total = Number(netCashRevenue) + Number(netCreditRevenue) + Number(netPlaycardRevenue);

  // Total Credit Revenue for credit account which is net credit revenue plus net play card credit
  const totalCreditRevenueForCreditAccount = Number(netCreditRevenue) + Number(netPlaycardCreditRevenue);

  // Get the updated variable field data
  const updatedVariableFieldData = getVariableAccountSummaryFields({
    taxExemptFlag,
    sublocation,
    grossSale: total,
    previousVariableFieldData: variableSummaryFields,
    variance,
  });

  // Get the updated Variable Field array from the updated variable data
  const updatedVariableFieldArray = getVariableFieldArray(updatedVariableFieldData);

  // Define the debit field index based on the length of updatedVariableFieldData plus 2, treat as a number
  const debitFieldIndex = Number(Object.keys(updatedVariableFieldData)?.length + 2);

  // The creditFieldIndex is always set to 1
  const creditFieldIndex = Number(1);

  // Define newData as an array containing a series of objects
  const newData = [
    {
      // Check if accountNumber of previous summary is not empty or null, if so, assign it.
      // If not, and if grossSaleAccount has length, assign it. If everything fails, set it to null
      accountNumber:
        checkObjectNotEmpty(previousAccountSummary?.[0]?.accountNumber) &&
        checkValueNotNullUndefinedBlank(previousAccountSummary?.[0]?.accountNumber?.value)
          ? previousAccountSummary?.[0]?.accountNumber
          : isArrayWithLength(grossSaleAccount)
          ? { text: grossSaleAccount[0]?.accountNumber || '', value: Number(grossSaleAccount[0]?.id) || '' }
          : null,

      // Check if previous account number exists, if true, assign the name from previous summary,
      // otherwise, it defaults to name in the `grossSaleAccount` array of account objects
      name: previousAccountSummary?.[0]?.accountNumber?.value
        ? previousAccountSummary?.[0]?.name
        : grossSaleAccount[0]?.accountName || '',

      // The `description` field is obtained from the previous account summary or set to an empty string
      description: previousAccountSummary?.[0]?.description || '',

      // If the debit amount exists in the previous account summary, assign it.
      // If not, assign the round off value of 0
      debit: previousAccountSummary?.[0]?.debit || roundOffValue(0),

      // The credit amount is obtained as the round off of the total amount
      credit: roundOffValue(total, 2),
    },
    {
      // Similar checks for accountNumber, name and description are done here.
      // The only difference being, we're considering a creditAccount this time
      accountNumber:
        checkObjectNotEmpty(previousAccountSummary?.[creditFieldIndex]?.accountNumber) &&
        checkValueNotNullUndefinedBlank(previousAccountSummary?.[creditFieldIndex]?.accountNumber?.value)
          ? previousAccountSummary?.[creditFieldIndex]?.accountNumber
          : isArrayWithLength(creditAccount)
          ? { text: creditAccount[0]?.accountNumber || '', value: Number(creditAccount[0]?.id) || '' }
          : null,
      name: previousAccountSummary?.[0]?.accountNumber?.value
        ? previousAccountSummary?.[creditFieldIndex]?.name
        : creditAccount[0]?.accountName || '',
      description: previousAccountSummary?.[creditFieldIndex]?.description || '',

      // The debit is given the value of the total credit revenue for credit account
      debit: roundOffValue(totalCreditRevenueForCreditAccount),

      // The credit amount is retrieved from the previous account summary or defaults to the round off value of 0
      credit: previousAccountSummary?.[creditFieldIndex]?.credit || roundOffValue(0),
    },
    // Spread operator is used to include all elements of the `updatedVariableFieldArray` into `newData` array
    ...updatedVariableFieldArray,
    {
      // Similar checks to the above are done here, but considering the accountReceivableAccount this time
      accountNumber:
        checkObjectNotEmpty(previousAccountSummary?.[debitFieldIndex]?.accountNumber) &&
        checkValueNotNullUndefinedBlank(previousAccountSummary?.[debitFieldIndex]?.accountNumber?.value)
          ? previousAccountSummary?.[debitFieldIndex]?.accountNumber
          : isArrayWithLength(accountReceivableAccount)
          ? {
              text: accountReceivableAccount[0]?.accountNumber || '',
              value: Number(accountReceivableAccount[0]?.id) || '',
            }
          : null,
      name: previousAccountSummary?.[debitFieldIndex]?.accountNumber?.value
        ? previousAccountSummary?.[debitFieldIndex]?.name
        : accountReceivableAccount[0]?.accountName || '',
      description: previousAccountSummary?.[debitFieldIndex]?.description || '',
      debit: previousAccountSummary?.[debitFieldIndex]?.debit || roundOffValue(0),
      credit: previousAccountSummary?.[debitFieldIndex]?.credit || roundOffValue(0),
    },
  ];

  // The new account summary data and updated variable field data is returned
  return { newAccountSummaryData: newData, updatedVariableFieldData };
};

export const getApprovedTabAccountSummaryData = (accountSumData) => {
  if (accountSumData?.length > 0) {
    let accountSummary = accountSumData?.filter((item) => String(item?.type) !== String('GROSS_SALES'));

    let grossSales = accountSumData?.filter((item) => String(item?.type) === String('GROSS_SALES'));
    return { accountSummary: accountSummary, grossSales: grossSales };
  }
  return { accountSummary: [], grossSales: [] };
};

// TODO: refactor
export const getDraftData = (formikData, dateOfReading, dateEntered, isReconciliation) => {
  const data = {};
  if (!formikData && !dateOfReading && !dateEntered) {
    return data;
  }
  if (dateOfReading && dateEntered) {
    data.dateOfReading = getDateFormat(dateOfReading);
    data.dateEntered = getDateFormat(dateEntered);
  }
  if (formikData) {
    Object.entries(formikData).map(([key, value]) => {
      if (!key.includes(FIELD_NAME.PRIOR_READING && !isReconciliation) && !key.includes(METER_TYPE.INTERCARD)) {
        data[key] = value;
      }
      if (key.includes('independent')) {
        data[key] = value;
      }
    });
  }
  return data;
};

export const getInitialDatesForSublocation = () => {
  let obj = {
    dateOfReading: dateFormatForApi(dayjs(new Date())),
    dateEntered: dateFormatForApi(new Date()),
  };
  return obj;
};

export const getFieldsToUpdateWithChangeInDates = (states, isReconciliation = false) => {
  const updateFieldArray = ['points', 'cashDebits', 'standardPlay', 'seedliveTotalRevenue', 'meterAdjustment'];
  if (!isReconciliation) {
    updateFieldArray.push('priorReading');
  }

  let revisedStates = {};
  for (let key in states) {
    let fieldName = String(key).split('_')[1];
    if (updateFieldArray.includes(String(fieldName))) {
      revisedStates = { ...revisedStates, [key]: states[key] };
    }
  }
  return revisedStates;
};

export const getSeedLiveDataForUpdate = (seedliveData, sublocation) => {
  const newSeedLiveDataForUpdate = [];
  const sublocationAssets = sublocation?.assets?.map((asset) => String(asset?.id));
  if ((checkValueNotNullUndefinedBlank(seedliveData), checkObjectNotEmpty(seedliveData))) {
    Object.keys(seedliveData)?.map((i) => {
      let obj = {};
      let fieldValue = 0;
      const meterName = seedliveData[i]?.meterName;
      if (meterName === METER_TYPE?.TOKEN_CHANGER) {
        fieldValue =
          seedliveData[i]?.machineAcceptedPaymentType === TOKEN_CHANGER_PAYMENT_TYPE.CASH
            ? seedliveData[i]?.seedliveCashAmount
            : seedliveData[i]?.seedliveCreditAmount;
      } else if (meterName === METER_TYPE?.CASH) {
        fieldValue = seedliveData[i]?.seedliveCashAmount;
      } else if (meterName === METER_TYPE?.CREDIT) {
        fieldValue = seedliveData[i]?.seedliveCreditAmount;
      }
      obj = {
        ...seedliveData[i],
        assetMeterId: i,
        field: 'seedliveTotalRevenue',
        fieldValue: Number(fieldValue || 0),
        sublocationId: sublocation?.id,
      };
      if (sublocationAssets?.includes(String(obj?.assetId))) {
        newSeedLiveDataForUpdate.push(obj);
      }
    });
  }
  let states = {};
  newSeedLiveDataForUpdate?.map((assetMeter) => {
    const name = getFieldName(
      assetMeter?.meterName,
      assetMeter?.field,
      assetMeter?.sublocationId,
      assetMeter?.assetId,
      assetMeter?.meterId,
      assetMeter?.assetMeterId,
    );
    if (checkValueNotNullUndefinedBlank(name)) {
      states = { ...states, [name]: assetMeter?.fieldValue };
    }
  });

  return states;
};

export const getNewAssetDataFromSublocation = (sublocation, intercardTransactions) => {
  let intercardDataForSub = {};
  intercardTransactions?.forEach((item) => {
    intercardDataForSub = { ...intercardDataForSub, ...item };
  });
  let updatedAssets = sublocation?.assets?.map((asset) => {
    const newAssetData = { ...asset, intercardTransactions: intercardDataForSub[asset?.id] || [] };
    return { ...newAssetData };
  });
  return { updatedAssets, intercardDataForSub };
};

export const getIntercardDataFromDataForApproveCollection = (data) => {
  const intercardDataForUpdate = data?.intercardData?.map((intercard) => {
    const newData = {
      assetSubTag: intercard?.assetSubTag,
      cashDebits: intercard?.cashDebits ? Number(intercard?.cashDebits) : intercard?.cashDebits,
      standardPlay: intercard?.standardPlay ? Number(intercard?.standardPlay) : intercard?.standardPlay,
      points: intercard?.points ? Number(intercard?.points) : intercard?.points,
      group: intercard?.group,
    };
    return newData;
  });
  return intercardDataForUpdate;
};

export const getLastCollectionDate = (sublocation, isReconciliation) => {
  if (isReconciliation) {
    return sublocation?.lastCollection?.dateOfReading;
  } else if (isArrayWithLength(sublocation?.collections)) {
    return sublocation?.collections[0]?.dateOfReading;
  }
  return '';
};

export const getVariableAccountSummaryFields = ({
  taxExemptFlag,
  sublocation,
  grossSale = 0,
  previousVariableFieldData = {},
  variance = 0,
}) => {
  let accountSummaryState = {};
  let salesTax = { ...sublocation?.salesTax };
  let totalTax = 0;
  let taxPayable;
  const salesTaxAccount =
    sublocation?.accountNumbers?.filter(
      (account) => String(account?.accountName) === String(ACCOUNT_TYPES.SALES_TAX),
    ) || [];
  const refundsAccount =
    sublocation?.accountNumbers?.filter((account) => String(account?.accountName) === ACCOUNT_TYPES.REFUNDS) || [];
  const variableRentAccount =
    sublocation?.accountNumbers?.filter((account) => String(account?.accountName) === ACCOUNT_TYPES.VARIABLE_RENT) ||
    [];
  if (taxExemptFlag && checkObjectNotEmpty(salesTax)) {
    totalTax = getTotalSalesTax(salesTax);
  }
  if (isArrayWithLength(refundsAccount)) {
    let previousRefunds =
      checkObjectNotEmpty(previousVariableFieldData) && checkObjectNotEmpty(previousVariableFieldData['refunds'])
        ? previousVariableFieldData['refunds']
        : {};
    const creditRefund = Number(variance) >= 0 ? variance : 0;
    const debitRefund = Number(variance) < 0 ? variance : 0;
    accountSummaryState['refunds'] = {
      [ACCOUNT_FIELDS.ACCOUNT_NUMBER]:
        checkObjectNotEmpty(previousRefunds) && checkValueNotNullUndefinedBlank(previousRefunds?.accountNumber?.value)
          ? previousRefunds?.accountNumber
          : { text: refundsAccount[0]?.accountNumber || '', value: Number(refundsAccount[0]?.id) || '' },
      [ACCOUNT_FIELDS.NAME]:
        checkObjectNotEmpty(previousRefunds) && checkValueNotNullUndefinedBlank(previousRefunds?.accountNumber?.value)
          ? String(previousRefunds?.name)
          : refundsAccount[0]?.accountName || '',
      [ACCOUNT_FIELDS.DESCRIPTION]: checkObjectNotEmpty(previousRefunds) ? String(previousRefunds?.description) : '',
      [ACCOUNT_FIELDS.DEBIT]: Math.abs(Number(debitRefund)),
      [ACCOUNT_FIELDS.CREDIT]: Math.abs(Number(creditRefund)),
    };
  }
  let previousSalesTax =
    checkObjectNotEmpty(previousVariableFieldData) && checkObjectNotEmpty(previousVariableFieldData['salesTax'])
      ? previousVariableFieldData['salesTax']
      : {};
  taxPayable = getTotalTaxPayable(grossSale, totalTax, variance);
  accountSummaryState['salesTax'] = {
    [ACCOUNT_FIELDS.ACCOUNT_NUMBER]:
      checkObjectNotEmpty(previousSalesTax) && checkValueNotNullUndefinedBlank(previousSalesTax?.accountNumber?.value)
        ? previousSalesTax?.accountNumber
        : {
            text: salesTaxAccount[0]?.accountNumber || '',
            value: Number(salesTaxAccount[0]?.id) || '',
          },
    [ACCOUNT_FIELDS.NAME]: checkObjectNotEmpty(previousSalesTax)
      ? String(previousSalesTax?.name)
      : salesTaxAccount[0]?.accountName || '',
    [ACCOUNT_FIELDS.DESCRIPTION]: checkObjectNotEmpty(previousSalesTax) ? String(previousSalesTax?.description) : '',
    [ACCOUNT_FIELDS.DEBIT]: roundOffValue(Number(taxPayable) || 0),
    [ACCOUNT_FIELDS.CREDIT]: roundOffValue(0),
  };

  if (sublocation?.calculateRent && checkValueNotNullUndefinedBlank(sublocation?.variableRent)) {
    let previousVariableRent =
      checkObjectNotEmpty(previousVariableFieldData) && checkObjectNotEmpty(previousVariableFieldData['variableRent'])
        ? previousVariableFieldData['variableRent']
        : {};
    let netSales = Number(grossSale) + Number(variance) - Number(taxPayable);
    const variableRentValue = roundOffValue(Number(sublocation?.variableRent) * Number(netSales));
    accountSummaryState['variableRent'] = {
      [ACCOUNT_FIELDS.ACCOUNT_NUMBER]:
        checkObjectNotEmpty(previousVariableRent) &&
        checkValueNotNullUndefinedBlank(previousVariableRent?.accountNumber?.value)
          ? previousVariableRent?.accountNumber
          : isArrayWithLength(variableRentAccount)
          ? {
              text: variableRentAccount[0]?.accountNumber || '',
              value: Number(variableRentAccount[0]?.id),
            }
          : null,
      [ACCOUNT_FIELDS.NAME]: checkObjectNotEmpty(previousVariableRent)
        ? previousVariableRent?.name
        : variableRentAccount[0]?.accountName || '',
      [ACCOUNT_FIELDS.DESCRIPTION]: checkObjectNotEmpty(previousVariableRent)
        ? String(previousVariableRent?.description)
        : '',
      [ACCOUNT_FIELDS.DEBIT]: roundOffValue(Number(variableRentValue) || 0),
      [ACCOUNT_FIELDS.CREDIT]: roundOffValue(0),
    };
  }
  return accountSummaryState;
};

export const getVariableFieldArray = (variableFields = {}) => {
  let arr = [];
  if (checkObjectNotEmpty(variableFields)) {
    for (let key in variableFields) {
      if (checkObjectNotEmpty(variableFields[key])) {
        arr.push({ ...variableFields[key] });
      }
    }
  }
  return arr;
};

export const getUpdatedVariableFieldsData = (variableSummaryFields, accountSummaryState) => {
  let keys = Object.keys(variableSummaryFields);
  let length = keys?.length;
  let updatedArray = accountSummaryState?.slice(2, length + 2);
  let temp = { ...variableSummaryFields };
  keys?.forEach((item, index) => {
    const fieldKey = keys[index];
    temp[fieldKey] = { ...updatedArray[index] };
  });
  return temp;
};

export const getRunningTotalValue = (accountSummaryState) => {
  const temp = [...accountSummaryState];
  let totalCredit = 0;
  let totalDebit = 0;
  temp?.map((item) => {
    totalDebit += Number(item?.debit);
    totalCredit += Number(item?.credit);
  });
  const total = roundOffValue(Number(totalCredit) - Number(totalDebit));
  return total;
};

export const getTotalSalesTax = (salesTax) => {
  let totalSalesTax = 0;
  if (checkObjectNotEmpty(salesTax)) {
    totalSalesTax = Number(salesTax?.areaTaxRate) + Number(salesTax?.stateTaxRate);
  }
  return totalSalesTax;
};

export const getSortedAccountSummaryForApprovedTab = (accountSummary) => {
  let arr = [...accountSummary];
  let sortedArr = [];
  if (isArrayWithLength(arr)) {
    let fixedFieldsData = [];
    let remainingFields = [];
    let grossSaleArr = arr?.filter((item) => item.name === ACCOUNT_TYPES.DEFAULT_GROSS_SALES);
    const refundsArr = arr?.filter((item) => item.name === ACCOUNT_TYPES.REFUNDS);
    const salesTaxArr = arr?.filter((item) => item.name === ACCOUNT_TYPES.SALES_TAX);
    const variableRentArr = arr?.filter((item) => item.name === ACCOUNT_TYPES.VARIABLE_RENT);
    if (!isArrayWithLength(grossSaleArr)) {
      grossSaleArr = arr?.filter((item) => item.name === ACCOUNT_TYPES.GROSS_SALES);
    }
    let creditCardArr = arr?.filter((item) => item?.name === ACCOUNT_TYPES.DEFAULT_CREDIT_CARD);
    const mergedArray = grossSaleArr?.concat(creditCardArr, refundsArr, salesTaxArr, variableRentArr);
    fixedFieldsData = [...mergedArray];
    const fixedFields = [
      ACCOUNT_TYPES.DEFAULT_GROSS_SALES,
      ACCOUNT_TYPES.REFUNDS,
      ACCOUNT_TYPES.SALES_TAX,
      ACCOUNT_TYPES.VARIABLE_RENT,
      ACCOUNT_TYPES.DEFAULT_CREDIT_CARD,
    ];
    remainingFields = arr?.filter((item) => !fixedFields?.includes(item?.name));
    sortedArr = fixedFieldsData?.concat(remainingFields);
  }
  return sortedArr;
};

export const getShowLocationPlaycardSummary = (playcards) => {
  let isPlaycardSummaryEmpty = false;
  if (playcards && checkObjectNotEmpty(playcards)) {
    Object.values(playcards)?.forEach((subloc) => {
      for (let field in subloc) {
        if (!['', null, undefined, 0].includes(subloc[field])) {
          isPlaycardSummaryEmpty = true;
        }
      }
    });
  }
  return isPlaycardSummaryEmpty;
};

export const getIntercardAndPlaycardDataSumForComparison = (intercardData = {}, playCardData) => {
  let sublocIntercardPlaycardTotal = {};
  const intercardArray = Object.values(intercardData);
  const subIds = [...new Set(intercardArray.map((item) => item?.sublocationId))];
  subIds?.forEach((item) => {
    const subId = item;
    const sublocationIntercardData = intercardArray?.filter((item) => String(item?.sublocationId) === String(subId));
    const playcardData = playCardData[subId];
    let intercardTotal = 0;
    let playcardTotal = Number(playcardData?.adjustedPlaycardSales);
    sublocationIntercardData?.forEach((item) => {
      item?.intercardData?.forEach((data) => {
        intercardTotal += Number(data?.cashDebits) + Number(data?.standardPlay);
      });
    });
    sublocIntercardPlaycardTotal[subId] = {
      intercardTotal: intercardTotal,
      playcardTotal: playcardTotal,
    };
  });
  return sublocIntercardPlaycardTotal;
};

export const getApprovedAssetSubtotalMap = (assets, abbreviation, exchangeRate) => {
  const subtotalMap = {};
  assets?.map((asset) => {
    const { assetMeters } = asset;
    const {
      subtotalCashRevenue,
      subtotalCreditRevenue,
      subtotalCreditRevenueInUSD,
      variance,
      subtotalCashCollectedRevenue,
      subtotalCashCollectedRevenueInUSD,
      subtotalCashRevenueInUSD,
    } = getApprovedSubtotals(assetMeters, exchangeRate);
    subtotalMap[asset?.id] = {
      subtotalCashRevenue,
      subtotalCreditRevenue,
      subtotalCreditRevenueInUSD,
      variance,
      subtotalCashCollectedRevenue,
    };
    if (abbreviation !== CURRENCY_CONSTANT.USD || (exchangeRate && exchangeRate !== DEFAULT_EXCHANGE_RATE)) {
      subtotalMap[asset?.id]['subtotalRevenueInUSD'] = subtotalCashRevenueInUSD + subtotalCreditRevenueInUSD;
      subtotalMap[asset?.id]['subtotalCashRevenueInUSD'] = subtotalCashRevenueInUSD;
      subtotalMap[asset?.id]['subtotalRevenueLocalCurrency'] = subtotalCashRevenue + subtotalCreditRevenue;
      subtotalMap[asset?.id]['subtotalCashCollectedRevenue'] = subtotalCashCollectedRevenue;
      subtotalMap[asset?.id]['subtotalCashCollectedRevenueInUSD'] = subtotalCashCollectedRevenueInUSD;
    } else {
      subtotalMap[asset?.id]['subtotalCashRevenueInUSD'] = subtotalCashRevenueInUSD;
      subtotalMap[asset?.id]['subtotalRevenueInUSD'] = subtotalCashRevenueInUSD + subtotalCreditRevenueInUSD;
      subtotalMap[asset?.id]['subtotalCashCollectedRevenueInUSD'] = subtotalCashCollectedRevenueInUSD;
    }
  });
  return subtotalMap;
};

export const getReportDataFromRawData = (rawReportData, asset, isReconciliation) => {
  let reportData = [];
  // if asset doesn't have noCollectionReason or the noCollectionReason is not pending, the report can be submitted in add-collection
  if (
    isArrayWithLength(rawReportData) &&
    !isReconciliation &&
    (!asset?.noCollectionReason || asset?.noCollectionReason?.status?.title !== MESSAGE.REPORT_STATUS_PENDING)
  ) {
    let assetReported = rawReportData?.filter((field) => field?.assetId === String(asset?.id));
    if (isArrayWithLength(assetReported) && assetReported[0]?.report?.issue) {
      reportData = [
        {
          noCollectReason: {
            collectionItemNoCollectIssueId: assetReported[0]?.report?.issue,
            reason: assetReported[0]?.report?.reason,
          },
        },
      ];
    }
  }

  return reportData;
};

export const getRevisedPlaycardValuesWithChangeInDateOfReading = (initialPlaycardState, prevPlaycardValues) => {
  let netCashTotal = 0;
  if (
    checkValueNotNullUndefinedBlank(initialPlaycardState?.netCashTotal) &&
    checkValueNotNullUndefinedBlank(prevPlaycardValues?.cashRefunds)
  ) {
    netCashTotal = initialPlaycardState?.netCashTotal - prevPlaycardValues?.cashRefunds;
  } else if (checkValueNotNullUndefinedBlank(initialPlaycardState?.netCashTotal)) {
    netCashTotal = initialPlaycardState?.netCashTotal;
  } else if (checkValueNotNullUndefinedBlank(prevPlaycardValues?.cashRefunds)) {
    netCashTotal = prevPlaycardValues?.cashRefunds;
  }

  const state = {
    ...initialPlaycardState,
    netCashTotal: netCashTotal || 0,
    adjustments: prevPlaycardValues?.adjustments || 0,
    cashRefunds: prevPlaycardValues?.cashRefunds || 0,
    bulkEncodedPlaycards: prevPlaycardValues?.bulkEncodedPlaycards || 0,
  };

  const adjustedPlaycardSales = getAdjustedPlaycardSales(state);
  const revisedState = {
    ...state,
    adjustedPlaycardSales: adjustedPlaycardSales || 0,
  };
  return revisedState;
};

export const getUpdatedARAccountDataWithSummary = (accountSummary) => {
  let accountSummaryData = [...accountSummary];

  let accountSummaryDataWithoutARAccount = accountSummaryData?.filter((item) =>
    NOT_AR_TYPES.find((el) => el?.toLowerCase() === String(item?.name)?.toLowerCase()),
  );
  let runningTotal = getRunningTotalValue(accountSummaryDataWithoutARAccount);
  let indexOfARAccount = '';
  accountSummaryData?.forEach((item, index) => {
    if (!NOT_AR_TYPES.find((el) => el?.toLowerCase() === String(item?.name)?.toLowerCase())) {
      indexOfARAccount = index;
    }
  });
  if (indexOfARAccount) {
    accountSummaryData[indexOfARAccount] =
      runningTotal > 0
        ? {
            ...accountSummaryData[indexOfARAccount],
            debit: roundOffValue(Math.abs(Number(runningTotal))),
            credit: roundOffValue(0),
          }
        : {
            ...accountSummaryData[indexOfARAccount],
            credit: roundOffValue(Math.abs(Number(runningTotal))),
            debit: roundOffValue(0),
          };
  }
  return accountSummaryData;
};

export const getTotalTaxPayable = (grossSale, totalTax, variance) => {
  const taxPayable = roundOffValue(
    ((Number(grossSale) + Number(variance)) / (1 + Number(totalTax))) * Number(totalTax),
  );
  return taxPayable;
};

export const getPlayCardSalesRevenueInUsd = (obj) => {
  const playCardObjWithSublocationKeyName = { ...obj };
  let totalPlayCardSales = 0;
  for (const key in playCardObjWithSublocationKeyName) {
    totalPlayCardSales += Number(playCardObjWithSublocationKeyName[key]?.adjustedPlaycardSales);
  }
  return totalPlayCardSales;
};

export const getUpdatedAccountSummaryWithChangeInSalesTaxGrossSale = (accountSummary, sublocation) => {
  let accountSummaryData = [...accountSummary];
  const taxExemptFlag = sublocation?.taxExemptFlag;
  let salesTaxRow = accountSummaryData?.filter((row) => row?.name === ACCOUNT_TYPES.SALES_TAX);
  let grossSale = accountSummaryData[0]?.credit || 0;
  // Old sales tax from previous calculation
  let salesTax = salesTaxRow[0]?.debit || 0;
  // Recalculate sales tax
  const totalSalesTaxRate = taxExemptFlag ? 0 : getTotalSalesTax(sublocation?.salesTax);
  const refundsAccount = accountSummaryData?.find((row) => String(row?.name) === ACCOUNT_TYPES.REFUNDS) || [];
  let variance = 0;
  if (refundsAccount) {
    const refundDebit = 0 - checkNumberIsNaNAndInfinity(refundsAccount?.debit);
    const refundCredit = checkNumberIsNaNAndInfinity(refundsAccount?.credit);
    variance = refundDebit + refundCredit;
  }
  const newSalesTax = getTotalTaxPayable(grossSale, totalSalesTaxRate, variance);
  const isSalesTaxUpdated = Number(newSalesTax) !== Number(salesTax);

  const netSales = isSalesTaxUpdated
    ? Number(grossSale) + Number(variance) - Number(newSalesTax)
    : Number(grossSale) + Number(variance) - Number(salesTax);
  const updatedRent = roundOffValue(Number(sublocation?.variableRent || 0) * Number(netSales));
  let rentIndex = '',
    saleTaxIndex = null;
  accountSummaryData?.forEach((item, index) => {
    if (item?.name === ACCOUNT_TYPES.VARIABLE_RENT) {
      rentIndex = index;
    }
    if (item?.name === ACCOUNT_TYPES.SALES_TAX) {
      saleTaxIndex = index;
    }
  });
  if (rentIndex) {
    accountSummaryData[rentIndex] = { ...accountSummaryData[rentIndex], debit: updatedRent };
  }
  if (saleTaxIndex && isSalesTaxUpdated && Number(newSalesTax) > 0) {
    accountSummaryData[saleTaxIndex] = { ...accountSummaryData[saleTaxIndex], debit: newSalesTax, credit: 0 };
  } else if (saleTaxIndex && isSalesTaxUpdated && Number(newSalesTax) < 0) {
    accountSummaryData[saleTaxIndex] = { ...accountSummaryData[saleTaxIndex], credit: newSalesTax, debit: 0 };
  }
  return accountSummaryData;
};

export const getPlayCardApiSchemaData = (playCard) => {
  if (playCard) {
    return {
      cashPlaycardSales: playCard?.cashPlaycardSales,
      salesFromCreditCard: playCard?.salesFromCreditCard,
      creditCardRefunds: playCard?.creditCardRefunds,
      complimentaryPlaycards: playCard?.complimentaryPlaycards,
    };
  }
};

export const updateFormikWithStoredData = (formikData, states, asset, isReconciliation = false) => {
  if (formikData && Object.values(formikData).length > 0) {
    const filteredFormikData = formikData.filter(
      (data) =>
        String(data?.assetId) === String(asset?.id) &&
        !EXCLUDING_FIELD_NAMES_FOR_INITIALIZE.filter(
          (item) => item !== FIELD_NAME.SEEDLIVE_REVENUE && isReconciliation,
        ).includes(data?.fieldName) &&
        !Object.keys(data)?.includes('comment') &&
        !(Object.keys(data)?.includes('report') && data?.report?.issue),
    );
    const independentRepData = formikData.filter((data) => data?.fieldName?.includes('independent'));

    const commentData = formikData.filter((data) => Object.keys(data)?.includes('comment'));
    const reportData = formikData.filter((data) => Object.keys(data)?.includes('report') && data?.report?.issue);

    if (filteredFormikData?.length > 0) {
      filteredFormikData.forEach((el) => {
        const name = getFieldName(
          el?.meterName,
          el?.fieldName,
          el?.sublocationId,
          el?.assetId,
          el?.meterId,
          el?.assetMeterId,
        );
        states[name] = el?.fieldValue;
      });
    }
    if (commentData?.length > 0) {
      commentData.forEach((el) => {
        const name = getTypeFieldName('comment', el?.sublocationId, el?.assetId);
        states[name] = el?.comment;
      });
    }
    if (reportData?.length > 0) {
      reportData.forEach((el) => {
        const name = getTypeFieldName('report', el?.sublocationId, el?.assetId);
        states[name] = el?.report;
      });
    }
    if (independentRepData?.length > 0) {
      independentRepData.forEach((el) => {
        const name = `${el?.fieldName}_${el?.sublocationId}`;
        states[name] = el?.fieldValue;
      });
    }
  }
  return states;
};

export const getConditionToRecalculateRentVariable = (summaryRowAccountName, fieldName) => {
  const condition1 =
    [ACCOUNT_TYPES.GROSS_SALES, ACCOUNT_TYPES.DEFAULT_GROSS_SALES].includes(summaryRowAccountName) &&
    fieldName === ACCOUNT_FIELDS.CREDIT;
  const condition2 = summaryRowAccountName === ACCOUNT_TYPES.SALES_TAX && fieldName === ACCOUNT_FIELDS.DEBIT;
  const condition3 = summaryRowAccountName === ACCOUNT_TYPES.REFUNDS;
  return condition1 || condition2 || condition3;
};

export const getSublocationDates = (subloc) => {
  const sublocLastCollectionDate = subloc?.collections?.[0]?.dateOfReading;
  const finalSubLocLastCollectionDate = sublocLastCollectionDate ? dateFormatForApi(sublocLastCollectionDate) : null;
  const sublocStartDate = getDateWithoutConversion(subloc?.startDate);
  const finalStartDate = sublocStartDate ? dateFormatForApi(sublocStartDate) : null;
  const legacyId = subloc?.legacysublocId || null;
  return {
    lastApprovedCollectionDate: finalSubLocLastCollectionDate,
    startDate: finalStartDate,
    legacysublocId: legacyId,
  };
};

export const getVisibleRows = (fieldName, isTaxPayable) =>
  (fieldName === ACCOUNT_TYPES.SALES_TAX && isTaxPayable) || ![ACCOUNT_TYPES.SALES_TAX]?.includes(fieldName);

export const getInitialIndependentRepFieldValueFromSublocations = (subLocations) => {
  const states = {};
  let schema = {};
  subLocations?.forEach((sublocation) => {
    const singleSchema = getIndependentRepSchema(sublocation.id);
    schema = { ...schema, ...singleSchema };

    let textName =
      sublocation.independentRep?.firstName && sublocation.independentRep?.lastName
        ? `${sublocation.independentRep?.firstName} ${sublocation.independentRep?.lastName}`
        : '';
    states[`${INDEPENDENT_REP_OF_SUBLOCATION.REP_ID}_${sublocation?.id}`] = {
      text: textName,
      value: sublocation[`${INDEPENDENT_REP_OF_SUBLOCATION.REP_ID}_${sublocation?.id}`],
    };
    states[`${INDEPENDENT_REP_OF_SUBLOCATION.PAY_CODE}_${sublocation?.id}`] =
      sublocation[`${INDEPENDENT_REP_OF_SUBLOCATION.PAY_CODE}_${sublocation?.id}`];
    states[`${INDEPENDENT_REP_OF_SUBLOCATION.FEE}_${sublocation?.id}`] =
      sublocation[`${INDEPENDENT_REP_OF_SUBLOCATION.FEE}_${sublocation?.id}`];
    states[`${INDEPENDENT_REP_OF_SUBLOCATION.RATE}_${sublocation?.id}`] =
      sublocation[`${INDEPENDENT_REP_OF_SUBLOCATION.RATE}_${sublocation?.id}`];
  });
  return { states, schema };
};

/**
 * This function returns the check fields for the meter type
 *
 * @param {string} meterName
 * @returns
 */
export const getCheckFieldsByMeterType = (meterName) => {
  switch (meterName) {
    case METER_TYPE.BILL:
      return CHECK_FIELDS.BILL_FIELDS;
    case METER_TYPE.COIN:
      return CHECK_FIELDS.COINS_CHECK_FIELDS;
    case METER_TYPE.TOKEN_CHANGER:
      return CHECK_FIELDS.TOKEN_CHANGER_CHECK_FIELDS;
    case METER_TYPE.TOKEN:
      return CHECK_FIELDS.TOKEN_CHECK_FIELDS;
    case METER_TYPE.PRIZE:
      return CHECK_FIELDS.PRIZE_CHECK_FIELDS;
    case METER_TYPE.TICKET:
      return CHECK_FIELDS.TICKET_CHECK_FIELDS;
    case METER_TYPE.CASH:
      return CHECK_FIELDS.CASH_CHECK_FIELDS;
    case METER_TYPE.CREDIT:
      return CHECK_FIELDS.CREDIT_CHECK_FIELDS;
    case METER_TYPE.MEDALLION:
      return CHECK_FIELDS.MEDALLION_CHECK_FIELDS;
    case METER_TYPE.CANDY:
      return CHECK_FIELDS.CANDY_CHECK_FIELDS;
    case METER_TYPE.IMPULSE_READER:
      return CHECK_FIELDS.IMPULSE_READER_CHECK_FIELDS;
    default:
      return [];
  }
};

/**
 * This function checks if the ExpectedData asset meter data has any value
 *
 * @param {Array} currentExpectedData
 * @returns boolean
 */
export const hasAssetMeterData = (currentExpectedData) => {
  let hasData = false;
  if (isArrayWithLength(currentExpectedData)) {
    for (let i = 0; i < currentExpectedData.length; i++) {
      const el = currentExpectedData[i];
      const fields = getCheckFieldsByMeterType(el?.meterName);
      const fieldsValues = fields?.map((field) => el[field]) ?? [];
      if (!hasData) {
        hasData = fieldsValues.every((v) => v !== '' && v !== 0 && v !== null && v !== undefined);
      }
      if (hasData) {
        break;
      }
    }
  }

  return hasData;
};
