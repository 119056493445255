import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { apiUrl } from '../../utils/api';
import { apiBasePath } from '../baseUrl';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getGlobalAccountNumber: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;
          return `${apiUrl.globalAccountNumber}?perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&${searchParams}&sort=id:ASC`;
        }
        return `${apiUrl.globalAccountNumber}`;
      },
    }),
    updateUserAccountNumber: build.mutation({
      query: ({ id, data }) => ({
        url: `${apiUrl.accountNumber}/update-user-account-number/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetGlobalAccountNumberQuery, useUpdateUserAccountNumberMutation } = extendedApi;
