const apiUrl = {
  profile: 'profile',
  userList: 'user',
  userListForSelect: 'user/select',
  updateGraphUsers: 'update-graph-users',
  userRole: 'role',
  location: 'location',
  sublocation: 'sublocation',
  sublocations: 'sublocations',
  ownerInfo: 'ownerinfo',
  uiInfo: 'ui/menuRoles',
  countries: 'countries',
  states: 'states',
  cities: 'cities',
  owner: 'owners',
  locationType: 'types',
  areaTaxLocation: 'area-tax/locations',
  modem: 'modems',
  model: 'models',
  modelCategory: 'model-categories',
  modelTheme: 'model-themes',
  modelManufacturer: 'model-manufacturers',
  machine: 'assets',
  machineType: 'asset-types',
  machineStatus: 'asset-statuses',
  collections: 'collections',
  reconciliation: 'reconciliation',
  meters: 'meters',
  sublocationbyIR: 'sublocations-by-independent-rep',
  locationbyIR: 'locations-by-independent-rep',
  machineMeters: 'asset-meters',
  deposits: 'deposits',
  modelType: 'model-types',
  gameCategories: 'game-categories',
  currencies: 'currencies',
  locationOwnerTypes: 'owner-types',
  lease: 'contracts',
  validateSubLocationsPeriod: 'validate-sublocations-period',
  exchangeRate: 'exchange-rate',
  productCategories: 'product-categories',
  intercard: 'intercard',
  seedlive: 'seedlive',
  x3Departments: 'x3_departments',
  x3Industries: 'x3_industries',
  x3Owners: 'x3_owners',
  trackings: 'trackings',
  history: 'history',
  accountNumber: 'account-numbers',
  globalAccountNumber: 'global-account-numbers',
};

export { apiUrl };
