import React from 'react';
import { Autocomplete, Button, GridContainer, GridItem, Modal, TextField } from '../../../components/shared';
import { Box, Grid } from '@mui/material';
import { MESSAGE } from '../../../constants/message';
import { useGetGlobalAccountNumberQuery } from '../../../services/AccountNumber/AccountNumberService';
import { useDebounce } from '../../../hooks';

const EditUserGLNumberModal = (props) => {
  const { onClose = () => {}, onConfirm = () => {}, selectedSublocation, employeeDetails = {} } = props;
  const [showGLModal, setShowGLModal] = React.useState(false);
  const [sublocations, setSublocations] = React.useState([]);
  const { firstName, lastName } = employeeDetails || {};
  const [GLNumberSearchText, setGLNumberSearchText] = React.useState('');
  const debouncedGLNumberSearchText = useDebounce(GLNumberSearchText, 300);

  const { currentData, isSuccess, isFetching } = useGetGlobalAccountNumberQuery({
    page: 1,
    perPage: 100,
    searchParams: `q=${debouncedGLNumberSearchText}`,
  });

  const accountNumbers = React.useMemo(() => {
    if (isSuccess) {
      return (
        currentData?.data?.globalAccountNumber?.map((value) => ({
          ...value,
          id: value?.accountNumber?.trim(),
          name: value?.accountNumber?.trim(),
          text: value?.accountNumber?.trim(),
          value: value?.accountNumber?.trim(),
        })) ?? []
      );
    }
  }, [isFetching, isSuccess]);

  React.useEffect(() => {
    if (selectedSublocation && selectedSublocation.length > 0) {
      setShowGLModal(true);
      setSublocations(selectedSublocation);
    }
  }, [selectedSublocation]);

  const handleClose = () => {
    onClose();
    setShowGLModal(false);
  };

  const handleConfirm = () => {
    onConfirm(sublocations);
    setShowGLModal(false);
  };

  const handleGLNumberChange = (e) => {
    const { name, value } = e;
    const [field, index] = name.split('_');
    const tempSublocations = [...sublocations];
    tempSublocations[index][field] = value;
    setSublocations(tempSublocations);
    setGLNumberSearchText('');
  };

  // TODO: Add compare logic for prev vs new
  const disableConfirm = false;

  return (
    <Modal
      open={showGLModal}
      onClose={handleClose}
      title={`Edit GL for ${firstName} ${lastName}`}
      titleVariant="subtitle10"
      actionButtons={
        <Box my={3}>
          <Button variant="contained" size="large" onClick={handleConfirm} disabled={disableConfirm}>
            {MESSAGE.CONFIRM}
          </Button>
          <Button size="large" onClick={handleClose} variant={'text'}>
            {MESSAGE.CANCEL}
          </Button>
        </Box>
      }
      maxWidth={'sm'}
    >
      {sublocations?.map((sublocation, index) => {
        let { id, name, accountNumber } = sublocation;

        return (
          <Grid key={id}>
            <div>{name}:</div>
            <GridContainer rowSpacing={2} gap={2}>
              <GridItem xs={3}>
                <Autocomplete
                  label="GL Number"
                  name={`accountNumber_${index}`}
                  value={accountNumber || null}
                  options={accountNumbers || []}
                  getOptionLabel={(option) => option.text || ''}
                  onChange={(_, value) => handleGLNumberChange({ name: `accountNumber_${index}`, value })}
                  onInputChange={(_, value) => setGLNumberSearchText(value)}
                />
              </GridItem>
              <GridItem xs={3}>
                <TextField label="GL Name" value={accountNumber?.accountName || ''} disabled />
              </GridItem>
              <GridItem xs={3}>
                <TextField label="X3 Number" value={accountNumber?.X3_accountNumber || ''} disabled />
              </GridItem>
            </GridContainer>
          </Grid>
        );
      })}
    </Modal>
  );
};

export default EditUserGLNumberModal;
