import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: { padding: 0 },
  breadcrumbContainer: { padding: 0 },
  machineDetailContent: {
    '& .MuiSvgIcon-root': {
      '&:hover': { color: theme.palette.primary },
    },
    '& .MuiAccordionSummary-root': { minHeight: '72px' },
    '& .MuiAccordionDetails-root': { padding: '8px 0px' },
    '& .MuiGrid2-root MuiGrid2-container': { padding: '8px 24px 0px 24px' },
  },
  modemSearch: {
    padding: '16px 24px 24px 24px',
    '& .MuiSvgIcon-root': { color: theme?.palette?.primary?.main },
    '& .MuiFormControl-root': { borderRadius: '8px' },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme?.palette?.primary?.main,
    },
  },
  assignNewModemTitle: { padding: '31px 0px 0px 24px' },
  btnWrap: { padding: '52px 0px 40px 24px' },
  modemDetailContent: {
    '& .MuiAccordionDetails-root': { padding: '8px 0px' },
    '& .MuiGrid2-root MuiGrid2-container': { padding: '8px 24px 0px 24px' },
    '& .MuiAccordionSummary-root': { minHeight: '72px' },
  },
  EditModemDetail: {
    '& .MuiAccordionDetails-root': { paddingTop: 16 },
    '& .MuiAccordionSummary-root': { minHeight: '72px' },
  },
  modemIcon: {
    width: '2.5em',
    height: '2.5em',
    color: theme?.palette?.primary?.main,
  },
  modemSearchCheckBox: { margin: '0px 12px 0px -12px' },
  addMeterTable: {
    '& .MuiTableContainer-root': {
      marginTop: '24px',
    },
    '& .MuiSvgIcon-root': { color: theme?.palette?.grey[500] },
    '& .MuiFormControl-root': { padding: '16px 12px 24px 24px' },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme?.palette?.grey[100],
      borderRadius: '8px',
    },
    '& .MuiTableCell-root MuiTableCell-head': { width: '50%' },
    '& .MuiTableCell-head': { whiteSpace: 'noWrap' },
  },
  addBtn: {
    padding: '8px 24px 16px 12px',
  },
  meterTableHeading: { padding: '24px 24px 0px 24px' },
  addMeterHead: {
    borderBottom: `1px solid ${theme?.palette?.grey[900]}`,
    background: theme?.palette?.common?.white,
    minHeight: 76,
    paddingLeft: 24,
  },
  addMeterBtn: { padding: '52px 24px 16px 8px' },
  meterCardContent: {
    minHeight: '220px',
    '& .MuiAutocomplete-listbox': {
      maxHeight: '140px',
      overflow: 'auto',
      '& li': { padding: 10 },
    },
  },
  btn: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '46px 8px 16px 8px',
  },
  errorButton: { padding: '12px 0px 12px 0px' },
  meterNameColumn: { color: theme?.palette?.common?.black, width: '200px' },
  archive: { color: theme?.palette?.grey[500] },
  prizeMeterField: {
    width: '100%',
    padding: 0,
  },
  noContent: {
    backgroundColor: theme?.palette?.common?.white,
    boxShadow: `0px 1px 4px ${theme?.palette?.grey[300]}`,
    borderRadius: 8,
  },
  assignModemTableWrapper: {
    '& td[class*=MuiTableCell-body]': {
      '&:nth-child(1)': {
        width: 30,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  error: {
    color: 'red',
  },
}));

export default useStyles;
