import React, { useEffect, useState } from 'react';
import useStyles from '../style';
import { Button } from '@mui/material';
import { GridContainer, GridItem, Autocomplete } from '../../../components/shared';
import { MESSAGE } from '../../../constants/message';
import { useFormik } from 'formik';
import { addMissingAssetsSchema } from '../../../schema/validationSchemas';
import ConfirmationModal from '../../../components/ConfirmationModel/ConfirmationModal';
import { useUpdateMissingAssetsByTransactionIdMutation } from '../../../services/CollectionService/CollectionService';
import { useNavigate, useParams } from 'react-router-dom';

const AddMissingAssets = ({ sublocationId, missingAssets }) => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [showAddMissingForm, setShowAddMissingForm] = useState(false);
  const [showAddMissingModal, setShowAddMissingModal] = useState(false);
  const [missingPropAssets, setMissingPropAssets] = useState([]);
  const [userSelectedMissingAssets, setUserSelectedMissingAssets] = useState({});

  useEffect(() => {
    setMissingPropAssets(
      missingAssets.map((item) => ({
        id: item.id,
        name: `${item.id} (${item.legacyAssetId})`,
      })),
    );
  }, [missingAssets]);

  const formik = useFormik({
    initialValues: {
      assetIdsArr: [],
    },
    validationSchema: addMissingAssetsSchema,
    onSubmit: (values) => {
      setShowAddMissingModal(true);
      const updateValues = {
        assetIds: values.assetIdsArr.map((item) => item.id),
      };
      setUserSelectedMissingAssets(updateValues);
    },
  });

  const onMissingAssetsSelect = (columnName, newValue) => {
    formik.setFieldValue('assetIdsArr', newValue);
  };

  const onAddMissingAssetsModalClickHandler = (value) => {
    if (value === MESSAGE.SUBMIT) {
      AddMissingAssetsHandler();
    } else {
      setShowAddMissingModal(false);
      setShowAddMissingForm(false);
    }
  };

  const [updateMissingAssetsApi] = useUpdateMissingAssetsByTransactionIdMutation();

  const AddMissingAssetsHandler = () => {
    updateMissingAssetsApi({
      transactionId: params.id,
      sublocationId,
      ...userSelectedMissingAssets,
    }).then((response) => {
      if (response?.data?.data) {
        setShowAddMissingModal(false);
        // refreshing same page.
        navigate(0);
      }
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <GridContainer className={classes.addMissingAssetsContainer} spacing={1}>
        {!showAddMissingForm && (
          <GridItem xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                setShowAddMissingForm(true);
              }}
            >
              Add Missing Assets
            </Button>
          </GridItem>
        )}
        {showAddMissingForm && (
          <React.Fragment>
            <GridItem xs={12} md={2}>
              <Autocomplete
                error={formik.touched.assetIdsArr && formik.errors.assetIdsArr ? true : false}
                helperText={formik.touched.assetIdsArr && formik.errors.assetIdsArr ? formik.errors.assetIdsArr : ''}
                multiple={true}
                disableCloseOnSelect
                options={missingPropAssets}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={formik.values.missingAssets}
                label={'Missing Assets'}
                onChange={(_, newValue) => onMissingAssetsSelect('missingAssets', newValue)}
                onBlur={() => formik.setFieldTouched('missingAssets')}
              />
            </GridItem>
            <GridItem xs={12} md={10}>
              <Button variant="contained" type="submit">
                {MESSAGE.SUBMIT}
              </Button>
              <Button
                onClick={() => {
                  setShowAddMissingForm(false);
                }}
              >
                {MESSAGE.CANCEL}
              </Button>
            </GridItem>
          </React.Fragment>
        )}
      </GridContainer>
      <ConfirmationModal
        isOpen={showAddMissingModal}
        title={MESSAGE.CONFIRMATION}
        msg={MESSAGE.ADD_MISSING_ASSETS_MESSAGE}
        buttons={[
          { text: MESSAGE.SUBMIT, value: MESSAGE.SUBMIT },
          { text: MESSAGE.CANCEL, value: MESSAGE.CANCEL },
        ]}
        onClick={onAddMissingAssetsModalClickHandler}
      />
    </form>
  );
};

export default AddMissingAssets;
