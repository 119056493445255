import { apiBasePath } from '../baseUrl';
import { apiUrl } from '../../utils/api';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    seedliveProcess: build.query({
      query: (args) => {
        if (args) {
          const { page = 1, perPage = 10, searchParams = '' } = args;
          return `${apiUrl.seedlive}?perPage=${perPage}&page=${page}&${searchParams}`;
        }
        return `${apiUrl.seedlive}`;
      },
    }),
  }),
});

export const { useSeedliveProcessQuery, useLazySeedliveProcessQuery } = extendedApi;
