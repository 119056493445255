import React, { useState } from 'react';
import { Button, GridContainer, GridItem, Modal, PercentTextField } from '../../components/shared';
import { Box, Grid } from '@mui/material';
import { MESSAGE } from '../../constants/message';
import useStyles from './style';

const EditStateTaxRateModal = (props) => {
  const styles = useStyles();
  const { onClose = () => {}, onConfirm = () => {}, rowData, isOpen } = props;

  const [taxrRate, setTaxRate] = useState('');
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm(taxrRate);
  };
  const handleRateChange = (value) => {
    setTaxRate(value);
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      title={`Edit Rates for ${rowData?.name}`}
      titleVariant="subtitle10"
      actionButtons={
        <Box my={3}>
          <Button variant="contained" size="large" onClick={handleConfirm} disabled={taxrRate.value === 0}>
            {MESSAGE.CONFIRM}
          </Button>
          <Button size="large" onClick={handleClose} variant={'text'}>
            {MESSAGE.CANCEL}
          </Button>
        </Box>
      }
      maxWidth={'xs'}
    >
      <Grid>
        <GridContainer rowSpacing={2} gap={2}>
          <GridItem>
            <PercentTextField
              label={'Tax Rate'}
              value={rowData?.stateTaxRate}
              name={rowData?.id.toString()}
              allowDecimal
              onChange={handleRateChange}
            />
          </GridItem>
          <GridItem>
            <div className={styles.disclaimerStyle}>
              <em>
                This action will override the current tax rate for all sublocations within this state. Please proceed
                with caution.
              </em>
            </div>
          </GridItem>
        </GridContainer>
      </Grid>
    </Modal>
  );
};

export default EditStateTaxRateModal;
