import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';
import { apiUrl } from '../../utils/api';
import { apiBasePath } from '../baseUrl';

export const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getAllCountries: build.query({
      query: () => apiUrl.countries,
      transformResponse: (response) => response.data.countries,
    }),
    getCountryById: build.query({
      query: (id) => `${apiUrl.countries}/${id}`,
    }),
    getCountryStatesByCountryId: build.query({
      query: (id) => `${apiUrl.countries}/${id}/${apiUrl.states}`,
    }),
    getAllStates: build.query({
      query: (args) => {
        if (args) {
          const { page, perPage, searchParams = '' } = args;

          return `${apiUrl.states}?&perPage=${perPage || DEFAULT_PER_PAGE}&page=${
            page || DEFAULT_PAGE
          }&q=${searchParams}`;
        }
      },
      transformResponse: (response) => response,
    }),
    getStateById: build.query({
      query: (id) => `${apiUrl.states}/${id}`,
      transformResponse: (response) => response.data,
    }),
    getStateCitiesByStateId: build.query({
      query: (id) => `${apiUrl.states}/${id}/${apiUrl.cities}`,
    }),
    getAllCities: build.query({
      query: () => apiUrl.cities,
      transformResponse: (response) => response.data?.cities,
    }),
    getCityById: build.query({
      query: (id) => `${apiUrl.cities}/${id}`,
      transformResponse: (response) => response.data,
    }),
    updateState: build.mutation({
      query: (data) => ({
        url: `${apiUrl.states}/${data.id}`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllCountriesQuery,
  useLazyGetAllCountriesQuery,
  useGetCountryByIdQuery,
  useGetCountryStatesByCountryIdQuery,
  useLazyGetCountryStatesByCountryIdQuery,
  useGetAllStatesQuery,
  useGetStateByIdQuery,
  useGetStateCitiesByStateIdQuery,
  useLazyGetStateCitiesByStateIdQuery,
  useGetAllCitiesQuery,
  useGetCityByIdQuery,
  useUpdateStateMutation,
} = extendedApi;
