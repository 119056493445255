import React from 'react';
import { Button, Datepicker, Modal } from '../../../components/shared';
import { Box } from '@mui/material';
import { MESSAGE } from '../../../constants/message';

const BulkUpdateDateEnteredModal = (props) => {
  const { showApproveModal, onApproveButtonPress, setShowApproveModal = () => {}, selectedCollection = [] } = props;
  const [updatedDateEntered, setUpdatedDateEntered] = React.useState(null);

  const { retailCollections, gameRoomCollections } = React.useMemo(() => {
    let retailCollections = 0,
      gameRoomCollections = 0;

    Object.values(selectedCollection).map((collection) => {
      const { selected = false, productCategories = [] } = collection;
      if (selected) {
        productCategories.map((category) => {
          if (['GAME ROOM', 'ARCADE'].includes(category.name)) {
            gameRoomCollections++;
          } else {
            retailCollections++;
          }
        });
      }
    });

    return { retailCollections, gameRoomCollections };
  }, [showApproveModal]);

  React.useEffect(() => {
    if (showApproveModal) setUpdatedDateEntered(null);
  }, [showApproveModal]);

  return (
    <Modal
      title={'Bulk Update Date Entered'}
      titleVariant="subtitle10"
      maxWidth={'xs'}
      open={showApproveModal}
      actionButtons={
        <Box my={3}>
          <Button
            variant="contained"
            size="large"
            disabled={!updatedDateEntered}
            onClick={() => onApproveButtonPress(updatedDateEntered)}
          >
            {'Submit'}
          </Button>
          <Button size="large" onClick={() => setShowApproveModal(false)}>
            {MESSAGE.CANCEL}
          </Button>
        </Box>
      }
      children={
        <Box>
          <>{MESSAGE.UPDATE_COLLECTION_DATE_ENTERED}</>
          <ul className="bullet-list" style={{ margin: '10px 10px', paddingLeft: '20px' }}>
            {retailCollections > 0 && (
              <li>
                <strong>Retail Collections</strong> - {retailCollections}
              </li>
            )}
            {gameRoomCollections > 0 && (
              <li>
                <strong>Game Room/Arcade Collections</strong> - {gameRoomCollections}
              </li>
            )}
          </ul>
          <Datepicker
            label="Updated Date Entered"
            name="dateEntered"
            value={updatedDateEntered}
            inputFormat="MM/DD/YYYY"
            onChange={(date) => setUpdatedDateEntered(date)}
          />
        </Box>
      }
    />
  );
};

export default BulkUpdateDateEnteredModal;
