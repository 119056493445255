import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridContainer, GridItem, Button } from '../index';
import { Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DrawerConstant } from '../../../constants/DrawerConstants';
import useStyles from './style';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import SortContent from './SortContent';
import FilterContent from './FilterContent';

export default function NewFilterDrawer(props) {
  const { DRAWER_TYPES, DIRECTION } = FILTER_CONSTANTS;
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const width = matches ? DrawerConstant.FILTER_DRAWER_MD_UP : DrawerConstant.FILTER_DRAWER_MD_DOWN;
  const classes = useStyles();

  const {
    name = '',
    title = '',
    filteredValues = {},
    onApplyFilter = () => {},
    onReset,
    drawerType = DRAWER_TYPES.FILTER_DRAWER,
    startIcon = <FilterAltOutlinedIcon />,
    onSortSelect,
    selectedSort,
    sortData,
    filterData: initialFilterData,
    getStatesBasedOnCountryId = () => {},
    getCitiesBasedOnStateId = () => {},
    emptyFilterLength,
  } = props;
  const [filter, setFilter] = useState(false);
  const [filterParams, setFilterParams] = useState(filteredValues);
  const [startIconParam, setStartIconParam] = useState(startIcon);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [filterData, setFilterData] = useState(initialFilterData);

  useEffect(() => {
    if (filter) {
      if (Object.keys(filterParams).includes(FILTER_VALUES.SEEDLIVE_MISSING_DATES)) {
        const { from, to } = filterParams[FILTER_VALUES.DATE_RANGE];
        const updatedFilterData = filterData.map((item) => {
          if (item.value === FILTER_VALUES.SEEDLIVE_MISSING_DATES) {
            const result = {
              ...item,
              disabled: !from || !to,
            };

            return result;
          }
          return item;
        });
        setFilterData(updatedFilterData);
      }
    }
  }, [filter]);

  useEffect(() => {
    if (JSON.stringify(filteredValues) !== JSON.stringify(filterParams)) setFilterParams(filteredValues);
    if (JSON.stringify(filteredValues)?.length > emptyFilterLength) {
      setStartIconParam(<FilterAltIcon />);
      setFilterApplied(true);
    }
  }, [filteredValues]);

  const toggleDrawer =
    (open, apply = false, reset = false) =>
    (event) => {
      if (apply && filterParams && Object.keys(filterParams) && !open) {
        onApplyFilter(filterParams);
      }
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      if (reset) {
        onReset();
        setFilterData(initialFilterData);
        setFilterParams({});
        setStartIconParam(<FilterAltOutlinedIcon />);
        setFilterApplied(false);
      }

      setFilter(open);
    };

  const selectChipsHandler = (columnName, v) => {
    setFilterParams((prev) => {
      const newObject = { ...prev };
      newObject[columnName] = v;
      if (columnName === FILTER_VALUES.COUNTRY) {
        newObject[FILTER_VALUES.STATE] = null;
        newObject[FILTER_VALUES.CITY] = [];
        getStatesBasedOnCountryId(v);
      } else if (columnName === FILTER_VALUES.STATE) {
        newObject[FILTER_VALUES.CITY] = [];
        getCitiesBasedOnStateId(v);
      } else if (columnName === FILTER_VALUES.DATE_RANGE) {
        if (FILTER_VALUES.SEEDLIVE_MISSING_DATES in prev) {
          const updatedFilterData = filterData.map((item) => {
            if (item.value === FILTER_VALUES.SEEDLIVE_MISSING_DATES) {
              const { from, to } = v || {};
              const result = {
                ...item,
                disabled: !from || !to,
              };
              if (result.disabled) newObject[FILTER_VALUES.SEEDLIVE_MISSING_DATES] = false;
              return result;
            }
            return item;
          });
          setFilterData(updatedFilterData);
        }
      }
      return newObject;
    });
  };

  const handleSort = (e) => {
    onSortSelect(e);
    toggleDrawer(false)(e);
  };

  const renderSortComponent = () => <SortContent selectedSort={selectedSort} data={sortData} handleSort={handleSort} />;
  const renderFilterComponent = () => (
    <FilterContent data={filterData} filterParams={filterParams} onChipChange={selectChipsHandler} />
  );

  const renderSubComponent = () => {
    switch (drawerType) {
      case DRAWER_TYPES.SORT_DRAWER:
        return renderSortComponent();
      case DRAWER_TYPES.FILTER_DRAWER:
        return renderFilterComponent();

      default:
        return;
    }
  };

  useEffect(() => {
    if (!filter) {
      setFilterParams(filteredValues);
    }
  }, [filter]);

  const subComponent = () => (
    <Box sx={{ width: width }} role="presentation">
      <GridContainer direction="column" className={classes.filterContainer} spacing={2}>
        <GridContainer p={2} pr={3}>
          <GridItem xs={8} md={10} display="flex" justifyContent="start" alignItems="center">
            <Typography role="presentation" variant="h1" data-testid="title">
              {title}
            </Typography>
          </GridItem>
          <GridItem xs={4} md={2} display="flex" justifyContent="end" alignItems="center">
            <IconButton onClick={toggleDrawer(false)} data-testid="closeBtn">
              <CloseIcon />
            </IconButton>
          </GridItem>
        </GridContainer>
        <GridContainer direction="column" className={classes.filterContainerList}>
          {renderSubComponent()}
        </GridContainer>
        {drawerType !== DRAWER_TYPES.SORT_DRAWER && (
          <GridContainer px={2} className={classes.filterBtnWrap}>
            <GridItem xs={6}>
              <Button variant="contained" onClick={toggleDrawer(false, true)} data-testid="apply-filter" size="small">
                APPLY FILTERS
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button onClick={toggleDrawer(false, false, true)} data-testid="clear-all" size="small">
                CLEAR ALL
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </GridContainer>
    </Box>
  );

  return (
    <Box>
      <Box>
        <Box component="div" sx={{ display: 'none' }} data-testid="drawer-status">
          {String(filter)}
        </Box>
        <Button
          className={classes.iconBtn}
          onClick={toggleDrawer(true)}
          startIcon={startIconParam}
          variant={isFilterApplied ? 'contained' : 'outlined'}
          data-testid="filter-btn"
          size="large"
        >
          <Typography variant="buttonText" noWrap className={classes.filterName}>
            {name}
          </Typography>
        </Button>
        <Drawer anchor={DIRECTION.RIGHT} open={filter} onClose={toggleDrawer(false)}>
          {subComponent()}
        </Drawer>
      </Box>
    </Box>
  );
}
