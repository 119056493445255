import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../constants/pagination';

const initialState = {
  states: {
    searchText: '',
    pageNumber: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_PER_PAGE,
  },
};
export const statesFilterSlice = createSlice({
  name: 'statesFilter',
  initialState,
  reducers: {
    setStateFilters(state, action) {
      state.states.searchText = action.payload?.searchText || '';
      state.states.pageNumber = action.payload?.pageNumber || DEFAULT_PAGE;
      state.states.rowsPerPage = action.payload?.rowsPerPage || DEFAULT_PER_PAGE;
    },
    resetStateFiltersState(state) {
      state.states = { ...initialState.states, searchText: state.states.searchText };
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
});

export default statesFilterSlice;
