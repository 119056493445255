import React, { useEffect, useState } from 'react';
import SublocationMachinesForm from '../AddCollection/SublocationMachinesForm';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTotalTokenCollected,
  setTotalTicketDispensed,
  setTotalPrizeDispensed,
  setTransformedSublocations,
  setSubTotalRevenues,
  setTotalTokenChangerRevenueValue,
  setRevenueMeterData,
  setTotalTokenDispensed,
  setFormikData,
} from '../../../redux-slice/reconciliationCollection';
import {
  getInitialIntercardState,
  getRevenueInLocalCurrency,
  getSubTotalsPerAsset,
  getTotalPrizeDispensed,
  getTotalTicketDispensed,
  getTotalTokenCollected,
  getTotalTokenDispensed,
  getTotalTokenRevenueCollected,
  getTransformedData,
} from '../../../utils/collection-methods';
import { DEFAULT_EXCHANGE_RATE } from '../../../constants/CurrencyConstants';
import { NoContent } from '../../../components/shared';
import { checkObjectNotEmpty, checkValueNotNullUndefinedBlank } from '../../../utils/common-methods';

const displayName = 'ReconciliationCollectionForm';
const ReconciliationCollectionForm = React.forwardRef(
  (
    {
      sublocations,
      allSublocations,
      initialStateAndScheme,
      onConfirm,
      isReconciliation = false,
      isVerified = false,
      readFromDraft,
      turnOffDraft,
      searchState,
      sortState = false,
      onDeleteSubLocationAssets,
      handleSublocationDeleteClick,
    },
    ref,
  ) => {
    const { scheme } = initialStateAndScheme;
    const [initialStates, setInitialStates] = useState({});
    const { reRender } = useSelector((state) => state.reconciliationCollection);
    const dispatch = useDispatch();

    useEffect(() => {
      setInitialStates(initialStateAndScheme.initialState);
    }, [initialStateAndScheme]);
    const {
      averageTokenValueInUSD,
      revenueMeterData,
      sublocationDatesForReconciliation,
      updateStatesForReconciliationSublocationDatesChange,
      intercardDataForReconciliation,
      locationCurrency,
    } = useSelector((state) => state.reconciliationCollection);
    const { playCards } = useSelector((state) => state.playCard);

    const formik = useFormik({
      initialValues: initialStates,
      validationSchema: scheme,
      enableReinitialize: true,
      onSubmit: () => {
        onConfirm();
      },
    });

    useEffect(() => {
      ref.current = formik;
      if (formik.values && Object.values(formik.values).length > 0) {
        let totalTicketDispensed = 0;
        let totalPrizeDispensed = 0;
        let totalTokenCollected = 0;
        let totalTokenChangerRevenueCollected = 0;
        let totalTokenDispensed = 0;
        const exchangeRate = locationCurrency?.exchangeRate
          ? Number(locationCurrency?.exchangeRate)
          : DEFAULT_EXCHANGE_RATE;
        Object.entries(formik.values).map(([key, value]) => {
          totalTicketDispensed = getTotalTicketDispensed(totalTicketDispensed, key, value);
          totalPrizeDispensed = getTotalPrizeDispensed(totalPrizeDispensed, key, value);
          totalTokenCollected = getTotalTokenCollected(totalTokenCollected, key, value);
          totalTokenDispensed = getTotalTokenDispensed(totalTokenDispensed, key, value);
          totalTokenChangerRevenueCollected = getTotalTokenRevenueCollected(
            totalTokenChangerRevenueCollected,
            key,
            formik.values,
            exchangeRate,
          );
        });
        if (Object.values(formik.values).some((el) => el !== '')) {
          const { transformedSublocations, revenueMeterData, formikData } = getTransformedData(
            formik.values,
            allSublocations,
            true,
          );
          dispatch(setRevenueMeterData(revenueMeterData));
          dispatch(setTransformedSublocations(transformedSublocations));
          dispatch(setFormikData(formikData));
        }
        dispatch(setTotalTokenCollected(totalTokenCollected));
        dispatch(setTotalTokenDispensed(totalTokenDispensed));
        dispatch(setTotalTicketDispensed(totalTicketDispensed));
        dispatch(setTotalPrizeDispensed(totalPrizeDispensed));
        dispatch(setTotalTokenChangerRevenueValue(totalTokenChangerRevenueCollected));
      }
    }, [formik.values, reRender]);

    useEffect(() => {
      if (
        checkValueNotNullUndefinedBlank(updateStatesForReconciliationSublocationDatesChange) &&
        checkObjectNotEmpty(updateStatesForReconciliationSublocationDatesChange)
      ) {
        formik.setValues({ ...formik.values, ...updateStatesForReconciliationSublocationDatesChange });
      }
    }, [sublocationDatesForReconciliation]);

    useEffect(() => {
      // under search mode, the change of initial state doesn't reinitialize formik, thus we need to update formik manually
      const currentAssets = allSublocations?.flatMap((sub) => sub.assets);
      let updateStates = {};
      currentAssets.map((asset) => {
        if (
          intercardDataForReconciliation &&
          Object.values(intercardDataForReconciliation).length > 0 &&
          intercardDataForReconciliation[asset?.id] &&
          intercardDataForReconciliation[asset?.id]?.intercardData.length > 0
        ) {
          const intercardInitialStates = getInitialIntercardState(
            intercardDataForReconciliation[asset?.id]?.intercardData,
            asset,
          );
          updateStates = { ...updateStates, ...intercardInitialStates };
        }
      });
      if (Object.values(updateStates).length > 0) {
        formik.setValues({ ...formik.values, ...updateStates });
      }
    }, [intercardDataForReconciliation]);

    useEffect(() => {
      const rawResults = revenueMeterData
        ?.map((data) => {
          const res = getSubTotalsPerAsset(data?.assetId, data?.data, locationCurrency?.exchangeRate);
          if (Object.values(res).length === 0) {
            return false;
          }
          res.sublocationId = data?.sublocationId;
          res.assetId = data?.assetId;
          return res;
        })
        .filter((el) => el !== false);
      let total = 0,
        totalInUSD = 0;
      for (const key in playCards) {
        const adjustedPlaycardSales = Number(playCards[key]?.adjustedPlaycardSales) || 0;
        const adjustedPlaycardSalesInLocalCurrency = adjustedPlaycardSales
          ? getRevenueInLocalCurrency(adjustedPlaycardSales, locationCurrency?.exchangeRate)
          : 0;

        total += adjustedPlaycardSalesInLocalCurrency;
        totalInUSD += adjustedPlaycardSales;
      }
      rawResults['total'] = total;
      rawResults['totalInUSD'] = totalInUSD;
      dispatch(setSubTotalRevenues(rawResults));
    }, [revenueMeterData, averageTokenValueInUSD, playCards, reRender, JSON.stringify(locationCurrency)]);
    const handleDelete = (row) => {
      //console.log(sublocations, row);
      if (onDeleteSubLocationAssets) {
        onDeleteSubLocationAssets(row.id); // Pass sublocation ID or any other identifier
      }
    };

    useEffect(() => {
      if (formik?.errors && Object.values(formik.errors).length > 0) {
        ref.current = formik;
      }
    }, [formik.errors]);

    if (sublocations.length === 0) {
      return (
        <NoContent title="No Asset Found" desc={<>No results found, please try changing the search criteria.</>} />
      );
    }
    if (sublocations && sublocations.length > 0) {
      return (
        <FormikProvider value={formik} validateOnChange={false} validateOnBlur={true}>
          {sublocations?.map((sublocation) => (
            <SublocationMachinesForm
              key={sublocation?.id}
              sublocation={sublocation}
              originalSublocation={allSublocations.find((el) => el.id === sublocation?.id)}
              isReconciliation={isReconciliation}
              isVerified={isVerified}
              searchState={searchState}
              readFromDraft={readFromDraft}
              turnOffDraft={turnOffDraft}
              sortState={sortState}
              onDelete={handleDelete}
              handleSublocationDeleteClick={(id) => handleSublocationDeleteClick(id)}
            />
          ))}
        </FormikProvider>
      );
    }
  },
);

ReconciliationCollectionForm.displayName = displayName;
export default ReconciliationCollectionForm;
