import { InformationPad } from '../../../components/shared';
import {
  getTimeFormatForStaticView,
  getDateFormat,
  mergedTwoPhoneNumber,
  isArrayWithLength,
  checkNAOrReturnPercentValue,
} from '../../../utils/common-methods';
import { ControlledTooltips } from '../../../components/shared';
import YesNoIndicator from '../../../components/shared/YesNoIndicator';
import { SUB_LOCATION_DETAIL_LABEL } from '../../../constants/accordionFields';

const SubLocationDetailAccordion = ({ sublocationInfo }) => {
  let productTypeStr = '';
  if (isArrayWithLength(sublocationInfo?.productCategories)) {
    let productNames = sublocationInfo?.productCategories?.map((item) => item?.name || '');
    productTypeStr = productNames?.join(', ');
  }
  const fields = [
    { label: SUB_LOCATION_DETAIL_LABEL.ZIPCODE, value: sublocationInfo?.code },
    {
      label: SUB_LOCATION_DETAIL_LABEL.PHONE,
      value: mergedTwoPhoneNumber(sublocationInfo?.phone1, sublocationInfo?.phone2),
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.LOCATION_TYPE,
      value: sublocationInfo?.sublocationType?.name,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.MANAGER,
      value: (
        <ControlledTooltips tooltipValue={sublocationInfo?.tooltipValue}>{sublocationInfo?.manager}</ControlledTooltips>
      ),
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.OPEN_HOURS,
      value:
        sublocationInfo?.openHours && sublocationInfo?.closeHours
          ? `${getTimeFormatForStaticView(sublocationInfo?.openHours)}-${getTimeFormatForStaticView(
              sublocationInfo?.closeHours,
            )}`
          : null,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.START_DATE,
      value: getDateFormat(sublocationInfo?.startDate),
    },
    { label: SUB_LOCATION_DETAIL_LABEL.END_DATE, value: getDateFormat(sublocationInfo?.endDate) },
    {
      label: SUB_LOCATION_DETAIL_LABEL.UNINSTALL_DATE,
      value: sublocationInfo?.uninstallDate ? getDateFormat(sublocationInfo?.uninstallDate) : null,
    },
    { label: SUB_LOCATION_DETAIL_LABEL.ADDRESS1, value: sublocationInfo?.address1 },
    { label: SUB_LOCATION_DETAIL_LABEL.ADDRESS2, value: sublocationInfo?.address2 },
    {
      label: SUB_LOCATION_DETAIL_LABEL.PRODUCT_TYPE,
      value: productTypeStr || 'N/A',
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.STATE_TAX,
      value: checkNAOrReturnPercentValue(sublocationInfo?.stateTaxRate),
    },
    { label: SUB_LOCATION_DETAIL_LABEL.AREA_TAX, value: checkNAOrReturnPercentValue(sublocationInfo?.areaTaxRate) },
    { label: SUB_LOCATION_DETAIL_LABEL.MISC_TAX, value: checkNAOrReturnPercentValue(sublocationInfo?.miscTax) },
    {
      label: SUB_LOCATION_DETAIL_LABEL.TAX_EXEMPT_FLAG,
      value: <YesNoIndicator value={sublocationInfo?.taxExemptFlag === true} />,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.LOC_PAYS_TAX,
      value: <YesNoIndicator value={sublocationInfo?.locPaysTaxFlag === true} />,
    },
    {
      label: SUB_LOCATION_DETAIL_LABEL.CALC_RENT,
      value: <YesNoIndicator value={sublocationInfo?.calculateRent === true} />,
    },
  ];

  return <InformationPad data={fields} />;
};
export default SubLocationDetailAccordion;
