import React from 'react';
import { InformationPad, Link } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import { Box } from '@mui/material';
import useStyles from './style';
import { LEASE_DETAILS } from '../../../constants/accordionFields';
import {
  getDateFormat,
  rentTypeString,
  formatNumberOrReturnZero,
  checkNAOrReturnPercentValue,
} from '../../../utils/common-methods';

const LeaseDetailsAccordion = (props) => {
  const { LEASE, RENT, GENERAL } = LEASE_DETAILS;
  const classes = useStyles();
  const { leaseDetails, isFrom = 'LEASE' } = props;

  const locationLink = (id, name) => (
    <Link underline="none" to={`/${routeConstants.LOCATION_ROUTE}/${id}`}>
      {name}
    </Link>
  );

  const sublocationLink = (id, name, locationId, isLast) => (
    <Link underline="none" to={`/${routeConstants.SUBLOCATION_ROUTE}/${id}?locationId=${locationId} `} key={id}>
      {`${name}${isLast ? '' : ', '}`}
    </Link>
  );

  const leaseDetailsFields = [
    {
      label: LEASE.LOCATION,
      value: locationLink(leaseDetails?.location?.id, leaseDetails?.location?.locationName),
    },
    {
      label: LEASE.SUB_LOCATION,
      value: leaseDetails?.contractSublocations?.map((item, index) =>
        sublocationLink(
          item?.sublocation?.id,
          item?.sublocation?.name,
          leaseDetails?.location?.id,
          index + 1 === leaseDetails?.contractSublocations?.length,
        ),
      ),
    },
    { label: LEASE.ADDRESS1, value: leaseDetails?.location?.address1 },
    { label: LEASE.ADDRESS2, value: leaseDetails?.location?.address2 },
    { label: LEASE.ZIPCODE, value: leaseDetails?.location?.postcode },
    { label: LEASE.COUNTRY, value: leaseDetails?.location?.country?.name },
    { label: LEASE.STATE, value: leaseDetails?.location?.state?.name },
    { label: LEASE.CITY, value: leaseDetails?.location?.city?.name },
    { label: LEASE.BOOTH_INFO, value: 'N/A' },
    { label: LEASE.SPACE, value: 'N/A' },
    { label: LEASE.DBA, value: 'N/A' },
    { label: LEASE.SALES_PERSON, value: 'N/A' },
    { label: LEASE.TYPE, value: leaseDetails?.leaseType },
    { label: LEASE.STATUS, value: leaseDetails?.status },
    {
      label: LEASE.LEASE_BEGINS,
      value: getDateFormat(leaseDetails?.termStartDate),
    },
    {
      label: LEASE.LEASE_ENDS,
      value: getDateFormat(leaseDetails?.termEndDate),
    },
  ];

  const rentDetailsCommon = [
    { label: RENT.RENT_TYPE, value: rentTypeString(leaseDetails?.rentType) },
    {
      label: RENT.RENT_CALCULATION_TIMING,
      value:
        leaseDetails?.rentCalculateInterval &&
        (
          leaseDetails?.rentCalculateInterval?.charAt(0).toUpperCase() +
          leaseDetails?.rentCalculateInterval?.slice(1)?.toLowerCase()
        )
          ?.split('_')
          ?.join(' '),
    },
    { label: RENT.CALCULATION_STARTS, value: 'N/A' },
    { label: RENT.CALCULATION_ENDS, value: 'N/A' },
    {
      label: RENT.CAM_FEE,
      value: `$${formatNumberOrReturnZero(leaseDetails?.CAMFee)}`,
    },
    {
      label: RENT.CAM_RATE,
      value: checkNAOrReturnPercentValue(leaseDetails?.CAMRate),
    },
    {
      label: RENT.THRESHOLD_RATE,
      value: checkNAOrReturnPercentValue(leaseDetails?.thresholdRate),
    },
    {
      label: RENT.THRESHOLD_REVENUE,
      value: `$${formatNumberOrReturnZero(leaseDetails?.thresholdRevenue)}`,
    },
    {
      label: RENT.NOTES,
      value: leaseDetails?.notes && `${leaseDetails?.notes}`,
    },
    {
      label: RENT.COIN_COST,
      value: `$${formatNumberOrReturnZero(leaseDetails?.coinCost)}`,
    },
    {
      label: RENT.CARD_COST,
      value: `$${formatNumberOrReturnZero(leaseDetails?.cardCost)}`,
    },
  ];

  const generateDynamicLabels = (modelTypes) =>
    modelTypes?.map((value) => ({
      label: value?.gameCategory?.name,
      value: `${value?.rentRate}`,
    }));

  const rentDetailsFields = (type) => {
    switch (type) {
      case 'RATE_PLUS_FEE':
      case 'COMBO':
      case 'RATE_FEE_HIGHER':
      case 'SUM':
      case 'NA':
        return [
          ...rentDetailsCommon,
          {
            label: RENT.RENT_FEE,
            value: `$${formatNumberOrReturnZero(leaseDetails?.fee)}`,
          },

          {
            label: RENT.RENT_RATE,
            value: checkNAOrReturnPercentValue(leaseDetails?.rate),
          },

          {
            label: RENT.BREAKPOINT_NATURAL,
            value: leaseDetails?.hasNaturalBreakPoint ? 'Yes' : 'No',
          },
          {
            label: RENT.BREAKPOINT_VALUE,
            value: `$${formatNumberOrReturnZero(leaseDetails?.naturalBreakPointValue)}`,
          },
        ];

      case 'RATE_ONLY':
      case 'BENCHMARK_RENT':
        return [
          ...rentDetailsCommon,
          {
            label: RENT.RENT_RATE,
            value: checkNAOrReturnPercentValue(leaseDetails?.rate),
          },
          ...generateDynamicLabels(leaseDetails?.contractAssetGameCategoryRates),
        ];

      case 'FEE_ONLY':
      case 'FEE_ONLY_ANNUAL':
      case 'FEE_ONLY_YEAR':
      case 'FEE_PER_PIC':
      case 'FEE_ONLY_AFTER_BENCHMARK':
      case 'FEE_ONLY_SLIDING_BENCHMARK':
        return [
          ...rentDetailsCommon,
          {
            label: RENT.RENT_FEE,
            value: leaseDetails?.fee && `$${leaseDetails?.fee}`,
          },
        ];

      default:
        return [
          ...rentDetailsCommon,
          {
            label: RENT.RENT_FEE,
            value: leaseDetails?.fee && `$${leaseDetails?.fee}`,
          },

          {
            label: RENT.RENT_RATE,
            value: leaseDetails?.rate && `${leaseDetails?.rate}%`,
          },

          {
            label: RENT.BREAKPOINT_NATURAL,
            value: leaseDetails?.hasNaturalBreakPoint ? 'Yes' : 'No',
          },
          {
            label: RENT.BREAKPOINT_VALUE,
            value: leaseDetails?.naturalBreakPointValue && `$${leaseDetails?.naturalBreakPointValue}`,
          },
        ];
    }
  };

  const generalDetailsFields = [
    {
      label: GENERAL.WORKERS_COMP,
      value: leaseDetails?.workersComp ? 'Yes' : 'No',
    },
    {
      label: GENERAL.GEN_LIABILITY,
      value: leaseDetails?.genLiability ? 'Yes' : 'No',
    },
    { label: GENERAL.UMBRELLA, value: leaseDetails?.umbrella ? 'Yes' : 'No' },
    { label: GENERAL.TERMINATION_DAYS, value: leaseDetails?.termDays },
    { label: GENERAL.RELOCATION_DAYS, value: leaseDetails?.reloDays },
    { label: GENERAL.SALES_REPORTS_BY, value: leaseDetails?.salesReportBy },
    { label: GENERAL.PERCENT_RENT_BY, value: leaseDetails?.percentRateBy },
    { label: GENERAL.FIXED_RENT_BY, value: leaseDetails?.fixedRentDue },
    { label: GENERAL.ADDENDUM, value: leaseDetails?.addendum ? 'Yes' : 'No' },
    {
      label: GENERAL.SECURITY_DEPOSIT,
      value: `$${formatNumberOrReturnZero(leaseDetails?.securityDeposit)}`,
    },
    {
      label: GENERAL.GROSS_SALES,
      value: `$${formatNumberOrReturnZero(leaseDetails?.grossSales)}`,
    },
    {
      label: GENERAL.OCC,
      value: checkNAOrReturnPercentValue(leaseDetails?.OCCPercentage),
    },
  ];

  const showingFieldsData = (from) => {
    switch (from) {
      case 'LEASE':
        return leaseDetailsFields;
      case 'RENT':
        return rentDetailsFields(leaseDetails?.rentType);
      case 'GENERAL':
        return generalDetailsFields;
      default:
        return [];
    }
  };

  return (
    <Box className={classes.leaseDetailsAccordion}>
      <InformationPad
        data={showingFieldsData(isFrom)}
        headingColumn={{ xs: 12, sm: 4, lg: 2.25 }}
        titleColumn={{ xs: 12, sm: 8, lg: 9.75 }}
      />
    </Box>
  );
};

export default LeaseDetailsAccordion;
